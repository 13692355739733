import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlanRequest } from "../../Infrastructure/Core/Request/Plan/PlanRequest";
import {
  loadCreatePlan,
  loadEditPlan,
  loadPlanDetails,
} from "../../Application/Actions/loader";
import { useNavigate, useParams } from "react-router-dom";
import { getPlanDetail } from "../../Application/Selectors/plan";
import { PlanDTO } from "../../Infrastructure/Core/DTOs/Plan/PlanDTO";
import CommonFields from "../../Infrastructure/Core/Helper/CommonFields";

function PlanCU() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const planDetail: PlanDTO = useSelector(getPlanDetail);
  const [formData, setFormData] = useState<PlanRequest>({
    planName: "",
    planDescription: "",
    planType: CommonFields.defaultValue,
    smsPack: 0,
    priceInIndia: 0,
    priceOutSideIndia: 0,
    outSideIndiaCurrancy: "USD",
    isTaxable: false,
    expiredInDays: -2,
    isMobileApp: false,
    isWebApp: false,
    isDesktop: false,
    isVisible: true,
    isDeleted: false,
    createdBy: CommonFields.defaultValue,
  });

  useEffect(() => {
    dispatch(loadPlanDetails(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      setFormData({
        planName: planDetail.planName,
        planDescription: planDetail.planDescription,
        planType: planDetail.planType,
        smsPack: planDetail.smsPack,
        priceInIndia: planDetail.priceInIndia,
        priceOutSideIndia: planDetail.priceOutSideIndia,
        outSideIndiaCurrancy: planDetail.outSideIndiaCurrancy,
        isTaxable: planDetail.isTaxable,
        expiredInDays: planDetail.expiredInDays,
        isMobileApp: planDetail.isMobileApp,
        isWebApp: planDetail.isWebApp,
        isDesktop: planDetail.isDesktop,
        isVisible: planDetail.isVisible,
        isDeleted: planDetail.isDeleted,
        createdBy: planDetail.createdBy,
      });
    } else {
      setFormData({
        planName: "",
        planDescription: "",
        planType: CommonFields.defaultValue,
        smsPack: 0,
        priceInIndia: 0,
        priceOutSideIndia: 0,
        outSideIndiaCurrancy: "USD",
        isTaxable: false,
        expiredInDays: -2,
        isMobileApp: false,
        isWebApp: false,
        isDesktop: false,
        isVisible: true,
        isDeleted: false,
        createdBy: CommonFields.defaultValue,
      });
    }
  }, [id, planDetail]);

  const setNewFormdata = (e: any) => {
    const { name, type, value, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (id) {
      const formObject = {
        createdBy: formData.createdBy,
        expiredInDays: Number(formData.expiredInDays),
        isDeleted: formData.isDeleted,
        isDesktop: formData.isDesktop,
        isMobileApp:formData.isMobileApp,
        isTaxable: formData.isTaxable,
        isVisible: formData.isVisible,
        isWebApp: formData.isWebApp,
        outSideIndiaCurrancy: formData.outSideIndiaCurrancy,
        planDescription: formData.planDescription,
        planName: formData.planName,
        planType: formData.planType,
        priceInIndia: Number(formData.priceInIndia),
        priceOutSideIndia: Number(formData.priceOutSideIndia),
        smsPack: formData.smsPack,
        tenantId: planDetail.tenantId,
        id: planDetail.id,
        branchId: planDetail.branchId,
        status: planDetail.status,
        createdOn: planDetail.createdOn,
        modifiedBy: planDetail.modifiedBy,
        modifiedOn: new Date(),
      };
      dispatch(loadEditPlan(formObject));
    } else {
      const formObject = {
        createdBy: formData.createdBy,
        expiredInDays: Number(formData.expiredInDays),
        isDeleted: formData.isDeleted,
        isDesktop:formData.isDesktop,
        isMobileApp:formData.isMobileApp,
        isTaxable: formData.isTaxable,
        isVisible: formData.isVisible,
        isWebApp:formData.isWebApp,
        outSideIndiaCurrancy: formData.outSideIndiaCurrancy,
        planDescription: formData.planDescription,
        planName: formData.planName,
        planType: formData.planType,
        priceInIndia: Number(formData.priceInIndia),
        priceOutSideIndia: Number(formData.priceOutSideIndia),
        smsPack: formData.smsPack,
      };
      dispatch(loadCreatePlan(formObject));
    }
    navigate("/PlanManage");
  };

  return (
    <>
      <div className="m-4 h-[600px]">
        <div className="bg-white border">
          <div className="">
            <div className="flex flex-col">
              <div className="bg-white px-4 py-2">
                <div className="text-center flex justify-between">
                  <h6 className="text-slate-800 text-xs md:text-base lg:text-base xl:text-base font-bold py-1">
                    {id ? "Update Plan" : "Add Plan"}
                  </h6>
                  <button
                    className="bg-red-600 text-white active:bg-red-700 hover:bg-red-500 font-medium uppercase text-xs lg:text-base w-52 py-1 lg:px-10 lg:py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                  >
                    {id ? "Update Plan" : "Add Plan"}
                  </button>
                </div>
              </div>
              <hr />

              <div className="flex-auto px-8 py-4 bg-white rounded-b-xl h-[600px]">
                <form>
                  <>
                    <h6 className="text-sm font-bold uppercase my-3">
                      Basic Info
                    </h6>

                    <div className="grid grid-col-1 md:grid-col-1 lg:grid-cols-2 gap-x-8 gap-y-6">
                      <div className="">
                        <label
                          htmlFor="planName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Name
                        </label>
                        <input
                          placeholder="Enter Name"
                          className="w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          name="planName"
                          id="planName"
                          value={formData.planName}
                          onChange={setNewFormdata}
                        ></input>
                      </div>
                      <div className="">
                        <label
                          htmlFor="planType"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Type
                        </label>
                        <select
                          id="planType"
                          name="planType"
                          className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                          value={formData.planType}
                          onChange={setNewFormdata}
                        >
                          <option value={""} disabled>
                            Choose Type
                          </option>
                          <option value={"Subscription"}>Subscription</option>
                          <option value={"Addon"}>Addon</option>
                          <option value={"Device"}>Device</option>
                          {/* {PlantatusList.map((data: PlantatusDTO, index: any) => (
                        <option key={index} value={data.id}>
                          {data.fieldValue}
                        </option>
                      ))} */}
                        </select>
                      </div>

                      <div className=" col-start-1">
                        <label
                          htmlFor="planDescription"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Description
                        </label>
                        <textarea
                          placeholder="Enter Description"
                          className="w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          name="planDescription"
                          id="planDescription"
                          value={formData.planDescription}
                          onChange={setNewFormdata}
                        ></textarea>
                      </div>

                      <div className=" col-start-1">
                        <label
                          htmlFor="priceInIndia"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Price In India
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Price In India"
                          className="w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          name="priceInIndia"
                          id="priceInIndia"
                          value={formData.priceInIndia}
                          onChange={setNewFormdata}
                        ></input>
                      </div>

                      <div className="">
                        <label
                          htmlFor="priceOutSideIndia"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Price Outside India
                        </label>
                        <input
                          type="number"
                          placeholder="Enter Price Outside India"
                          className="w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          name="priceOutSideIndia"
                          id="priceOutSideIndia"
                          value={formData.priceOutSideIndia}
                          onChange={setNewFormdata}
                        ></input>
                      </div>

                      <div className="">
                        <label
                          htmlFor="expiredInDays"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Expiration In Days
                        </label>
                        {/* <input
                          type="number"
                          placeholder="Enter Expiration In Days"
                          className="w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                          name="expiredInDays"
                          id="expiredInDays"
                          value={formData.expiredInDays}
                          onChange={setNewFormdata}
                        ></input> */}
                        <select
                          id="expiredInDays"
                          name="expiredInDays"
                          className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                          value={formData.expiredInDays}
                          onChange={setNewFormdata}
                        >
                          <option value={-2} disabled>
                            Choose Expiration Days
                          </option>
                          <option value={365}>1 year</option>
                          <option value={270}>9 months</option>
                          <option value={180}>6 months</option>
                          <option value={90}>3 months</option>
                          <option value={30}>1 month</option>
                          <option value={15}>15 Days</option>
                          <option value={7}>7 Days</option>
                          <option value={-1}>PAYG</option>
                          <option value={0}>No Limit</option>
                          {/* {PlantatusList.map((data: PlantatusDTO, index: any) => (
                        <option key={index} value={data.id}>
                          {data.fieldValue}
                        </option>
                      ))} */}
                        </select>
                      </div>
                    </div>

                    <hr className="my-6" />
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
                      <div className="relative md:mx-5 my-3">
                        <label className="items-center cursor-pointer">
                          <div className="flex">
                            <div>
                              <input
                                name="isTaxable"
                                type="checkbox"
                                checked={formData.isTaxable} // Use checked attribute for checkboxes
                                onChange={setNewFormdata}
                                className="sr-only peer"
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </div>

                            <div>
                              <span className="ms-3 text-xs lg:text-sm font-medium text-gray-900 dark:text-gray-300">
                                Taxable
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="relative md:mx-5 my-3">
                        <label className="items-center cursor-pointer">
                          <div className="flex">
                            <div>
                              <input
                                name="isMobileApp"
                                type="checkbox"
                                checked={formData.isMobileApp}
                                onChange={setNewFormdata}
                                className="sr-only peer"
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </div>

                            <div>
                              <span className="ms-3 text-xs lg:text-sm font-medium text-gray-900 dark:text-gray-300">
                                Mobile App
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="relative md:mx-5 my-3">
                        <label className="items-center cursor-pointer">
                          <div className="flex">
                            <div>
                              <input
                                name="isWebApp"
                                type="checkbox"
                                checked={formData.isWebApp}
                                onChange={setNewFormdata}
                                className="sr-only peer"
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </div>

                            <div>
                              <span className="ms-3 text-xs lg:text-sm font-medium text-gray-900 dark:text-gray-300">
                                Web App
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div className="relative md:mx-5 my-3">
                        <label className="items-center cursor-pointer">
                          <div className="flex">
                            <div>
                              <input
                                name="isDesktop"
                                type="checkbox"
                                checked={formData.isDesktop}
                                onChange={setNewFormdata}
                                className="sr-only peer"
                              />
                              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </div>

                            <div>
                              <span className="ms-3 text-xs lg:text-sm font-medium text-gray-900 dark:text-gray-300">
                                Desktop App
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanCU;
