import { EnquiryDetailDTO } from "../../../Core/DTOs/Enquiry/EnquiryDetailDTO";
import CommonFields from "../../../Core/Helper/CommonFields";
import Environment from "../../../Core/Helper/Environment";
import jwtInterceptor from "../../../Core/Helper/JwtInterceptor";
import { EnquirySalesLogRequest } from "../../../Core/Request/Enquiry/EnquirySalesLogRequest";

let storedTokenString = localStorage.getItem("Token");
let parsedToken = storedTokenString ? JSON.parse(storedTokenString) : null;
let token = parsedToken?.token ?? null;
let headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  authorization: `Bearer ${token}`,
};
const getEnquiryList = async () => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.dashboardMSPUrl}/lead`,
      {
        headers: headers,
      }
    );
    return response.data.result;
  } catch (error) {
    throw error;
  }
};
const getEnquiryDetailById = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.dashboardMSPUrl}/lead/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const enquiryCallLog = async (data: EnquirySalesLogRequest) => {
  try {
    const formData = {
      id: data.id,
      createOn: data.createOn,
      createdBy: data.modifiedBy,
      leadId: data.leadId,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
      remark: data.remark,
      status: data.status,
      followUpOn: new Date(data.followUpOn).toISOString(),
    };
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/call-log`,
      formData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const saleStatusList = async () => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.setUpUrl}/common-fields/${CommonFields.saleStatus.salesStatusType}`,
      {
        headers: headers,
      }
    );
    return response.data.result;
  } catch (error) {
    throw error;
  }
};
const updateEnquiryDetail = async (data: EnquiryDetailDTO) => {
  try {
    const formData = {
      id: data.id,
      name: data.name,
      brandName: data.brandName,
      email: data.email,
      phone: data.phone,
      city: data.city,
      state: data.state,
      country: data.country,
      countryCode: data.country,
      dialCode: data.dialCode,
      branchCount: data.branchCount,
      enableNewsInsightOffer: data.enableNewsInsightOffer,
      tenantId: data.tenantId,
      salesRepresentative: data.salesRepresentative,
      saleStatus: data.saleStatus,
      saleRemark: data.saleRemark,
      activePlanId: data.activePlanId,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
    };
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/update-lead`,
      formData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
function randomstring(L: any) {
  var s = "";
  var randomchar = function () {
    var n = Math.floor(Math.random() * 62);
    if (n < 10) return n;
    if (n < 36) return String.fromCharCode(n + 55);
    return String.fromCharCode(n + 61);
  };
  while (s.length < L) s += randomchar();
  return s;
}
const leadToCustomer = async (data: EnquiryDetailDTO) => {
  try {
    const fiftydigit = randomstring(50);
    const formData = {
      id: CommonFields.defaultValue,
      leadId: data.id,
      name: data.brandName,
      connectionString: data.brandName.split(" ")[0] + data.id,
      domain: "-",
      secret: fiftydigit,
      isAccountSetup: false,
      isBranchSetup: false,
      isUserCreated: false,
      isMenuSetup: false,
      isCatalogueSetup: false,
      isBillingSetup: false,
      activePlanId: data.activePlanId,
      planExpiredOn: new Date(),
      isDedicatedDatabase: true,
      database: data.brandName,
      databaseProvider: "SQL",
      status: CommonFields.status.active,
      isVisible: true,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.createdOn,
      domainType: CommonFields.defaultValue,
      isBrandSetup: false,
      isTaxSetup: false,
    };
    const response = await jwtInterceptor.post(
      `${Environment.tenantUrl}`,
      formData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getEnquiryList,
  getEnquiryDetailById,
  enquiryCallLog,
  saleStatusList,
  updateEnquiryDetail,
  leadToCustomer,
};
