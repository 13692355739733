import {
  PLAN_LIST,
  planListSuccess,
  planListFailure,
  CREATE_PLAN,
  createPlanFailure,
  createPlanSuccess,
  PLAN_DETAILS,
  planDetailsSuccess,
  planDetailsFailure,
  EDIT_PLAN,
  editPlanSuccess,
  editPlanFailure,
} from "../Actions/plan";
import * as loaderActions from "../Actions/loader";
import ToastifyService from "../../Infrastructure/Core/Helper/ToastifyService";

const getPlanList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === PLAN_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "getPlanList" })
        );
        const planList = await api.Plan.planList();
        dispatch(planListSuccess(planList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getPlanList" })
        );
        dispatch(planListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getPlanList" })
        );
      }
    }
    next(action);
  };
const createPlan =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CREATE_PLAN) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "createPlan" })
        );
        const planList = await api.Plan.createPlan(action.payload);
        dispatch(createPlanSuccess(planList));
        ToastifyService.success("Plan created successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createPlan" })
        );
        dispatch(createPlanFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createPlan" })
        );
      }
    }
    next(action);
  };
const planDetails =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === PLAN_DETAILS) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "planDetails" })
        );
        const planDetails = await api.Plan.planDetails(action.payload);
        dispatch(planDetailsSuccess(planDetails));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "planDetails" })
        );
        dispatch(planDetailsFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "planDetails" })
        );
      }
    }
    next(action);
  };
const editPlan =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === EDIT_PLAN) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "editPlan" })
        );
        const planList = await api.Plan.editPlan(action.payload);
        dispatch(editPlanSuccess(planList));
        ToastifyService.success("Plan updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editPlan" })
        );
        dispatch(editPlanFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editPlan" })
        );
      }
    }
    next(action);
  };
export default [getPlanList, createPlan, planDetails, editPlan];
