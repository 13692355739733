const defaultValue = "00000000-0000-0000-0000-000000000000";
const saleStatus = {
  salesStatusType: "3f8409a9-e338-497d-ac50-87c0c3af82c3",
  salesDone: "d9c5a952-911e-4a0f-8935-5e1fae01001e",
  noSales: "d9c5a952-911e-4a0f-8935-5e1fae01002e",
  ongoingSales: "d9c5a952-911e-4a0f-8935-5e1fae01003e",
};
const domainType = {
  fudeasyDomain: "d9c5a952-911e-4a0f-8935-5e1fae02901e",
  customDomain: "d9c5a952-911e-4a0f-8935-5e1fae02902e",
};
const taxMethod = {
  exclusiveTax: "d9c5a952-911e-4a0f-8935-5e1fae01401e",
};
const status = {
  active: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
};
const payStatus = {
  pending: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
};

export default {
  defaultValue,
  saleStatus,
  domainType,
  taxMethod,
  status,
  payStatus,
};
