import React from "react";

function FormField({ colCount, fieldCount,marginY,marginX }: any) {
  const numberOfColumns = colCount;
  const itemsPerRow = fieldCount;

  const generateTimelineItems = () => {
    const items = [];
    for (let i = 0; i < itemsPerRow; i++) {
      items.push(
        <div key={i} className={`mx-${marginX} h-4 w-48 bg-gray-200 rounded my-${marginY}`}></div>
      );
    }
    return items;
  };

  return (
    <>
      <div className={`animate-pulse grid grid-cols-${numberOfColumns} gap-x-${marginX}`}>
        {generateTimelineItems()}
      </div>
    </>
  );
}

export default FormField;