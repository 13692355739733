import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DataTable, { TableStyles } from "react-data-table-component";

import { getLoading } from "../../Application/Selectors/loader";
import { loadPlanList } from "../../Application/Actions/loader";
import { getPlanList } from "../../Application/Selectors/plan";
import { PlanDTO } from "../../Infrastructure/Core/DTOs/Plan/PlanDTO";
import ShimmerTable from "../Loader/Table";

function PlanManage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const planList = useSelector(getPlanList);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(loadPlanList);
  }, []);

  const tableColsName = [
    "Plan Name",
    "Plan Type",
    "Price",
    "Global Price",
    "Expiry Term",
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "35px",
      },
    },
  };
  const columns = [
    {
      name: "Plan Name",
      selector: (row: PlanDTO) => row.planName,
      sortable: true,
      cell: (row: PlanDTO) => (
        <div
          className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
          onClick={() => navigate(`/PlanDetail/${row.id}`)}
        >
          {row.planName}
        </div>
      ),
    },
    {
      name: "Plan Type",
      selector: (row: PlanDTO) => row.planType,
      sortable: true,
      cell: (row: PlanDTO) => <div className="px-6 py-2">{row.planType}</div>,
    },
    {
      name: "Price",
      selector: (row: PlanDTO) =>
        row.priceInIndia !== 0 ? row.priceInIndia : "-",
      sortable: true,
      cell: (row: PlanDTO) => (
        <div className="px-6 py-2">
          {row.priceInIndia !== 0 ? row.priceInIndia : "-"}
        </div>
      ),
    },
    {
      name: "Global Price",
      selector: (row: PlanDTO) =>
        row.priceOutSideIndia !== 0 ? row.priceOutSideIndia : "-",
      sortable: true,
      cell: (row: PlanDTO) => (
        <div className="px-6 py-2">
          {row.priceOutSideIndia !== 0 ? row.priceOutSideIndia : "-"}
        </div>
      ),
    },
    {
      name: "Expiry Term",
      selector: (row: PlanDTO) =>
        row.expiredInDays === 0
          ? "-"
          : row.expiredInDays === -1
          ? "PAYG"
          : row.expiredInDays + "   Days",
      sortable: true,
      cell: (row: PlanDTO) => (
        <div className="px-6 py-2">
          {row.expiredInDays === 0
            ? "-"
            : row.expiredInDays === -1
            ? "PAYG"
            : row.expiredInDays + "   Days"}
        </div>
      ),
    },
  ];
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const filteredData: PlanDTO[] = planList.filter((plan: PlanDTO) => {
    return (
      plan.planName.toLowerCase().includes(searchText.toLowerCase()) ||
      plan.planType.toLowerCase().includes(searchText.toLowerCase()) ||
      plan.priceInIndia.toString().includes(searchText.toLowerCase()) ||
      plan.priceOutSideIndia.toString().includes(searchText.toLowerCase())
    );
  });

  return (
    <>
      <div className="flex justify-between items-center align-middle mb-3">
        <div>
          <h3 className="font-medium text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg">
            Plan Management
          </h3>
        </div>
        <div>
          <Link to="/PlanCreate">
            <button
              className={`bg-red-500 text-slate-50 py-1 text-xs md:text-sm lg:text-base xl:text-base 2xl:text-base w-32 md:w-36 lg:w-56 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md`}
            >
              Add Plan
            </button>
          </Link>
        </div>
      </div>
      <div className="flex justify-end my-3">
        <input
          type="text"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search..."
          className="border-[1px] border-slate-300 px-2 py-2 rounded text-sm shadow focus:outline-none focus:ring-1 md:w-2/5 lg:w-2/5 xl:w-1/5 2xl:w-1/5 ease-linear transition-all duration-150"
        />
      </div>
      <div className="overflow-x-auto shadow-lg rounded-lg">
        {loading && value === "getPlanList" ? (
          <ShimmerTable
            colNames={tableColsName}
            colsCount={tableColsName.length}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}
export default PlanManage;
// const navigate = useNavigate();
// const handleProceed = (e: string | undefined) => {
//   navigate(`/SalesRepDetails/${e}`);
// };
// const handleProceed = (e: any) => {
//   navigate("/PlanDetail/" + e);
// };
