export const SALES_REP_LIST = "sales rep list";
export const SALES_REP_LIST_SUCCESS = "sales rep list success";
export const SALES_REP_LIST_FAILURE = "sales rep list failed";

// get sales rep list
export const salesRepList = {
  type: SALES_REP_LIST,
};
export const salesRepListSuccess = (data: any) => ({
  type: SALES_REP_LIST_SUCCESS,
  payload: data,
});
export const salesRepListFailure = (error: any) => ({
  type: SALES_REP_LIST_FAILURE,
  payload: error,
});
