import React, { useEffect, useState } from "react";
import { UserRequest } from "../../../../../Infrastructure/Core/Request/Customer/User/UserRequest";
import { CustomerDetailsDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCreateUser,
  loadEditUser,
} from "../../../../../Application/Actions/loader";
import FormField from "../../../../Loader/FormField";
import {
  getCreateUserSuccess,
  getEditUserSuccess,
} from "../../../../../Application/Selectors/customer";
import { UserDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/User/UserDTO";
import {
  resetCreateUserSuccess,
  resetEditUserSuccess,
} from "../../../../../Application/Actions/customer";
import CommonFields from "../../../../../Infrastructure/Core/Helper/CommonFields";

function UserAccountCU(props: any) {
  const dispatch = useDispatch();
  const [isCreateUser, setIsCreateUser] = useState<boolean>(true);
  const [isEditUser, setIsEditUser] = useState<boolean>(true);
  const loading = props?.loading;
  const value = props?.value;
  const [editUserData, setEditUserData] = useState<UserDTO | undefined>(
    undefined
  );
  const createUserSuccess = useSelector(getCreateUserSuccess);
  const editUserSuccess = useSelector(getEditUserSuccess);
  const [formData, setFormData] = useState<UserRequest>({
    id: CommonFields.defaultValue,
  });
  useEffect(() => {
    setIsCreateUser(props.isCreateUser);
    setIsEditUser(props.isEditUser);
    setEditUserData(props.editUserData);

    setFormData({
      id: isCreateUser
        ? CommonFields.defaultValue
        : editUserData?.id || CommonFields.defaultValue,
    });
  }, [props, editUserData, isCreateUser]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isCreateUser) {
      dispatch(loadCreateUser(formData));
    } else {
      dispatch(loadEditUser(formData));
    }
  };
  useEffect(() => {
    if (createUserSuccess) {
      props.updateIsCreateUser(false);
      props.UserListSuccess(createUserSuccess);
      setTimeout(() => {
        dispatch(resetCreateUserSuccess());
      }, 1000);
    }
  }, [createUserSuccess, props, dispatch]);

  useEffect(() => {
    if (editUserSuccess) {
      props.updateIsEditUser(false);
      props.UserListSuccess(editUserSuccess);
      setTimeout(() => {
        dispatch(resetEditUserSuccess());
      }, 1000);
    }
  }, [editUserSuccess, props, dispatch]);

  return (
    <>
      <div
        className={`bg-white h-[790x] shadow-md border-1 px-8 py-2 ${
          isCreateUser || isEditUser ? "block" : "hidden"
        }`}
      >
        {loading && value === "" ? (
          <FormField colCount={3} fieldCount={4} marginY={5} marginX={4} />
        ) : (
          <>
            <div className="grid grid-cols-3 gap-y-1.5 px-2.5 mt-1 mb-2 py-2">
              <div className="col-start-1">
                <label
                  htmlFor="UserName"
                  className="block text-xs font-medium text-gray-900"
                >
                  User / Location Name:
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="UserName"
                    id="UserName"
                    name="UserName"
                    // value={formData.UserName}
                    // onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[320px]"
                    placeholder="Enter User name"
                  />
                </div>
              </div>

              <div className="col-start-2">
                <label
                  htmlFor="currancyId"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Currency: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <select
                    id="currancyId"
                    name="currancyId"
                    // value={formData.currancyId}
                    onChange={setNewFormdata}
                    className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[320px]"
                  >
                    <option value={0} disabled>
                      Choose Currency
                    </option>
                    <option value={1}>INR - ₹</option>
                    <option value={2}>USD - $</option>
                  </select>
                </div>
              </div>

              <div className="col-start-1">
                <label
                  htmlFor="shortCode"
                  className="block text-xs font-medium text-gray-900"
                >
                  User Identification Code:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="shortCode"
                    name="shortCode"
                    // value={formData.shortCode}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[320px]"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <hr />
        {loading && value === "" ? (
          <FormField colCount={2} fieldCount={14} marginY={5} marginX={4} />
        ) : (
          <>
            <div className="grid grid-cols-2 gap-y-1.5 px-2.5 mt-2 mb-2 py-2">
              <div>
                <label
                  htmlFor="addressline1"
                  className="block text-xs font-medium text-gray-900"
                >
                  Address Line 1:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="addressline1"
                    id="addressline1"
                    name="addressline1"
                    // value={formData.addressline1}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Address Line 1"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="addressline2"
                  className="block text-xs font-medium text-gray-900"
                >
                  Address Line 2:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="addressline2"
                    id="addressline2"
                    name="addressline2"
                    // value={formData.addressline2}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Address Line 2"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="city"
                  className="block text-xs font-medium text-gray-900"
                >
                  City: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="city"
                    id="city"
                    name="city"
                    // value={formData.city}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter City Name"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="state"
                  className="block text-xs font-medium text-gray-900"
                >
                  State: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="state"
                    id="state"
                    name="state"
                    // value={formData.state}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter State"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Country: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    // value={formData.country}
                    onChange={setNewFormdata}
                    className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 focus:outline-none focus:ring-1 focus:ring-blue-400 w-[500px]"
                  >
                    <option value={""} disabled>
                      Choose Country
                    </option>
                    <option value={"India"}>India</option>
                    <option value={"USA"}>USA</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="zipCode"
                  className="block text-xs font-medium text-gray-900"
                >
                  Zip Code: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="zipCode"
                    id="zipCode"
                    name="zipCode"
                    // value={formData.zipCode}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Zip Code"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <hr />

        {loading && value === "" ? (
          <FormField colCount={2} fieldCount={3} marginY={5} marginX={4} />
        ) : (
          <>
            <div className="grid grid-cols-2 gap-y-1.5 px-2.5 my-1 py-2">
              <div>
                <label
                  htmlFor="contactPersonName"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Person Name:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonName"
                    id="contactPersonName"
                    name="contactPersonName"
                    // value={formData.contactPersonName}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Contact Person Name"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactPersonContactNumber"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Person Number:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonContactNumber"
                    id="contactPersonContactNumber"
                    name="contactPersonContactNumber"
                    // value={formData.contactPersonContactNumber}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Contact Person Number"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactPersonEmail"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Email Address:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonEmail"
                    id="contactPersonEmail"
                    name="contactPersonEmail"
                    // value={formData.contactPersonEmail}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
              active:ring-slate-200 w-[500px]"
                    placeholder="Enter Contact Email Address"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {loading && value === "" ? (
          <FormField colCount={4} fieldCount={1} marginY={5} marginX={4} />
        ) : (
          <>
            <button
              className="my-3 mx-2 bg-red-500 text-slate-50 px-4 py-1 w-72 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md"
              onClick={handleSubmit}
            >
              {isCreateUser ? "Create User" : "Edit User"}
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default UserAccountCU;
