import React, { useContext, useEffect, useState } from "react";
import FormField from "../../../Loader/FormField";
import { CustomerDetailsDTO } from "../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import { CustomerAccountSetupRequest } from "../../../../Infrastructure/Core/Request/Customer/CustomerAccountSetupRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  loadAccountSetup,
  loadCheckDomain,
} from "../../../../Application/Actions/loader";
import {
  getCustomerDetail,
  getDomainSuccess,
} from "../../../../Application/Selectors/customer";
import AuthContext from "../../../../Infrastructure/Core/Helper/AuthContext";
import { CommonFieldsDTO } from "../../../../Infrastructure/Core/DTOs/CommonFields/CommonFieldsDTO";
import CommonFields from "../../../../Infrastructure/Core/Helper/CommonFields";
import LoadingSpinner from "../../../Loader/LoadingSpinner";
import { getLoading } from "../../../../Application/Selectors/loader";

function AccountSetup(props: any) {
  const dispatch = useDispatch();
  const customerDetailsById: CustomerDetailsDTO =
    useSelector(getCustomerDetail);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  const [fudEasyManaged, setfudEasyManaged] = useState(true);
  const domainStatus = useSelector(getDomainSuccess);
  const { commonFields } = useContext(AuthContext) ?? { commonFields: [] };
  const domainTypeList = commonFields
    ? commonFields.filter((data: any) => data.fieldTypeCode === "DTYP")
    : [];
  const [formData, setFormData] = useState<CustomerAccountSetupRequest>({
    id: CommonFields.defaultValue,
    leadId: CommonFields.defaultValue,
    name: "",
    connectionString: "",
    domain: "",
    secret: "",
    isAccountSetup: false,
    isBranchSetup: false,
    isUserCreated: false,
    isMenuSetup: false,
    isCatalogueSetup: false,
    isBillingSetup: false,
    isBrandSetup: false,
    isTaxSetup: false,
    isSubscriptionSetup: false,
    activePlanId: CommonFields.defaultValue,
    planExpiredOn: new Date(),
    isDedicatedDatabase: true,
    databaseProvider: "SQL",
    status: CommonFields.status.active,
    isVisible: false,
    createdByName: CommonFields.defaultValue,
    createdOn: new Date(),
    modifiedByName: CommonFields.defaultValue,
    modifiedOn: new Date(),
    domainType: CommonFields.defaultValue,
  });

  useEffect(() => {
    setFormData({
      id: customerDetailsById?.id,
      leadId: customerDetailsById?.leadId,
      name: customerDetailsById?.name,
      connectionString: customerDetailsById?.connectionString,
      domain:
        customerDetailsById?.domain === "-" ? "" : customerDetailsById?.domain,
      secret: customerDetailsById?.secret,
      isAccountSetup: customerDetailsById?.isAccountSetup,
      isBranchSetup: customerDetailsById?.isBranchSetup,
      isUserCreated: customerDetailsById?.isUserCreated,
      isMenuSetup: customerDetailsById?.isMenuSetup,
      isCatalogueSetup: customerDetailsById?.isCatalogueSetup,
      isBillingSetup: customerDetailsById?.isBillingSetup,
      isBrandSetup: customerDetailsById?.isBrandSetup,
      isTaxSetup: customerDetailsById?.isTaxSetup,
      isSubscriptionSetup: customerDetailsById?.isSubscriptionSetup,
      activePlanId: customerDetailsById?.activePlanId,
      planExpiredOn: customerDetailsById?.planExpiredOn,
      isDedicatedDatabase: customerDetailsById?.isDedicatedDatabase,
      databaseProvider: customerDetailsById?.databaseProvider,
      status: customerDetailsById?.status,
      isVisible: customerDetailsById?.isVisible,
      createdByName: customerDetailsById?.createdBy,
      createdOn: customerDetailsById?.createdOn,
      modifiedByName: CommonFields.defaultValue,
      modifiedOn: new Date(),
      domainType: customerDetailsById?.domainType,
    });
    const domainTypeBool =
      customerDetailsById?.domainType === CommonFields.domainType.customDomain
        ? false
        : true;
    setfudEasyManaged(domainTypeBool);
  }, [customerDetailsById]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
    if (name === "domainType") {
      const domainTypeBool =
        newValue === CommonFields.domainType.customDomain ? false : true;
      setfudEasyManaged(domainTypeBool);
    } else if (name === "domain") {
      e.preventDefault();
      if (formData.domainType === CommonFields.domainType.fudeasyDomain) {
        dispatch(loadCheckDomain(newValue + ".fudeasy.com"));
      } else if (formData.domainType === CommonFields.domainType.customDomain) {
        dispatch(loadCheckDomain(newValue));
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(loadAccountSetup(formData));
  };

  return (
    <>
      <h1 className="font-medium py-1 text-base md:text-lg lg:text-lg xl:text-xl 2xl:text-xl">
        Account Setup
      </h1>
      <div className="bg-white h-fit md:h-[695px] lg:h-[710px] xl:h-[670px] 2xl:h-[730px] shadow-md rounded-md px-5 md:px-8">
        <div className="py-4">
          {loading && value === "getCustomerDetailsById" ? (
            <FormField fieldCount={5} colCount={1} marginX={1} marginY={4} />
          ) : (
            <>
              <form>
                <div className="grid grid-cols-1 gap-x-6 gap-y-2">
                  <div className="col-span-3">
                    <label
                      htmlFor="name"
                      className="block text-xs md:text-sm font-medium text-gray-900"
                    >
                      Business Name:
                      <span className="text-red-600 text-base">*</span>
                    </label>
                    <div className="mt-1">
                      <input
                        type="name"
                        id="name"
                        name="name"
                        onChange={setNewFormdata}
                        value={formData.name}
                        className="border w-full lg:w-6/12 xl:w-6/12 2xl:w-4/12 border-gray-400 text-gray-900 text-xs md:text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
          active:ring-slate-200"
                        placeholder="Enter Business Name"
                      />
                    </div>
                  </div>
                  <div className="col-span-3">
                    <label
                      htmlFor="domainType"
                      className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                    >
                      Domain Type:{" "}
                      <span className="text-red-600 text-base">*</span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="domainType"
                        name="domainType"
                        value={formData.domainType}
                        onChange={setNewFormdata}
                        className="py-1.5 px-2.5 block w-full lg:w-6/12 xl:w-6/12 2xl:w-4/12 rounded-sm text-xs md:text-sm text-gray-900 shadow-sm ring-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-400 border border-gray-400"
                      >
                        <option value={CommonFields.defaultValue} disabled>
                          Choose Domain Type
                        </option>
                        {domainTypeList.map(
                          (data: CommonFieldsDTO, index: any) => (
                            <option key={index} value={data.id}>
                              {data.fieldValue}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="domain"
                      className="block text-xs md:text-sm text-gray-700 font-medium"
                    >
                      Sub Domain Name:
                      <span className="text-red-600 text-base">*</span>
                    </label>
                    <div
                      className={`${
                        fudEasyManaged ? "flex" : ""
                      } rounded-lg mt-1`}
                    >
                      <input
                        type="text"
                        name="domain"
                        id="domain"
                        onChange={setNewFormdata}
                        value={formData.domain}
                        className={`py-1.5 px-2.5 block text-xs md:text-sm ${
                          fudEasyManaged
                            ? "w-[305px] border-s"
                            : "w-full lg:w-6/12 xl:w-6/12 2xl:w-4/12 border border-gray-400"
                        } border-y border-y-gray-400 border-s-gray-400 rounded-s-sm text-gray-900 shadow-sm ring-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-400`}
                        placeholder="Enter Sub Domain"
                      />
                      <div
                        className={`${
                          fudEasyManaged ? "inline-flex" : "hidden"
                        } px-2 2xl:px-4 items-center min-w-fit rounded-sm border border-gray-400 border-e-gray-400 bg-gray-50`}
                      >
                        <span className="text-xs md:text-sm  text-gray-500">
                          .fudeasy.com
                        </span>
                      </div>
                    </div>
                    <div
                      className={`items-center ${
                        domainStatus === undefined ? "hidden" : "block"
                      }`}
                    >
                      <span
                        className={`font-medium text-xs ${
                          domainStatus ? "text-red-600" : "text-green-600"
                        }`}
                      >
                        {domainStatus ? "Not Available" : "Available"}
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className={`bg-red-600 text-slate-50 py-1.5 w-40 md:w-52 lg:w-72 rounded-md font-medium my-6 hover:bg-red-500 active:bg-red-700 text-sm ${
                    domainStatus === null ? "cursor-pointer" : "cursor-default"
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    domainStatus !== null ||
                    (loading && value === "accountSetup")
                  }
                >
                  {loading && value === "accountSetup" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={20}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Proceed"
                  )}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AccountSetup;
