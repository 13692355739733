import { combineReducers } from "redux";
import enquiry from "./enquiry";
import loader from "./loader";
import customer from "./customer";
import salesRep from "./salesRep";
import plan from "./plan";
import common from "./common";

export default combineReducers({
    enquiry,
    loader,
    customer,
    salesRep,
    plan,
    common
});