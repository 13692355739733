import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from "../../../../Application/Selectors/loader";
import FormField from "../../../Loader/FormField";
import { useParams } from "react-router-dom";
import TaxGroupCU from "./TaxGroupSetup/TaxGroupCU";
import { getAllTaxGroupList } from "../../../../Application/Selectors/customer";
import { TaxGroupDTO } from "../../../../Infrastructure/Core/DTOs/Customer/TaxGroup/TaxGroupDTO";
import { loadTaxGroupList } from "../../../../Application/Actions/loader";
// import { CustomerDetailsDTO } from "../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import Environment from "../../../../Infrastructure/Core/Helper/Environment";
import CommonFields from "../../../../Infrastructure/Core/Helper/CommonFields";

function TaxGroupSetup(props: any) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const TaxGroupListData = useSelector(getAllTaxGroupList);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  const [isCreateTaxGroup, setIsCreateTaxGroup] = useState(false);
  const [isEditTaxGroup, setIsEditTaxGroup] = useState(false);
  const [TaxGroupList, setTaxGroupList] = useState<TaxGroupDTO[] | undefined>(
    undefined
  );
  const [selectedTaxId, setSelectedTaxId] = useState<string | null>(null);
  useEffect(() => {
    setIsCreateTaxGroup(props.isManage);
    setIsEditTaxGroup(props.isManage);
  }, [props]);
  useEffect(() => {
    dispatch(loadTaxGroupList(id));
  }, [id, dispatch]);

  useEffect(() => {
    setIsCreateTaxGroup(TaxGroupListData?.length === 0);
    setIsEditTaxGroup(false);
    setTaxGroupList(TaxGroupListData);
  }, [TaxGroupListData]);

  const updateIsCreateTaxGroup = (value: boolean) => {
    setIsCreateTaxGroup(value);
  };

  const updateIsEditTaxGroup = (value: boolean) => {
    setIsEditTaxGroup(value);
  };

  const setTaxGroupListSuccess = (data: TaxGroupDTO[]) => {
    setTaxGroupList(data);
  };

  const editTaxGroupForm = (id: any) => {
    setSelectedTaxId(id);
    setIsEditTaxGroup(true);
    setIsCreateTaxGroup(false);
  };

  return (
    <>
      <div className="flex justify-between items-center align-middle mb-3">
        <div>
          <h1 className="font-medium text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg">
            Tax Group Setup
          </h1>
        </div>
        <div>
          <button
            className={`bg-red-500 text-slate-50 py-1 text-xs md:text-sm lg:text-base xl:text-base 2xl:text-base w-32 md:w-36 lg:w-56 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md ${
              isCreateTaxGroup || isEditTaxGroup ? "hidden" : "block"
            }`}
            onClick={() => setIsCreateTaxGroup(true)}
          >
            Add New Tax Group
          </button>
        </div>
      </div>

      <div
        className={`bg-white h-[723px] shadow-md rounded-md border-1 p-2 ${
          isCreateTaxGroup || isEditTaxGroup ? "hidden" : "block"
        }`}
      >
        <div className="overflow-y-auto h-[650px] p-5">
          <div className="grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 me-3 gap-5">
            {loading && value === "taxGroupList" ? (
              <FormField colCount={4} fieldCount={5} marginY={4} marginX={4} />
            ) : (
              <>
                {TaxGroupList && TaxGroupList.length !== 0 ? (
                  <>
                    {TaxGroupList.map((Tax: TaxGroupDTO, index: any) => (
                      <div
                        key={index}
                        className="bg-white w-64 px-5 py-3 rounded-md shadow-sm shadow-slate-400 border-t"
                      >
                        <div className="flex">
                          <div className="me-2">
                            <span
                              className={`text-slate-50 px-2 py-0.5 uppercase text-xs rounded-full font-medium bg-green-500`}
                            >
                              {Tax.taxRate}%
                            </span>
                          </div>
                          <div className="">
                            <span className="bg-gray-500 text-slate-50 px-2 py-0.5 text-xs rounded-full font-medium">
                              {Tax.taxMethodId ===
                              CommonFields.taxMethod.exclusiveTax
                                ? "Exclusive"
                                : "Inclusive"}
                            </span>
                          </div>
                          <div
                            className="ml-auto items-center align-middle p-0.5 cursor-pointer"
                            onClick={() => editTaxGroupForm(Tax.id)}
                          >
                            <i className="fa-solid fa-pen-to-square text-lg"></i>
                          </div>
                        </div>

                        <div className="p-1">
                          <div className="">
                            <h3 className="font-bold">{Tax.taxGroupName}</h3>
                            <p className="text-sm">
                              <span>{Tax.taxGroupDescription}</span>
                            </p>
                          </div>

                          <div className="flex mt-2">
                            <div className="me-2">
                              <span className="text-xs">Location</span>
                              <h6 className="text-xs font-medium">
                                {Tax.branchId === CommonFields.defaultValue
                                  ? "All Locations"
                                  : "Branch Name"}
                              </h6>
                            </div>
                            <div>
                              <span className="text-xs">Created On</span>
                              <h6 className="text-xs font-medium">
                                {new Date(Tax.createdOn).toLocaleString()}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  "no data available"
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <TaxGroupCU
        isCreateTaxGroup={isCreateTaxGroup}
        isEditTaxGroup={isEditTaxGroup}
        loading={loading}
        updateIsCreateTaxGroup={updateIsCreateTaxGroup}
        updateIsEditTaxGroup={updateIsEditTaxGroup}
        TaxGroupListSuccess={setTaxGroupListSuccess}
        editTaxGroupData={TaxGroupList?.find(
          (Tax: any) => Tax.id === selectedTaxId
        )}
      />
    </>
  );
}

export default TaxGroupSetup;
