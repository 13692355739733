const Environment = {
  live: "https://gateway.fudeasy.com/api/",
  local: "https://localhost:7049/api/",
  stage: "",
  get baseUrl() {
    return this.stage === "dev" ? this.local : this.live;
  },
  get setUpUrl() {
    return `${this.baseUrl}setup`;
  },
  get tenantUrl() {
    return `${this.baseUrl}tenant`;
  },
  get dashboardMSPUrl() {
    return `${this.baseUrl}dashboardMSP`;
  },
  get subscriptionUrl() {
    return `${this.baseUrl}subscription`;
  },
  get accountUrl() {
    return `${this.baseUrl}account`;
  },
  get authenticateUrl() {
    return `${this.baseUrl}authenticate`;
  },
};

export default Environment;
