import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { configureStore } from "./Application/store.ts";
import services from "./Infrastructure/Services/index.ts";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./Infrastructure/Core/Helper/AuthContext.tsx";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={configureStore(services)}>
      <AuthContextProvider>
        <App />
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          // hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AuthContextProvider>
    </Provider>
  </BrowserRouter>
);
