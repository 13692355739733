import React, { useEffect, useState } from "react";
import { BranchRequest } from "../../../../../Infrastructure/Core/Request/Customer/Branch/BranchRequest";
import { CustomerDetailsDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCreateBranch,
  loadEditBranch,
} from "../../../../../Application/Actions/loader";
import FormField from "../../../../Loader/FormField";
import {
  getCreateBranchSuccess,
  getCustomerDetail,
  getEditBranchSuccess,
} from "../../../../../Application/Selectors/customer";
import { BranchDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/Branch/BranchDTO";
import {
  resetCreateBranchSuccess,
  resetEditBranchSuccess,
} from "../../../../../Application/Actions/customer";
import CommonFields from "../../../../../Infrastructure/Core/Helper/CommonFields";
import { getLoading } from "../../../../../Application/Selectors/loader";
import LoadingSpinner from "../../../../Loader/LoadingSpinner";

function CreateBranch(props: any) {
  const dispatch = useDispatch();
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  const [isCreateBranch, setIsCreateBranch] = useState<boolean>(true);
  const [isEditBranch, setIsEditBranch] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editBranchData, setEditBranchData] = useState<BranchDTO | undefined>(
    undefined
  );
  const tenantDetails: CustomerDetailsDTO = useSelector(getCustomerDetail);
  const createBranchSuccess = useSelector(getCreateBranchSuccess);
  const editBranchSuccess = useSelector(getEditBranchSuccess);
  const [formData, setFormData] = useState<BranchRequest>({
    id: CommonFields.defaultValue,
    tenantId: tenantDetails?.id ? tenantDetails?.id : CommonFields.defaultValue,
    branchId: CommonFields.defaultValue,
    status: CommonFields.status.active,
    isVisible: true,
    createdBy: CommonFields.defaultValue,
    createdOn: new Date(),
    modifiedBy: CommonFields.defaultValue,
    modifiedOn: new Date(),
    branchName: "",
    shortCode: "",
    type: CommonFields.defaultValue,
    currancyId: CommonFields.defaultValue,
    merchantId: "",
    duplicateprint: "",
    branchEmail: "",
    branchNotifyEmail: "",
    city: "",
    state: "",
    country: "",
    latitude: 0,
    longitude: 0,
    mapCode: "",
    contactNo: "",
    isFacebookEnable: false,
    facebookLink: "",
    isInstaEnable: false,
    instaLink: "",
    isTwitterEnable: false,
    twitterLink: "",
    isSnapchatEnable: false,
    snapChatLink: "",
    isLinkedInEnable: false,
    linkedInLink: "",
    isGoogleMapEnable: false,
    googleMapLink: "",
    isPrinterestEnable: false,
    printerestLink: "",
    isWhatsAppEnable: false,
    whatsAppLink: "",
    isYoutubeEnable: false,
    youtubeLink: "",
    isGoogleReviewEnable: false,
    isGoogleReviewLink: "",
    isBestseller: false,
    isFeatured: false,
    isDiscountAllowed: false,
    addressline1: "",
    addressline2: "",
    zipCode: "",
    contactPersonName: "",
    contactPersonContactNumber: "",
    contactPersonEmail: "",
    isDeleted: false,
    branchStartTime: "00:00:00",
    branchCloseTime: "00:00:00",
  });
  useEffect(() => {
    setIsCreateBranch(props.isCreateBranch);
    setIsLoading(loading);
    setIsEditBranch(props.isEditBranch);
    setEditBranchData(props.editBranchData);

    if (isCreateBranch) {
      setFormData({
        id: CommonFields.defaultValue,
        tenantId: tenantDetails?.id || CommonFields.defaultValue,
        branchId: CommonFields.defaultValue,
        status: CommonFields.status.active,
        isVisible: true,
        createdBy: CommonFields.defaultValue,
        createdOn: new Date(),
        modifiedBy: CommonFields.defaultValue,
        modifiedOn: new Date(),
        branchName: "",
        shortCode: "",
        type: CommonFields.defaultValue,
        currancyId: CommonFields.defaultValue,
        merchantId: "",
        duplicateprint: "",
        branchEmail: "",
        branchNotifyEmail: "",
        city: "",
        state: "",
        country: "",
        latitude: 0,
        longitude: 0,
        mapCode: "",
        contactNo: "",
        isFacebookEnable: false,
        facebookLink: "",
        isInstaEnable: false,
        instaLink: "",
        isTwitterEnable: false,
        twitterLink: "",
        isSnapchatEnable: false,
        snapChatLink: "",
        isLinkedInEnable: false,
        linkedInLink: "",
        isGoogleMapEnable: false,
        googleMapLink: "",
        isPrinterestEnable: false,
        printerestLink: "",
        isWhatsAppEnable: false,
        whatsAppLink: "",
        isYoutubeEnable: false,
        youtubeLink: "",
        isGoogleReviewEnable: false,
        isGoogleReviewLink: "",
        isBestseller: false,
        isFeatured: false,
        isDiscountAllowed: false,
        isDeleted: false,
        addressline1: "",
        addressline2: "",
        zipCode: "",
        contactPersonName: "",
        contactPersonContactNumber: "",
        contactPersonEmail: "",
        branchStartTime: "00:00:00",
        branchCloseTime: "00:00:00",
      });
    } else if (editBranchData) {
      setFormData({
        id: editBranchData.id || CommonFields.defaultValue,
        tenantId: tenantDetails?.id || CommonFields.defaultValue,
        branchId: editBranchData.id || CommonFields.defaultValue,
        status: editBranchData.status || CommonFields.status.active,
        isVisible: editBranchData.isVisible || false,
        createdBy: editBranchData.createdBy || CommonFields.defaultValue,
        createdOn: editBranchData.createdOn || new Date(),
        modifiedBy: editBranchData.modifiedBy || CommonFields.defaultValue,
        modifiedOn: editBranchData.modifiedOn || new Date(),
        branchName: editBranchData.branchName || "",
        shortCode: editBranchData.shortCode || "",
        type: editBranchData.type || CommonFields.defaultValue,
        currancyId: editBranchData.currancyId || CommonFields.defaultValue,
        merchantId: editBranchData.merchantId || "",
        duplicateprint: editBranchData.duplicateprint || "",
        branchEmail: editBranchData.branchEmail || "",
        branchNotifyEmail: editBranchData.branchNotifyEmail || "",
        city: editBranchData.city || "",
        state: editBranchData.state || "",
        country: editBranchData.country || "",
        latitude: editBranchData.latitude || 0,
        longitude: editBranchData.longitude || 0,
        mapCode: editBranchData.mapCode || "",
        contactNo: editBranchData.contactNo || "",
        isFacebookEnable: editBranchData.isFacebookEnable || false,
        facebookLink: editBranchData.facebookLink || "",
        isInstaEnable: editBranchData.isInstaEnable || false,
        instaLink: editBranchData.instaLink || "",
        isTwitterEnable: editBranchData.isTwitterEnable || false,
        twitterLink: editBranchData.twitterLink || "",
        isSnapchatEnable: editBranchData.isSnapchatEnable || false,
        snapChatLink: editBranchData.snapChatLink || "",
        isLinkedInEnable: editBranchData.isLinkedInEnable || false,
        linkedInLink: editBranchData.linkedInLink || "",
        isGoogleMapEnable: editBranchData.isGoogleMapEnable || false,
        googleMapLink: editBranchData.googleMapLink || "",
        isPrinterestEnable: editBranchData.isPrinterestEnable || false,
        printerestLink: editBranchData.printerestLink || "",
        isWhatsAppEnable: editBranchData.isWhatsAppEnable || false,
        whatsAppLink: editBranchData.whatsAppLink || "",
        isYoutubeEnable: editBranchData.isYoutubeEnable || false,
        youtubeLink: editBranchData.youtubeLink || "",
        isGoogleReviewEnable: editBranchData.isGoogleReviewEnable || false,
        isGoogleReviewLink: editBranchData.isGoogleReviewLink || "",
        isBestseller: editBranchData.isBestseller || false,
        isFeatured: editBranchData.isFeatured || false,
        isDiscountAllowed: editBranchData.isDiscountAllowed || false,
        isDeleted: editBranchData.isDeleted || false,
        addressline1: editBranchData.addressline1 || "",
        addressline2: editBranchData.addressline2 || "",
        zipCode: editBranchData.zipCode || "",
        contactPersonName: editBranchData.contactPersonName || "",
        contactPersonContactNumber:
          editBranchData.contactPersonContactNumber || "",
        contactPersonEmail: editBranchData.contactPersonEmail || "",
        branchStartTime: editBranchData.branchStartTime || "00:00:00",
        branchCloseTime: editBranchData.branchCloseTime || "00:00:00",
      });
    }
  }, [props, tenantDetails, editBranchData, isCreateBranch]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isCreateBranch) {
      dispatch(loadCreateBranch(formData));
    } else {
      dispatch(loadEditBranch(formData));
    }
  };
  useEffect(() => {
    if (createBranchSuccess) {
      props.updateIsCreateBranch(false);
      props.branchListSuccess(createBranchSuccess);
      setTimeout(() => {
        dispatch(resetCreateBranchSuccess());
      }, 1000);
    }
  }, [createBranchSuccess, props]);

  useEffect(() => {
    if (editBranchSuccess) {
      props.updateIsEditBranch(false);
      props.branchListSuccess(editBranchSuccess);
      setTimeout(() => {
        dispatch(resetEditBranchSuccess());
      }, 1000);
    }
  }, [editBranchSuccess, props]);

  return (
    <>
      <div
        className={`bg-white shadow-md rounded-md px-5 md:px-8  h-fit md:[725px] lg:h-[703px] xl:h-[665px] 2xl:h-[720px] ${
          isCreateBranch || isEditBranch ? "block" : "hidden"
        }`}
      >
        {!isLoading ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-1 mb-2 py-4 ">
              <div className="md:col-start-1">
                <label
                  htmlFor="branchName"
                  className="block text-xs font-medium text-gray-900"
                >
                  Branch / Location Name:
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="branchName"
                    id="branchName"
                    name="branchName"
                    value={formData.branchName}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full lg:w-full xl:w-full 2xl:w-full"
                    placeholder="Enter branch name"
                  />
                </div>
              </div>

              <div className="lg:col-start-2">
                <label
                  htmlFor="currancyId"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Currency: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-0.5">
                  <select
                    id="currancyId"
                    name="currancyId"
                    value={formData.currancyId}
                    onChange={setNewFormdata}
                    className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-full md:w-full"
                  >
                    <option value={0} disabled>
                      Choose Currency
                    </option>
                    <option value={1}>INR - ₹</option>
                    <option value={2}>USD - $</option>
                  </select>
                </div>
              </div>
            </div>
          </>
        ) : (
          <FormField colCount={3} fieldCount={4} marginY={5} marginX={4} />
        )}
        <hr />
        {!isLoading ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-1 mb-2 py-3">
              <div>
                <label
                  htmlFor="addressline1"
                  className="block text-xs font-medium text-gray-900"
                >
                  Address Line 1:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="addressline1"
                    id="addressline1"
                    name="addressline1"
                    value={formData.addressline1}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Address Line 1"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="addressline2"
                  className="block text-xs font-medium text-gray-900"
                >
                  Address Line 2:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="addressline2"
                    id="addressline2"
                    name="addressline2"
                    value={formData.addressline2}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Address Line 2"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="city"
                  className="block text-xs font-medium text-gray-900"
                >
                  City: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="city"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter City Name"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="state"
                  className="block text-xs font-medium text-gray-900"
                >
                  State: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="state"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter State"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-xs font-medium leading-6 text-gray-900"
                >
                  Country: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    value={formData.country}
                    onChange={setNewFormdata}
                    className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 focus:outline-none focus:ring-1 focus:ring-blue-400 w-full md:w-full 2xl:w-10/12"
                  >
                    <option value={""} disabled>
                      Choose Country
                    </option>
                    <option value={"India"}>India</option>
                    <option value={"USA"}>USA</option>
                  </select>
                </div>
              </div>

              <div>
                <label
                  htmlFor="zipCode"
                  className="block text-xs font-medium text-gray-900"
                >
                  Zip Code: <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="zipCode"
                    id="zipCode"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Zip Code"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <FormField colCount={2} fieldCount={14} marginY={5} marginX={4} />
        )}
        <hr />

        {!isLoading ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 mt-1 mb-2 py-2 gap-4">
              <div>
                <label
                  htmlFor="contactPersonName"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Person Name:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonName"
                    id="contactPersonName"
                    name="contactPersonName"
                    value={formData.contactPersonName}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Contact Person Name"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactPersonContactNumber"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Person Number:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonContactNumber"
                    id="contactPersonContactNumber"
                    name="contactPersonContactNumber"
                    value={formData.contactPersonContactNumber}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Contact Person Number"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactPersonEmail"
                  className="block text-xs font-medium text-gray-900"
                >
                  Contact Email Address:{" "}
                  <span className="text-red-600 text-base">*</span>
                </label>
                <div className="mt-1">
                  <input
                    type="contactPersonEmail"
                    id="contactPersonEmail"
                    name="contactPersonEmail"
                    value={formData.contactPersonEmail}
                    onChange={setNewFormdata}
                    className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-full md:w-full 2xl:w-10/12"
                    placeholder="Enter Contact Email Address"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <FormField colCount={2} fieldCount={3} marginY={5} marginX={4} />
        )}

        <button
          className="my-3 bg-red-500 text-slate-50 py-1 w-full md:w-9/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md"
          onClick={handleSubmit}
          disabled={
            loading && (value === "createBranch" || value === "editBranch")
          }
        >
          {loading && (value === "createBranch" || value === "editBranch") ? (
            <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
          ) : (
            <> {isCreateBranch ? "Create Branch" : "Edit Branch"}</>
          )}
        </button>
      </div>
    </>
  );
}

export default CreateBranch;
