import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../Infrastructure/Core/Helper/AuthContext";
interface TopNavProps {
  toggleSideNav: (isOpen: boolean) => void;
  isOpen: boolean;
}

const TopNav: React.FC<TopNavProps> = ({ isOpen, toggleSideNav }) => {
  const [isSideNavOpen, setSideNavOpen] = useState(isOpen);
  const authContext = useContext(AuthContext);
  useEffect(() => {
    setSideNavOpen(isOpen);
  }, [isOpen]);
  if (!authContext) {
    return null;
  }
  const { user, logout } = authContext;
  const handleToggleSideNav = () => {
    setSideNavOpen(!isSideNavOpen);
    toggleSideNav(!isSideNavOpen);
  };

  return (
    <header className="bg-white shadow-sm border-b border-slate-300 fixed top-0 left-0 right-0 z-50">
      <nav className="mx-auto flex items-center ps-2" aria-label="Global">
        <div
          className={`flex-1 flex transition-width duration-300 ease-in-out`}
        >
          <button className="block xl:hidden" onClick={handleToggleSideNav}>
            <div className="space-y-1.5 md:space-y-2 mx-3">
              <div className="w-4 md:w-7 h-0.5 bg-gray-600"></div>
              <div className="w-4 md:w-7 h-0.5 bg-gray-600"></div>
              <div className="w-4 md:w-7 h-0.5 bg-gray-600"></div>
            </div>
          </button>
          <div className="p-2 ">
            <img src={`/assets/logo.png`} alt="png" className={"w-32"} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex justify-center">
            <button className="inline-block relative mx-1 px-2">
              <i className="fa-solid fa-bell text-2xl text-orange-500 hover:text-orange-600 shake"></i>
              {/* {notificationCount > 0 && ( */}
              <>
                <span className="animate-ping absolute top-1 right-0.5 block h-2 w-2 rounded-full ring-2 ring-orange-300 bg-orange-400"></span>
                <span className="absolute top-1 right-0.5 inline-flex items-center justify-center h-4 w-4 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                  {/* {notificationCount} */}0
                </span>
              </>
              {/* )} */}
            </button>

            <button className="py-1.5 px-2 mx-1">
              <i className="fa-solid fa-user text-2xl text-gray-950 hover:text-gray-700"></i>
            </button>
            {user && (
              <button
                className="bg-white text-black border border-white rounded py-1.5 px-2 "
                type="button"
                onClick={() => {
                  if (logout) {
                    logout();
                  }
                }}
              >
                <i className="fa-solid fa-right-from-bracket text-2xl text-red-700 hover:text-red-600"></i>
              </button>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TopNav;
