import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EnquiryDetailDTO } from "../../../Infrastructure/Core/DTOs/Enquiry/EnquiryDetailDTO";
import {
  getEnquiryDetail,
  getTenantCreated,
} from "../../../Application/Selectors/enquiry";
import FormField from "../../Loader/FormField";
import { SalesRepDTO } from "../../../Infrastructure/Core/DTOs/SalesRep/SalesRepDTO";
import {
  resetTenantCreated,
  updateEnquiryDetail,
} from "../../../Application/Actions/enquiry";
import { loadLeadToCustomer } from "../../../Application/Actions/loader";
import { getSalesRepList } from "../../../Application/Selectors/salesRep";
import { CommonFieldsDTO } from "../../../Infrastructure/Core/DTOs/CommonFields/CommonFieldsDTO";
import { getLoading } from "../../../Application/Selectors/loader";
import CommonFields from "../../../Infrastructure/Core/Helper/CommonFields";
import LoadingSpinner from "../../Loader/LoadingSpinner";

function EnquiryDetailsForm(props: {
  planList: [];
  commonFields: CommonFieldsDTO[] | null;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let commonFields = props.commonFields;
  const enquiryDetail: EnquiryDetailDTO = useSelector(getEnquiryDetail);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  const saleStatusList = commonFields
    ? commonFields.filter((data: any) => data.fieldTypeCode === "Sale")
    : [];

  const salesRepList: SalesRepDTO[] = useSelector(getSalesRepList);
  let planList = props.planList;

  const [showPlanDD, setPlanDDShow] = useState(false);
  const [showReasonNotesLabel, setShowReasonNotesLabel] = useState(false);
  const tenantCreated = useSelector(getTenantCreated);

  const [formData, setFormData] = useState<EnquiryDetailDTO>({
    activePlanId: CommonFields.defaultValue,
    branchCount: 0,
    brandName: "",
    city: "",
    country: "",
    countryCode: "",
    createdOn: new Date(),
    dialCode: "",
    email: "",
    enableNewsInsightOffer: true,
    id: CommonFields.defaultValue,
    createdBy: CommonFields.defaultValue,
    modifiedBy: CommonFields.defaultValue,
    modifiedOn: new Date(),
    name: "",
    phone: "",
    saleRemark: "",
    saleStatus: CommonFields.defaultValue,
    salesRepresentative: "",
    state: "",
    tenantId: CommonFields.defaultValue,
    salesRepName: "",
  });

  useEffect(() => {
    if (enquiryDetail) {
      setFormData({
        ...formData,
        activePlanId: enquiryDetail.activePlanId || CommonFields.defaultValue,
        branchCount: enquiryDetail.branchCount || 0,
        brandName: enquiryDetail.brandName || "",
        city: enquiryDetail.city || "",
        country: enquiryDetail.country || "",
        countryCode: enquiryDetail.countryCode || "",
        createdOn: enquiryDetail.createdOn || new Date(),
        dialCode: enquiryDetail.dialCode || "",
        email: enquiryDetail.email || "",
        enableNewsInsightOffer: enquiryDetail.enableNewsInsightOffer || false,
        id: enquiryDetail.id || CommonFields.defaultValue,
        modifiedBy: enquiryDetail.modifiedBy || CommonFields.defaultValue,
        modifiedOn: enquiryDetail.modifiedOn || new Date(),
        name: enquiryDetail.name || "",
        phone: enquiryDetail.phone || "",
        saleRemark: enquiryDetail.saleRemark || "",
        saleStatus: enquiryDetail.saleStatus || CommonFields.defaultValue,
        salesRepresentative: enquiryDetail.salesRepresentative || CommonFields.defaultValue,
        state: enquiryDetail.state || "",
        tenantId: enquiryDetail.tenantId || CommonFields.defaultValue,
        salesRepName: enquiryDetail.salesRepName || "",
      });
      enquiryDetail?.saleStatus === CommonFields.saleStatus.salesDone
        ? setPlanDDShow(true)
        : setPlanDDShow(false);
      enquiryDetail?.saleStatus === CommonFields.saleStatus.noSales
        ? setShowReasonNotesLabel(true)
        : setShowReasonNotesLabel(false);
    }
  }, [enquiryDetail]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
    if (name === "saleStatus") {
      if (value === CommonFields.saleStatus.salesDone) {
        setPlanDDShow(true);
        setShowReasonNotesLabel(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          saleRemark: "",
        }));
      } else {
        value === CommonFields.saleStatus.noSales
          ? setShowReasonNotesLabel(true)
          : setShowReasonNotesLabel(false);
        setPlanDDShow(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          activePlanId: CommonFields.defaultValue,
          saleRemark: "",
        }));
      }
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (
      formData.saleStatus === CommonFields.saleStatus.salesDone &&
      formData.activePlanId !== CommonFields.defaultValue
    ) {
      dispatch(loadLeadToCustomer({ ...formData }));
    } else {
      dispatch(updateEnquiryDetail({ ...formData }));
    }
  };
  if (tenantCreated) {
    navigate(`/CustomerDetail/${tenantCreated?.id}`);
    setTimeout(() => {
      dispatch(resetTenantCreated);
    }, 100);
  }

  return (
    <div>
      <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-md border">
        <div className="rounded-t bg-white px-6 py-2">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-800 text-xs md:text-base lg:text-base xl:text-base font-bold py-1">
              Enquiry Details
            </h6>
             <button
                type="button"
                onClick={handleSubmit}
                className="bg-red-600 text-white active:bg-red-700 hover:bg-red-500 font-medium uppercase text-xs lg:text-base px-3 py-1 lg:px-10 lg:py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                disabled={loading && (value === "updateEnquiryDetail" || value==="leadToCustomer")}
              >
                {loading && (value === "updateEnquiryDetail" || value==="leadToCustomer") ? (
                  <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
                ) : (
                  "Update"
                )}
              </button>
          </div>
        </div>
        <hr />

        <div className="flex-auto px-8 py-4 bg-white rounded-b-xl h-fit">
          <form>
            {loading &&
            (value === "getEnquiryDetailsById" ||
              value === "getPlanList") ? (
              <FormField fieldCount={40} colCount={2} marginX={5} marginY={2} />
            ) : (
              <>
                <h6 className="text-sm font-bold uppercase my-1">Sales</h6>

                <div className="grid grid-col-1 md:grid-col-1 lg:grid-cols-2 gap-3">
                  <div className="mr-4">
                    <label
                      htmlFor="saleStatus"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sales status
                    </label>
                    <select
                      id="saleStatus"
                      name="saleStatus"
                      className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                      value={formData.saleStatus}
                      onChange={setNewFormdata}
                    >
                      <option value={CommonFields.defaultValue} disabled>
                        Choose Sales Status
                      </option>
                      {saleStatusList.map(
                        (data: CommonFieldsDTO, index: any) => (
                          <option key={index} value={data.id}>
                            {data.fieldValue}
                          </option>
                        )
                      )}
                    </select>
                  </div>

                  {showPlanDD ? (
                    <div className="">
                      <label
                        htmlFor="activePlanId"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Plan
                      </label>
                      <select
                        id="activePlanId"
                        name="activePlanId"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.activePlanId}
                        onChange={setNewFormdata}
                      >
                        <option value={CommonFields.defaultValue} disabled>
                          Choose Plan
                        </option>
                        {planList.map((data: any, index) => (
                          <option key={index} value={data.id}>
                            {data.planName}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mr-4">
                    <label
                      htmlFor="saleRemark"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {showReasonNotesLabel ? "Reason for no sale" : "Remark"}
                    </label>
                    <textarea
                      placeholder={
                        showReasonNotesLabel ? "Reason for no sale" : "Remark"
                      }
                      className="resize- w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                      name="saleRemark"
                      id="saleRemark"
                      value={formData.saleRemark}
                      onChange={setNewFormdata}
                      rows={1}
                    ></textarea>
                  </div>
                </div>

                <hr className="my-3" />

                <div className="my-3">
                  <h6 className="text-sm my-3 font-bold uppercase">
                    Business Information
                  </h6>
                  <div className="grid grid-col-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mr-4">
                    <div>
                      <label
                        htmlFor="brandName"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Business Name
                      </label>
                      <div>
                        <input
                          type="text"
                          name="brandName"
                          id="brandName"
                          placeholder="Business Name"
                          className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                          value={formData.brandName}
                          onChange={setNewFormdata}
                        />
                      </div>
                    </div>
                    <div className="xl:ml-2">
                      <label
                        htmlFor="branchCount"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Store Count
                      </label>
                      <div>
                        <input
                          type="text"
                          name="branchCount"
                          id="branchCount"
                          placeholder="Store Count"
                          className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                          value={formData.branchCount}
                          onChange={setNewFormdata}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="my-3" />

                <h6 className="text-sm my-2 font-bold uppercase ">
                  Personal Information
                </h6>
                <div className="grid grid-col-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mx-2">
                  <div className="mr-3">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name
                    </label>
                    <div>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="given-name"
                        placeholder="Name"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.name}
                        onChange={setNewFormdata}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contact number
                    </label>
                    <div>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Contact Number"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.phone}
                        onChange={setNewFormdata}
                      />
                    </div>
                  </div>
                  <div className="mr-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.email}
                        onChange={setNewFormdata}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <div>
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="given-name"
                        placeholder="City"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.city}
                        onChange={setNewFormdata}
                      />
                    </div>
                  </div>
                  <div className="mr-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div>
                      <input
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Country"
                        className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                        value={formData.country}
                        onChange={setNewFormdata}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-4" />

                <h6 className="text-sm my-3 font-bold uppercase">
                  Sales Information
                </h6>
                <div className="grid grid-col-1 md:grid-cols-1 lg:grid-cols-2 gap-4 m-2">
                  <div className="mr-3">
                    <label
                      htmlFor="salesRepresentative"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Sale Representative
                    </label>
                    <select
                      id="salesRepresentative"
                      name="salesRepresentative"
                      className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                      value={formData.salesRepresentative}
                      onChange={setNewFormdata}
                    >
                      <option value={CommonFields.defaultValue} disabled>
                        Choose Sales Representative
                      </option>
                      {salesRepList.map((data: SalesRepDTO, index) => (
                        <option key={index} value={data.id}>
                          {data.userName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default EnquiryDetailsForm;
