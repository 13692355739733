import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SubscriptionPlanSetupDTO } from "../../../../Infrastructure/Core/DTOs/Customer/SubscriptionPlan/SubscriptionPlanSetupDTO";
import {
  loadBranchList,
  loadCreateSubscriptionPlanSetup,
  loadPlanList,
  loadSubscriptionPlanSetupList,
} from "../../../../Application/Actions/loader";
import {
  getAllBranchList,
  getAllSubscriptionPlanSetupList,
  getCustomerDetail,
} from "../../../../Application/Selectors/customer";
import { BranchDTO } from "../../../../Infrastructure/Core/DTOs/Customer/Branch/BranchDTO";
import { getPlanList } from "../../../../Application/Selectors/plan";
import { SubscriptionPlanSetupRequest } from "../../../../Infrastructure/Core/Request/Customer/SubscriptionPlanSetup/SubscriptionPlanSetupRequest";
import { CustomerDetailsDTO } from "../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import Table from "../../../Loader/Table";
import CommonFields from "../../../../Infrastructure/Core/Helper/CommonFields";
import { getLoading } from "../../../../Application/Selectors/loader";

function SubscriptionPlanSetup(props: any) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const planList: [] = useSelector(getPlanList);
  const branchListData = useSelector(getAllBranchList);
  const subscriptionPlanSetupList: SubscriptionPlanSetupDTO[] = useSelector(
    getAllSubscriptionPlanSetupList
  );
  const tenantDetails: CustomerDetailsDTO = useSelector(getCustomerDetail);
  const loading: boolean = useSelector(getLoading).loading;
  const [formData, setFormData] = useState<SubscriptionPlanSetupRequest>({
    tenantId: id || CommonFields.defaultValue,
    branchId: CommonFields.defaultValue,
    status: CommonFields.status.active,
    isVisible: true,
    isDeleted: false,
    createdBy: CommonFields.defaultValue,
    customerId: CommonFields.defaultValue,
    planId: CommonFields.defaultValue,
    subscribedOn: new Date(),
    activeBranchCount: 1,
    discountPercent: 0,
  });

  const tableColsName = [
    "Billing Id",
    "Plan Name",
    "Branch Name",
    "Disc. %",
    "Price + Tax",
    "Activation Date",
    "Expiry Date",
    "Payment Status",
    // "Action",
  ];

  useEffect(() => {
    setFormData({
      tenantId: tenantDetails?.id || CommonFields.defaultValue,
      branchId: CommonFields.defaultValue,
      status: CommonFields.status.active,
      isVisible: true,
      isDeleted: false,
      createdBy: CommonFields.defaultValue,
      customerId: CommonFields.defaultValue,
      planId: CommonFields.defaultValue,
      subscribedOn: new Date(),
      activeBranchCount: 1,
      discountPercent: 0,
    });
    dispatch(loadBranchList(id));
  }, [tenantDetails]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };
  useEffect(() => {
    if (id) {
      dispatch(loadSubscriptionPlanSetupList(id));
      dispatch(loadPlanList);
    }
  }, [id]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const submitForData = {
      tenantId: formData.tenantId,
      branchId: formData.branchId,
      status: formData.status,
      isVisible: formData.isVisible,
      isDeleted: formData.isDeleted,
      createdBy: formData.createdBy,
      customerId: formData.tenantId,
      planId: formData.planId,
      subscribedOn: formData.subscribedOn,
      activeBranchCount: formData.activeBranchCount,
      discountPercent: Number(formData.discountPercent),
    };
    dispatch(loadCreateSubscriptionPlanSetup(submitForData));
  };
  return (
    <>
      <div className="md:w-96 lg:w-full">
        <div>
          <h1 className="font-medium text-lg">Apply Subcription</h1>
        </div>
        <form>
          <div className="my-3 p-5 md:my-0 md:py-2 md:px-0 md:grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3  ">
            <div>
              <label
                htmlFor="branchId"
                className="block text-xs font-medium text-gray-900"
              >
                Choose Branch
                <span className="text-red-600 text-base">*</span>
              </label>
              <select
                id="branchId"
                name="branchId"
                value={formData.branchId}
                onChange={setNewFormdata}
                className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-full"
              >
                {branchListData && branchListData?.length !== 0 ? (
                  <>
                    <option value={CommonFields.defaultValue} disabled>
                      Choose Branch
                    </option>
                    {branchListData?.map((branch: BranchDTO, index: any) => (
                      <option key={index} value={branch.id}>
                        {branch.branchName}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled value={0}>
                    No branch Available
                  </option>
                )}
              </select>
            </div>

            <div className="mt-2 md:mt-0">
              <label
                htmlFor="planId"
                className="block text-xs font-medium text-gray-900"
              >
                Choose Plan
                <span className="text-red-600 text-base">*</span>
              </label>
              <select
                id="planId"
                name="planId"
                value={formData.planId}
                onChange={setNewFormdata}
                className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-full"
              >
                {planList && planList.length !== 0 ? (
                  <option value={CommonFields.defaultValue} disabled>
                    Choose Plan
                  </option>
                ) : (
                  ""
                )}
                {planList && planList.length !== 0 ? (
                  <>
                    {planList.map((plan: any, index: any) => (
                      <option key={index} value={plan.id}>
                        {plan.planName}
                      </option>
                    ))}
                  </>
                ) : (
                  <option disabled value={0}>
                    No Plan Available
                  </option>
                )}
              </select>
            </div>

            <div className="mt-2 md:mt-0">
              <label
                htmlFor="discountPercent"
                className="block text-xs font-medium text-gray-900"
              >
                Discount
                <span className="text-red-600 text-base">*</span>
              </label>
              <div className="flex">
                <div className="w-10 md:w-10 z-10 text-center pointer-events-none flex items-center justify-center border border-gray-400 rounded-s bg-gray-200">
                  %
                </div>
                <input
                  value={formData.discountPercent}
                  onChange={setNewFormdata}
                  type="number"
                  className="py-1.5 px-2.5 block rounded-e border-y border-r border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-11/12"
                  placeholder="Tax Percent"
                  id="discountPercent"
                  name="discountPercent"
                />
              </div>
            </div>

            <div className="mt-6">
              <button
                className="bg-red-500 text-slate-50 px-2 py-1 w-full md:w-12/12 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md"
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </div>
        </form>

        <div className={`bg-white h-[665px] shadow-md rounded-md border-1`}>
          <div className="overflow-x-auto rounded-md">
            {!loading ? (
              <table className="w-full text-xs 2xl:text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    {tableColsName?.map((colName: any, index: any) => (
                      <th scope="col" className="px-5 py-3" key={index}>
                        {colName}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <>
                    {subscriptionPlanSetupList &&
                    subscriptionPlanSetupList.length !== 0 ? (
                      <>
                        {subscriptionPlanSetupList.map(
                          (
                            subsPlanSetupList: SubscriptionPlanSetupDTO,
                            index: any
                          ) => (
                            <tr key={index} className="bg-white border-b">
                              <th
                                scope="row"
                                className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {index + 1}
                              </th>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {subsPlanSetupList.planName}
                              </td>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {subsPlanSetupList.branchName}
                              </td>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {subsPlanSetupList.discountPercent} %
                              </td>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {subsPlanSetupList.price}
                              </td>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {new Date(
                                  subsPlanSetupList.subscribedOn
                                ).toLocaleString()}
                              </td>
                              <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                {new Date(
                                  subsPlanSetupList.expiredOn
                                ).toLocaleString()}
                              </td>
                              <td
                                className={`px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3`}
                              >
                                <span
                                  className={`text-white rounded-full py-0.5 ${
                                    subsPlanSetupList.paymentStatus ===
                                    CommonFields.payStatus.pending
                                      ? "bg-red-500 px-3"
                                      : "bg-green-500 px-6"
                                  }`}
                                >
                                  {subsPlanSetupList.paymentStatus ===
                                  CommonFields.payStatus.pending
                                    ? "Pending"
                                    : "Paid"}
                                </span>
                              </td>
                              {/* <td className="px-6 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
                                  {" "}
                                  <i className="fa-solid fa-pen-to-square text-lg"></i>
                                </td> */}
                            </tr>
                          )
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={tableColsName.length}>
                          <div className="flex justify-center items-center align-middle my-5">
                            <h1 className="font-medium"> No data available</h1>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
            ) : (
              <>
                <Table
                  colNames={tableColsName}
                  colsCount={tableColsName.length}
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* <SubscriptionPlanSetupCU
        // isCreateSubscriptionPlan={isCreateSubscriptionPlan}
        // isEditSubscriptionPlan={isEditSubscriptionPlan}
        tenantDetails={props.customerDetailsById}
        loading={props.loading}
        // updateIsCreateSubscriptionPlan={updateIsCreateSubscriptionPlan}
        // updateIsEditSubscriptionPlan={updateIsEditSubscriptionPlan}
        // // SubscriptionPlanListSuccess={setSubscriptionPlanListSuccess}
        // editSubscriptionPlanData={SubscriptionPlanList?.find(
        //   (SubscriptionPlan) =>
        //     SubscriptionPlan.id === selectedSubscriptionPlanId
        // )}
      /> */}
    </>
  );
}

export default SubscriptionPlanSetup;
