import React, { useContext, useEffect } from "react";
import SalesDetails from "./SalesDetails";
import EnquiryDetailsForm from "./EnquiryDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadEnquiryDetailsById, loadPlanList } from "../../../Application/Actions/loader";
import { getPlanList } from "../../../Application/Selectors/plan";
import AuthContext from "../../../Infrastructure/Core/Helper/AuthContext";

function EnquiryDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { commonFields } = useContext(AuthContext) ?? { commonFields: [] };
  useEffect(() => {
    if (id) {
      dispatch(loadEnquiryDetailsById(id));
      dispatch(loadPlanList);
    }
  }, [id, dispatch]);

  const planList :[] = useSelector(getPlanList);
  return (
    <>
      <section className="mt-16">
        <div className="md:flex md:flex-row">
          <div className="basis-6/12 lg:basis-8/12 drop-shadow-sm">
            <EnquiryDetailsForm planList={planList} commonFields={commonFields}/>
          </div>
          <div className="md:ml-5 basis-6/12 lg:basis-4/12 drop-shadow-sm">
            <SalesDetails/>
          </div>
        </div>
      </section>
    </>
  );
}

export default EnquiryDetails;
