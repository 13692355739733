import { useEffect, useState } from "react";
import { loadUserList } from "../../../../Application/Actions/loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserAccountCU from "./UserAccountSetup/UserAccountCU";
import { UserDTO } from "../../../../Infrastructure/Core/DTOs/Customer/User/UserDTO";
import { getAllUserList } from "../../../../Application/Selectors/customer";
import { getLoading } from "../../../../Application/Selectors/loader";
import DataTable, { TableStyles } from "react-data-table-component";
import ShimmerTable from "../../../Loader/Table";

function UserAccountSetup(props: any) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const UserListData: UserDTO[] = useSelector(getAllUserList);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;

  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [UserList, setUserList] = useState<UserDTO[] | undefined>(undefined);
  // const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const tableColsName = [
    "Id",
    "Name",
    "Username",
    "Contact Number",
    "Default Password",
    "Date Created",
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "20px",
      },
    },
  };
  const columns = [
    {
      name: "Id",
      selector: (row: any) => row.id,
      sortable: true,
      cell: (row: any) => (
        <span>{row.index}</span>
      ),
    },
    {
      name: "Name",
      selector: (row: UserDTO) => row.fullName,
      sortable: true,
      cell: (row: UserDTO) => (
        <div className="px-3 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.fullName}
        </div>
      ),
    }, {
      name: "Email",
      selector: (row: UserDTO) => row.email,
      sortable: true,
      cell: (row: UserDTO) => (
        <div className="px-3 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.email}
        </div>
      ),
    },
    {
      name: "Contact Number",
      selector: (row: UserDTO) => row.contactNumber,
      sortable: true,
      cell: (row: UserDTO) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.contactNumber}
        </div>
      ),
    },
    // {
    //   name: "Password",
    //   selector: (row: UserDTO) => "Pass@123",
    //   sortable: true,
    //   cell: (row: UserDTO) => (
    //     <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
    //       Pass@1234
    //     </div>
    //   ),
    // },
    {
      name: "Country",
      selector: (row: UserDTO) => row.createdOn,
      sortable: true,
      cell: (row: UserDTO) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {new Date(row.createdOn).toLocaleString()}
        </div>
      ),
    },
  ];
  useEffect(() => {
    dispatch(loadUserList(id));
  }, [id, dispatch]);

  useEffect(() => {
    setIsCreateUser(props.isManage);
    setIsEditUser(props.isManage);
  }, [props]);

  useEffect(() => {
    setIsCreateUser(UserListData?.length === 0);
    setIsEditUser(false);
    // setUserList(UserListData);
  }, [UserListData]);

  const updateIsCreateUser = (value: boolean) => {
    setIsCreateUser(value);
  };

  const updateIsEditUser = (value: boolean) => {
    setIsEditUser(value);
  };

  // const setUserListSuccess = (data: UserDTO[]) => {
  //   setUserList(data);
  // };

  // const editUserForm = (id: any) => {
  //   setSelectedUserId(id);
  //   setIsEditUser(true);
  //   setIsCreateUser(false);
  // };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const addIndexToData = (data: any) => {
    return data?.map((row: any, index: any) => ({
      ...row,
      index: index + 1,
    }));
  };
  const userList:UserDTO[] = addIndexToData(UserListData);
  const filteredData = userList.filter((item) => {
    return (
      item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.username.toLowerCase().includes(searchText.toLowerCase()) ||
      item.contactNumber.toLowerCase().includes(searchText.toLowerCase()) ||
      item.createdOn.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  return (
    <>
        {/* <div className="flex justify-between items-center align-middle mb-3"> */}
        <div>
          <h1 className="font-medium text-lg">Users</h1>
        </div>
        {/* <div>
          <button
            className={`bg-red-500 text-slate-50 px-2 py-1 w-56 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md ${
              isCreateUser || isEditUser ? "hidden" : "block"
            }`}
            onClick={() => setIsCreateUser(true)}
          >
            Add New User
          </button>
        </div> */}
        {/* </div> */}
        <div
          className={`${
            isCreateUser || isEditUser ? "hidden" : "block"
          }`}
        >
          {/* <div className="flex justify-end m-3"> */}
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="border-[1px] border-slate-300 px-2 py-2 rounded text-sm shadow focus:outline-none focus:ring-1 md:w-3/5 lg:w-3/5 xl:w-2/5 2xl:w-3/12 ease-linear transition-all duration-150 my-3"
            />
          {/* </div> */}
          <div className="overflow-x-auto md:h-[560px] lg:h-[656px] xl:h-[616px] 2xl:h-[676px] bg-white shadow-md rounded-md">
            {loading && value === "getEnquiryList" ? (
              <ShimmerTable
                colNames={tableColsName}
                colsCount={tableColsName.length}
              />
            ) : (
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                customStyles={customStyles}
              />
            )}
          </div>
        </div>
      <UserAccountCU
        isCreateUser={isCreateUser}
        isEditUser={isEditUser}
        loading={loading}
        value={props.value}
        updateIsCreateUser={updateIsCreateUser}
        updateIsEditUser={updateIsEditUser}
        // UserListSuccess={setUserListSuccess}
        // editUserData={UserList?.find((User: any) => User.id === selectedUserId)}
      />
    </>
  );
}

export default UserAccountSetup;
