import consoleLogger from './Logger/console';
import elasticSearchLogger from './Logger/elastic-search';
import api from './Api';

const env: string = 'production'; /* = process.NODE_ENV */

const services: any = {
  log: env === 'development' ? consoleLogger : elasticSearchLogger,
  api,
};

export default services;
