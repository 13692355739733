import { useContext, useState } from "react";
import AuthContext from "../../Infrastructure/Core/Helper/AuthContext";
import SideNav from "../Navigation/SideNav";
import TopNav from "../Navigation/TopNav";
// import Breadcrumbs from "../Navigation/Breadcrumbs";
import AppRouting from "../../Routing/AppRouting";
// import Footer from "../Navigation/Footer";
import Login from "../Account/Login";
import Footer from "../Navigation/Footer";
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isSideNavOpen, setSideNavOpen] = useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    return null;
  }

  const { user } = authContext;

  return (
    <>
      {/* <ToastContainer /> */}
      {!user ? (
        <Login />
      ) : (
        <div className="flex min-h-screen">
          <div>
            <SideNav
              isOpen={isSideNavOpen}
              toggleSideNav={(isOpen: boolean) => setSideNavOpen(isOpen)}
            />
          </div>

          <div className="flex flex-col flex-1 min-w-0">
            <TopNav
              isOpen={isSideNavOpen}
              toggleSideNav={(isOpen) => setSideNavOpen(isOpen)}
            />

            <div className="p-5 md:p-8 flex-1 overflow-y-auto relative md:pl-20">
              <div className="md:absolute md:left-20 xl:right-1 md:right-10 md:top-0 md:p-4 ml-16 md:ml-0">
                <div className="">
                  {/* <Breadcrumbs /> */}
                  <div className="my-14">
                    <AppRouting />
                  </div>
                </div>
              </div>
              {/* <div className="absolute py-2 left-1/2 md:py-0 bottom-0 lg:bottom-0 xl:bottom-0 2xl:bottom-0 mt-5">
                  <Footer />
                </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
