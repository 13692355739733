import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMenuListSuccess } from "../../../../Application/Selectors/customer";
import { getLoading } from "../../../../Application/Selectors/loader";
import DataTable, { TableStyles } from "react-data-table-component";
import ShimmerTable from "../../../Loader/Table";
interface Catalogue {
  sequence: number;
  catalogueName: string;
  catalogueCode: string;
  categoryCode: string;
  mainCategoryCode: string;
  description: string;
  longDesc: string;
  title: string;
  brand: string;
  tag: string;
  manufracturer: string;
  supplier: string;
  promoVideoLink: string;
  mainCatId: string;
  catId: string;
  subCatId: string;
  relation: string;
  relationValue: string;
  foodSegment: string;
  sellingType: string;
  productType: string;
  foodType: string;
  foodSubType: string;
  spicyLevel: string;
  mealTimeType: string;
  mealStartTime: string;
  preparationTime: string;
  mealEndTime: string;
  totalViews: 0;
  totalFavs: 0;
  imagePath: string;
  visibility: string;
  isImgUploaded: false;
  isGlobal: true;
  chefsSpecial: false;
  isDaySpecial: false;
  id: string;
  status: string;
  isVisible: true;
  isDeleted: false;
  tenantId: string;
  branchId: string;
  createdBy: string;
  modifiedBy: string;
  createdOn: string;
  modifiedOn: string;
}
function ItemSetup() {
  const menuList = useSelector(getMenuListSuccess);
  const loading = useSelector(getLoading)?.loading;
  const value = useSelector(getLoading)?.value;
  const catalogues: Catalogue[] = menuList?.catalogues;
  const [searchText, setSearchText] = useState("");
  const addIndexToData = (data: Catalogue[]) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1,
    }));
  };
  const cataloguesList = addIndexToData(catalogues);
  const filteredData = cataloguesList?.filter((item) => {
    return (
      item?.catalogueName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.catalogueCode?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.mainCategoryCode
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      item?.categoryCode?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.createdOn?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const columns = [
    {
      name: "ID",
      selector: (row: any) => row.index,
      sortable: true,
      cell: (row: any) => (
        <div className="px-2 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.index}
        </div>
      ),
    },
    {
      name: "Item Name",
      selector: (row: Catalogue) => row.catalogueName,
      sortable: true,
      cell: (row: Catalogue) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.catalogueName}
        </div>
      ),
    },
    {
      name: "Short Code",
      selector: (row: Catalogue) => row.catalogueCode,
      sortable: true,
      cell: (row: Catalogue) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.catalogueCode}
        </div>
      ),
    },
    {
      name: "Main Menu",
      selector: (row: Catalogue) => row.mainCategoryCode,
      sortable: true,
      cell: (row: Catalogue) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.mainCategoryCode}
        </div>
      ),
    },
    {
      name: "Sub Menu",
      selector: (row: Catalogue) => row.categoryCode,
      sortable: true,
      cell: (row: Catalogue) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.categoryCode}
        </div>
      ),
    },
    {
      name: "Date Created",
      selector: (row: Catalogue) => row.createdOn,
      sortable: true,
      cell: (row: Catalogue) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {new Date(row.createdOn).toLocaleString()}
        </div>
      ),
    },
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "15px",
      },
    },
  };
  return (
    <>
      <h3 className="text-slate-700 font-semibold text-xl">Item Catalogue</h3>
      <input
        type="text"
        value={searchText}
        onChange={handleSearch}
        placeholder="Search..."
        className="my-3 border-[1px] border-slate-300 px-2 py-2 rounded text-sm focus:outline-none focus:ring-1 md:w-3/5 lg:w-3/5 xl:w-2/5 2xl:w-3/12 ease-linear transition-all duration-150"
      />

      <div className="overflow-x-auto md:h-[560px] lg:h-[656px] xl:h-[616px] 2xl:h-[676px] bg-white shadow-md rounded-md">
        {loading && value === "uploadMenu" ? (
          <ShimmerTable
            colNames={["ID", "Name", "Type", "Parent", "Date Created"]}
            colsCount={5}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}

export default ItemSetup;
