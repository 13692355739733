import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnquiryStatusLogDTO } from "../../../Infrastructure/Core/DTOs/Enquiry/EnquiryStatusLogDTO";
import { EnquirySalesLogRequest } from "../../../Infrastructure/Core/Request/Enquiry/EnquirySalesLogRequest";
import { enquiryCallLog } from "../../../Application/Actions/enquiry";
import FormField from "../../Loader/FormField";
import TimeLine from "../../Loader/TimeLine";
import { EnquiryDetailDTO } from "../../../Infrastructure/Core/DTOs/Enquiry/EnquiryDetailDTO";
import {
  getEnquiryDetail,
  getSaleCallLog,
} from "../../../Application/Selectors/enquiry";
import { getLoading } from "../../../Application/Selectors/loader";
import CommonFields from "../../../Infrastructure/Core/Helper/CommonFields";
import DateTimePicker from "react-datetime-picker";

function SalesDetails() {
  const dispatch = useDispatch();
  const enquiryDetail: EnquiryDetailDTO = useSelector(getEnquiryDetail);
  const saleCallLog: EnquiryStatusLogDTO[] = useSelector(getSaleCallLog);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;

  const [saleCallLogList, setSaleCallLog] = useState(saleCallLog);
  const [salesFormData, setFormData] = useState<EnquirySalesLogRequest>({
    id: CommonFields.defaultValue,
    createOn: new Date(),
    createdBy: CommonFields.defaultValue,
    leadId: CommonFields.defaultValue,
    modifiedBy: CommonFields.defaultValue,
    modifiedOn: new Date(),
    remark: "",
    status: CommonFields.status.active,
    followUpOn: "",
  });

  useEffect(() => {
    if (
      enquiryDetail?.id !== undefined &&
      enquiryDetail?.saleStatus !== undefined
    ) {
      setFormData({
        id: CommonFields.defaultValue,
        createOn: new Date(),
        createdBy: CommonFields.defaultValue,
        leadId: enquiryDetail.id,
        modifiedBy: CommonFields.defaultValue,
        modifiedOn: new Date(),
        remark: "",
        status: enquiryDetail.saleStatus,
        followUpOn: "",
      });
    }
  }, [enquiryDetail]);

  useEffect(() => {
    if (saleCallLog !== undefined) {
      setSaleCallLog(saleCallLog);
    }
  }, [saleCallLog]);

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...salesFormData, [name]: value });
  };

  const handleDateChange = (date: any) => {
      setFormData({ ...salesFormData, followUpOn: date });
  };

  const handleSalesFormSubmit = () => {
    dispatch(enquiryCallLog(salesFormData));
    setFormData({ ...salesFormData, followUpOn: '' });
  };

  return (
    <div className="my-5 md:my-0">
      <div className="relative flex flex-col break-words w-full rounded-md border">
        <div className="rounded-t bg-white px-6 py-2">
          <div className="text-center flex justify-between">
            <h6 className="text-slate-800 text-xs md:text-base lg:text-base xl:text-base font-bold py-1">
              Sales Details
            </h6>
            <button
              className="bg-red-600 text-white active:bg-red-700 hover:bg-red-500 font-medium uppercase text-xs lg:text-base px-3 py-1 lg:px-10 lg:py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              onClick={()=>handleSalesFormSubmit()}
            >
              Update
            </button>
          </div>
        </div>

        <hr />

        <div className="px-8 bg-white rounded-b-md h-[500px] md:h-[725px] drop-shadow-lg">
          <form>
            <div className="mt-3">
              {loading && value === "enquiryCallLog" ? (
                <FormField
                  fieldCount={2}
                  colCount={1}
                  marginY={3}
                  marginX={1}
                />
              ) : (
                <>
                  <div>
                    <label
                      htmlFor="remark"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Remarks
                    </label>
                    <textarea
                      placeholder="Remarks"
                      className="resize-x w-full border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 ease-linear transition-all duration-150"
                      name="remark"
                      id="remark"
                      onChange={setNewFormdata}
                    ></textarea>
                  </div>

                  <div>
                    <label
                      htmlFor="followUpOn"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Follow Up On
                    </label>
                    <DateTimePicker
                      disableClock={true}
                      onChange={(e)=>handleDateChange(e)}
                      value={salesFormData.followUpOn}
                      format="dd-MM-yyyy hh:mm aa"
                      id="followUpOn"
                      name="followUpOn"
                      className="border-[1px] border-slate-300 px-2 py-2 bg-slate-50 rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </>
              )}
            </div>
          </form>

          <h1 className="my-4 font-medium text-lg">Status Log</h1>

          <div className="h-[28rem] overflow-y-auto">
            <div className="m-4 ">
              {loading && value === "enquiryCallLog" ? (
                <TimeLine />
              ) : (
                <>
                  {saleCallLogList && saleCallLogList?.length !== 0 ? (
                    <ol className="relative border-s border-gray-400">
                      {saleCallLogList.map(
                        (salesLog: EnquiryStatusLogDTO, index: number) => (
                          <li className="ms-4" key={index}>
                            <div className="absolute w-3 h-3 bg-gray-400 rounded-md mt-1.5 -start-1.5"></div>
                            <time className="mb-1 text-xs font-normal leading-none text-gray-600">
                              {new Date(salesLog.createOn).toLocaleString()}
                            </time>
                            <p className="mb-1 text-xs font-normal text-gray-600">
                              Follow Up Date: (
                              {new Date(salesLog.followupOn).toLocaleString()})
                            </p>
                            <p className="mb-4 text-xs font-normal text-gray-600">
                              Remark: {salesLog.remark}
                            </p>
                          </li>
                        )
                      )}
                    </ol>
                  ) : (
                    <p className="mb-4 text-xs font-normal text-gray-600">
                      No Logs Available
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesDetails;
