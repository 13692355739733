// get commonfield list
export const COMMONFIELD_LIST = "COMMONFIELD_LIST";
export const COMMONFIELD_LIST_SUCCESS = "COMMONFIELD_LIST_SUCCESS";

export const CommonFieldsList = {
  type: COMMONFIELD_LIST,
};
export const commonFieldsListSuccess = (data: any) => ({
  type: COMMONFIELD_LIST_SUCCESS,
  payload: data,
});
