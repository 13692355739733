import Environment from "../../../Core/Helper/Environment";
import jwtInterceptor from "../../../Core/Helper/JwtInterceptor";

let storedTokenString = localStorage.getItem("Token");
let parsedToken = storedTokenString ? JSON.parse(storedTokenString) : null;
let token = parsedToken?.token ?? null;
let headers={
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  authorization: `Bearer ${token}`,
}
const salesRepList = async () => {
    try {
      const response = await jwtInterceptor.get(`${Environment.dashboardMSPUrl}/sale-representative`,
        {
          headers: headers
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export default{
    salesRepList
  }
  