import React from "react";
import { TailSpin, ThreeDots } from "react-loader-spinner";

interface LoadingSpinnerProps {
  color: string;
  size: number;
  type: "TailSpin" | "ThreeDots";
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color,
  size,
  type,
}: LoadingSpinnerProps) => {
  return (
    <>
      {type === "TailSpin" ? (
        <div style={{ display: "inline-block" }}>
          <TailSpin color={color} height={size} />
        </div>
      ) : type === "ThreeDots" ? (
        <div style={{ display: "inline-block" }}>
          <ThreeDots color={color} height={size} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoadingSpinner;
