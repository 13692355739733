import CommonFields from "../../../Core/Helper/CommonFields";
import Environment from "../../../Core/Helper/Environment";
import jwtInterceptor from "../../../Core/Helper/JwtInterceptor";
import { BranchRequest } from "../../../Core/Request/Customer/Branch/BranchRequest";
import { TaxGroupRequest } from "../../../Core/Request/Customer/TaxGroup/TaxGroupRequest";

let storedTokenString = localStorage.getItem("Token");
let parsedToken = storedTokenString ? JSON.parse(storedTokenString) : null;
let token = parsedToken?.token ?? null;
let headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  authorization: `Bearer ${token}`,
};

const getCustomerList = async () => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.tenantUrl}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const getCustomerDetailById = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.tenantUrl}/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const checkDomain = async (data: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.dashboardMSPUrl}/check-domain/${data}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const accountSetup = async (data: any) => {
  try {
    const formData = {
      id: data.id,
      leadId: data.leadId,
      name: data.name,
      connectionString: data.connectionString,
      domain:
        data.domainType === CommonFields.domainType.fudeasyDomain
          ? data.domain + ".fudeasy.com"
          : data.domain,
      secret: data.secret,
      isAccountSetup: true,
      isBranchSetup: false,
      isUserCreated: false,
      isMenuSetup: false,
      isCatalogueSetup: false,
      isBillingSetup: false,
      isSubscriptionSetup: false,
      isBrandSetup: false,
      isTaxSetup: false,
      activePlanId: data.activePlanId,
      planExpiredOn: new Date(),
      isDedicatedDatabase: true,
      databaseProvider: "SQL",
      status: CommonFields.status.active,
      isVisible: true,
      createdByName: data.createdByName,
      createdOn: data.createdOn,
      modifiedByName: data.modifiedByName,
      modifiedOn: data.createdOn,
      domainType: data.domainType,
    };
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/update-tenant`,
      formData,
      {
        headers: headers,
      }
    );
    if (response.status === 200) {
      try {
        await jwtInterceptor.post(
          `${Environment.tenantUrl}/MigrateAllTenant`,
          {
            headers: headers,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
};
const createBranch = async (data: BranchRequest) => {
  try {
    const formData = {
      id: data.id,
      tenantId: data.tenantId,
      branchId: data.branchId,
      status: data.status,
      isVisible: data.isVisible,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
      branchName: data.branchName,
      shortCode: data.shortCode,
      type: data.type,
      currancyId: data.currancyId,
      merchantId: data.merchantId,
      duplicateprint: data.duplicateprint,
      branchEmail: data.branchEmail,
      branchNotifyEmail: data.branchNotifyEmail,
      addressline1: data.addressline1,
      addressline2: data.addressline2,
      zipCode: data.zipCode,
      contactPersonName: data.contactPersonName,
      contactPersonContactNumber: data.contactPersonContactNumber,
      contactPersonEmail: data.contactPersonEmail,
      isDeleted: data.isDeleted,
      city: data.city,
      state: data.state,
      country: data.country,
      latitude: data.latitude,
      longitude: data.longitude,
      mapCode: data.mapCode,
      contactNo: data.contactNo,
      isFacebookEnable: data.isFacebookEnable,
      facebookLink: data.facebookLink,
      isInstaEnable: data.isInstaEnable,
      instaLink: data.instaLink,
      isTwitterEnable: data.isTwitterEnable,
      twitterLink: data.twitterLink,
      isSnapchatEnable: data.isSnapchatEnable,
      snapChatLink: data.snapChatLink,
      isLinkedInEnable: data.isLinkedInEnable,
      linkedInLink: data.linkedInLink,
      isGoogleMapEnable: data.isGoogleMapEnable,
      googleMapLink: data.googleMapLink,
      isPrinterestEnable: data.isPrinterestEnable,
      printerestLink: data.printerestLink,
      isWhatsAppEnable: data.isWhatsAppEnable,
      whatsAppLink: data.whatsAppLink,
      isYoutubeEnable: data.isYoutubeEnable,
      youtubeLink: data.youtubeLink,
      isGoogleReviewEnable: data.isGoogleReviewEnable,
      isGoogleReviewLink: data.isGoogleReviewLink,
      isBestseller: data.isBestseller,
      isFeatured: data.isFeatured,
      isDiscountAllowed: data.isDiscountAllowed,
    };
    const response = await jwtInterceptor.post(
      `${Environment.setUpUrl}/add-branch`,
      formData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createSubscriptionPlanSetup = async (data: any) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.subscriptionUrl}/add-subscription`,
      data,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const editBranch = async (data: BranchRequest) => {
  try {
    const formData = {
      id: data.id,
      tenantId: data.tenantId,
      branchId: data.branchId,
      status: data.status,
      isVisible: data.isVisible,
      createdBy: data.createdBy,
      createdOn: data.createdOn,
      modifiedBy: data.modifiedBy,
      modifiedOn: data.modifiedOn,
      branchName: data.branchName,
      shortCode: data.shortCode,
      type: data.type,
      currancyId: data.currancyId,
      merchantId: data.merchantId,
      duplicateprint: data.duplicateprint,
      branchEmail: data.branchEmail,
      branchNotifyEmail: data.branchNotifyEmail,
      addressline1: data.addressline1,
      addressline2: data.addressline2,
      zipCode: data.zipCode,
      contactPersonName: data.contactPersonName,
      contactPersonContactNumber: data.contactPersonContactNumber,
      contactPersonEmail: data.contactPersonEmail,
      isDeleted: data.isDeleted,
      city: data.city,
      state: data.state,
      country: data.country,
      latitude: data.latitude,
      longitude: data.longitude,
      mapCode: data.mapCode,
      contactNo: data.contactNo,
      isFacebookEnable: data.isFacebookEnable,
      facebookLink: data.facebookLink,
      isInstaEnable: data.isInstaEnable,
      instaLink: data.instaLink,
      isTwitterEnable: data.isTwitterEnable,
      twitterLink: data.twitterLink,
      isSnapchatEnable: data.isSnapchatEnable,
      snapChatLink: data.snapChatLink,
      isLinkedInEnable: data.isLinkedInEnable,
      linkedInLink: data.linkedInLink,
      isGoogleMapEnable: data.isGoogleMapEnable,
      googleMapLink: data.googleMapLink,
      isPrinterestEnable: data.isPrinterestEnable,
      printerestLink: data.printerestLink,
      isWhatsAppEnable: data.isWhatsAppEnable,
      whatsAppLink: data.whatsAppLink,
      isYoutubeEnable: data.isYoutubeEnable,
      youtubeLink: data.youtubeLink,
      isGoogleReviewEnable: data.isGoogleReviewEnable,
      isGoogleReviewLink: data.isGoogleReviewLink,
      isBestseller: data.isBestseller,
      isFeatured: data.isFeatured,
      isDiscountAllowed: data.isDiscountAllowed,
    };
    const response = await jwtInterceptor.post(
      `${Environment.setUpUrl}/update-branch`,
      formData,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const branchList = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.setUpUrl}/branch-by-tenant/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const userList = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.accountUrl}/user-by-tenant/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const subscriptionPlanSetupList = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.subscriptionUrl}/subscriptions-by-tenant/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const branchDetails = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.setUpUrl}/branch-detail/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createTaxGroup = async (data: any) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/add-tax-group/${data.tenantId}`,
      data,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const taxGroupList = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.dashboardMSPUrl}/tax-group-by-tenant/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const editTaxGroup = async (data: TaxGroupRequest) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/update-tax-group/${data.tenantId}`,
      data,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const uploadMenu = async (data:any) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.dashboardMSPUrl}/upload-menu`,
      data,
      {
        headers: {...headers, 'Content-Type': 'multipart/form-data'}
        
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const loadMenuList = async (id:any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.dashboardMSPUrl}/uploaded-menu/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userList,
  getCustomerList,
  getCustomerDetailById,
  checkDomain,
  accountSetup,
  createBranch,
  branchList,
  branchDetails,
  editBranch,
  createTaxGroup,
  taxGroupList,
  editTaxGroup,
  createSubscriptionPlanSetup,
  subscriptionPlanSetupList,
  uploadMenu,
  loadMenuList
};
