import React from "react";

interface ShimmerTableProps {
  colsCount: number;
  colNames: string[];
}

const ShimmerTable: React.FC<ShimmerTableProps> = ({ colsCount, colNames }) => {
  const renderTableHeader = () => {
    return colNames.map((name, index) => (
      <th
        key={index}
        scope="col"
        className="px-6 py-3 bg-gray-300 text-left text-xs font-medium text-gray-900 tracking-wider"
      >
        {name}
      </th>
    ));
  };

  const renderTableRows = () => {
    const rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <tr key={i}>
          {colNames.map((_, index) => (
            <td key={index} className="px-6 py-4">
              <div className="h-4 bg-gray-200 rounded"></div>
            </td>
          ))}
        </tr>
      );
    }
    return rows;
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>{renderTableHeader()}</tr>
      </thead>
      <tbody className="animate-pulse">{renderTableRows()}</tbody>
    </table>
  );
};

export default ShimmerTable;
