import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerDetailsDTO } from "../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import CommonFields from "../../../../Infrastructure/Core/Helper/CommonFields";
import {
  loadMenuList,
  uploadMenu,
} from "../../../../Application/Actions/customer";
import { getMenuListSuccess } from "../../../../Application/Selectors/customer";
import DataTable, { TableStyles } from "react-data-table-component";
import { getLoading } from "../../../../Application/Selectors/loader";
import ShimmerTable from "../../../Loader/Table";
import { useParams } from "react-router-dom";

interface MainCategory {
  mainCatName: string;
  mainCategoryCode: string;
  mainCatDesc: string;
  sequence: number;
  id: string;
  status: string;
  isVisible: boolean;
  isDeleted: boolean;
  tenantId: string;
  branchId: string;
  createdBy: string;
  modifiedBy: string;
  createdOn: string;
  modifiedOn: string;
}
interface SubCategory {
  name: string;
  categoryCode: string;
  mainCategoryCode: string;
  description: string;
  mainCategoryId: string;
  sequence: number;
  id: string;
  status: string;
  isVisible: boolean;
  isDeleted: boolean;
  tenantId: string;
  branchId: string;
  createdBy: string;
  modifiedBy: string;
  createdOn: string;
  modifiedOn: string;
}
interface CategoryMatch {
  id: string;
  name: string;
  type: "Main Menu" | "Sub Menu";
  parent: string;
  dateCreated: string;
}
function matchCategories(
  mainCats: MainCategory[],
  subCats: SubCategory[]
): CategoryMatch[] {
  const mainCatMap = mainCats?.reduce<Record<string, MainCategory>>(
    (acc, cat) => {
      acc[cat?.mainCategoryCode] = cat;
      return acc;
    },
    {}
  );
  const result: CategoryMatch[] = subCats?.map((subCat) => {
    const parentCat = mainCatMap[subCat?.mainCategoryCode];
    return {
      id: subCat?.categoryCode,
      name: subCat?.name,
      type: "Sub Menu" as const,
      parent: parentCat ? parentCat?.mainCatName : "-",
      dateCreated: new Date(subCat?.createdOn)?.toLocaleString(),
    };
  });
  mainCats?.forEach((mainCat) => {
    result?.push({
      id: mainCat?.mainCategoryCode,
      name: mainCat?.mainCatName,
      type: "Main Menu" as const,
      parent: "-",
      dateCreated: new Date(mainCat?.createdOn)?.toLocaleString(),
    });
  });
  return result;
}
function MenuSetup(props: any) {
  const dispatch = useDispatch();
  const menuList = useSelector(getMenuListSuccess);
  const [tenantDetails, setTenantDetails] = useState<
    CustomerDetailsDTO | undefined
  >(undefined);
  const [menuFile, setMenuFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const loading = useSelector(getLoading)?.loading;
  const value = useSelector(getLoading)?.value;
  const mainCategories: MainCategory[] = menuList?.mainCategories;
  const subCategories: SubCategory[] = menuList?.categories;
  const result = matchCategories(mainCategories, subCategories);
  const [searchText, setSearchText] = useState("");
  const { id } = useParams();
  const addIndexToData = (data: any) => {
    return data?.map((row: any, index: any) => ({
      ...row,
      index: index + 1,
    }));
  };
  const resultList = addIndexToData(result);
  const filteredData = resultList?.filter((item: any) => {
    return (
      item?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.type?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.parent?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      item?.dateCreated?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  useEffect(() => {
    setMenuFile(null);
    setTenantDetails(props?.customerDetailsById);
  }, [props, tenantDetails]);
  useEffect(() => {
    if (id) {
      dispatch(loadMenuList(id));
    }
  }, [id]);

  const setNewFormdata = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMenuFile(e.target.files ? e.target.files[0] : null);
  };
  const handleUploadMenu = (e: React.FormEvent) => {
    e.preventDefault();
    const formObject = new FormData();
    formObject.append(
      "TenantId",
      tenantDetails?.id || CommonFields.defaultValue
    );
    formObject.append("Type", "0");
    if (menuFile) {
      formObject.append("DataFile", menuFile);
    }
    dispatch(uploadMenu(formObject));
    setMenuFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const columns = [
    {
      name: "ID",
      selector: (row: any) => row.index,
      sortable: true,
      cell: (row: any) => (
        <div className="px-2 py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.index}
        </div>
      ),
    },
    {
      name: "Name",
      selector: (row: CategoryMatch) => row.name,
      sortable: true,
      cell: (row: CategoryMatch) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">{row.name}</div>
      ),
    },
    {
      name: "Type",
      selector: (row: CategoryMatch) => row.type,
      sortable: true,
      cell: (row: CategoryMatch) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">{row.type}</div>
      ),
    },
    {
      name: "Parent",
      selector: (row: CategoryMatch) => row.parent,
      sortable: true,
      cell: (row: CategoryMatch) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {row.parent}
        </div>
      ),
    },
    {
      name: "Date Created",
      selector: (row: CategoryMatch) => row.dateCreated,
      sortable: true,
      cell: (row: CategoryMatch) => (
        <div className=" py-1 md:py-2 lg:py-2 xl:py-3 2xl:py-3">
          {new Date(row.dateCreated).toLocaleString()}
        </div>
      ),
    },
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "20px",
      },
    },
  };
  return (
    <>
      <h1 className="font-medium text-lg">Menu Categorisation</h1>

      <div className="md:flex md:items-center md:justify-between md:mr-4">
        <form>
          <div className="flex justify-start my-2 md:border md:border-slate-300 md:rounded-md">
            <input
              id="DataFile"
              type="file"
              name="DataFile"
              accept=".xlsx"
              onChange={setNewFormdata}
              ref={fileInputRef}
              className="file:border-0 file:px-2 file:py-1 file:mr-4 file:bg-slate-200 file:text-sm appearance-none text-sm focus:outline-none border bg-white focus:ring-blue-600 focus:border-blue-600 text-purple-900 placeholder-blue-300 font-semibold rounded-l p-1"
            />
            <button
              type="submit"
              className="bg-red-500 hover:bg-red-800 px-4 text-sm font-medium tracking-wide hover:shadow-2xl cursor-pointer transition duration-250 ease-in-out rounded-r text-white"
              value="Search"
              onClick={handleUploadMenu}
            >
              Upload
            </button>
          </div>
        </form>

        <div className=" md:px-2 py-1 rounded-sm border border-dashed border-slate-500 shadow-md bg-slate-50">
          <a
            href={`${process.env.PUBLIC_URL}/assets/Excel/FudEasy-Data-Mapping.xlsx`}
            download="Menu-Categorisation-Template"
          >
            <div className="flex items-center">
              <img className="w-8 mx-2" src="/assets/excel.svg" alt="excel" />
              <button className="font-medium text-sm ">
                Download Template
              </button>
            </div>
          </a>
        </div>
      </div>

      <input
        type="text"
        value={searchText}
        onChange={handleSearch}
        placeholder="Search..."
        className="my-3 border-[1px] border-slate-300 px-2 py-2 rounded text-sm focus:outline-none focus:ring-1 md:w-3/5 lg:w-3/5 xl:w-2/5 2xl:w-3/12 ease-linear transition-all duration-150"
      />

      <div className="overflow-x-auto md:h-[665px] lg:h-[605px] xl:h-[560px] 2xl:h-[620px] bg-white shadow-md rounded-md">
        {loading && value === "uploadMenu" ? (
          <ShimmerTable
            colNames={["ID", "Name", "Type", "Parent", "Date Created"]}
            colsCount={5}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}

export default MenuSetup;
