import {
  SALES_REP_LIST,
  salesRepListFailure,
  salesRepListSuccess,
} from "../Actions/salesRep";
import * as loaderActions from "../Actions/loader";

const getSalesRepList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === SALES_REP_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "getSalesRepList" })
        );
        const salesRepList = await api.SalesRep.salesRepList();
        dispatch(salesRepListSuccess(salesRepList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getSalesRepList" })
        );
        dispatch(salesRepListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getSalesRepList" })
        );
      }
    }
    next(action);
  };

export default [getSalesRepList];
