import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-tailwindcss-select";
import { CustomerDetailsDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import { TaxGroupDTO } from "../../../../../Infrastructure/Core/DTOs/Customer/TaxGroup/TaxGroupDTO";
import {
  getCreateTaxGroupSuccess,
  getCustomerDetail,
  getEditTaxGroupSuccess,
} from "../../../../../Application/Selectors/customer";
import { TaxGroupRequest } from "../../../../../Infrastructure/Core/Request/Customer/TaxGroup/TaxGroupRequest";
import {
  loadCreateTaxGroup,
  loadEditTaxGroup,
} from "../../../../../Application/Actions/loader";
import {
  resetCreateTaxGroupSuccess,
  resetEditTaxGroupSuccess,
} from "../../../../../Application/Actions/customer";
import Environment from "../../../../../Infrastructure/Core/Helper/Environment";
import AuthContext from "../../../../../Infrastructure/Core/Helper/AuthContext";
import { CommonFieldsDTO } from "../../../../../Infrastructure/Core/DTOs/CommonFields/CommonFieldsDTO";
import CommonFields from "../../../../../Infrastructure/Core/Helper/CommonFields";

function TaxGroupCU(props: any) {
  const { commonFields } = useContext(AuthContext) ?? { commonFields: [] };
  const taxTypeList = commonFields ? commonFields.filter((data: any) => data.fieldTypeCode === "TAXCD"): [];
  const taxMethodList = commonFields ? commonFields.filter((data: any) => data.fieldTypeCode === "TAXME"): [];
  const AppliedOnList = commonFields ? commonFields.filter((data: any) => data.fieldTypeCode === "APLDON"): [];
  const tenantDetails: CustomerDetailsDTO =
    useSelector(getCustomerDetail);
  const dispatch = useDispatch();
  const [isCreateTaxGroup, setIsCreateTaxGroup] = useState<boolean>(true);
  const [isEditTaxGroup, setIsEditTaxGroup] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editTaxGroupData, setEditTaxGroupData] = useState<
    TaxGroupDTO | undefined
  >(undefined);
  const createTaxGroupSuccess = useSelector(getCreateTaxGroupSuccess);
  const editTaxGroupSuccess = useSelector(getEditTaxGroupSuccess);
  const [formData, setFormData] = useState<TaxGroupRequest>({
    id: CommonFields.defaultValue,
    tenantId: tenantDetails?.id || CommonFields.defaultValue,
    branchId: CommonFields.defaultValue,
    status:CommonFields.status.active,
    isVisible: true,
    isDeleted: false,
    createdBy: CommonFields.defaultValue,
    createdOn: new Date(),
    modifiedBy: CommonFields.defaultValue,
    modifiedOn: new Date(),
    taxGroupName: "",
    taxGroupDescription: "",
    taxTypeId: CommonFields.defaultValue,
    taxCodeId: CommonFields.defaultValue,
    taxMethodId: CommonFields.defaultValue,
    taxRate: CommonFields.defaultValue,
    appliedOn: CommonFields.defaultValue,
  });

  useEffect(() => {
    setIsCreateTaxGroup(props.isCreateTaxGroup);
    setIsLoading(props.loading);
    setIsEditTaxGroup(props.isEditTaxGroup);
    setEditTaxGroupData(props.editTaxGroupData);
    if (isCreateTaxGroup) {
      setFormData({
        id: CommonFields.defaultValue,
        tenantId: tenantDetails?.id || CommonFields.defaultValue,
        branchId: CommonFields.defaultValue,
        status: CommonFields.status.active,
        isVisible: true,
        isDeleted: false,
        createdBy: CommonFields.defaultValue,
        createdOn: new Date(),
        modifiedBy: CommonFields.defaultValue,
        modifiedOn: new Date(),
        taxGroupName: "",
        taxGroupDescription: "",
        taxTypeId: CommonFields.defaultValue,
        taxCodeId: CommonFields.defaultValue,
        taxMethodId: CommonFields.defaultValue,
        taxRate: CommonFields.defaultValue,
        appliedOn: CommonFields.defaultValue,
      }); 
    }
  }, [props, tenantDetails, isCreateTaxGroup]);

useEffect(()=>{
  if (editTaxGroupData) {
    setFormData({...formData,
      id: editTaxGroupData.id || CommonFields.defaultValue,
      tenantId: editTaxGroupData.tenantId||CommonFields.defaultValue,
      branchId: editTaxGroupData.branchId||CommonFields.defaultValue,
      status: editTaxGroupData.status||CommonFields.status.active,
      isVisible: editTaxGroupData.isVisible||true,
      isDeleted: editTaxGroupData.isDeleted||false,
      createdBy: editTaxGroupData.createdBy,
      createdOn: editTaxGroupData.createdOn ,
      modifiedBy: CommonFields.defaultValue,
      modifiedOn: new Date() ,
      taxGroupName:editTaxGroupData.taxGroupName||"",
      taxGroupDescription:editTaxGroupData.taxGroupDescription||"",
      taxTypeId: editTaxGroupData.taxTypeId||CommonFields.defaultValue,
      taxCodeId: editTaxGroupData.taxCodeId||CommonFields.defaultValue,
      taxMethodId: editTaxGroupData.taxMethodId||CommonFields.defaultValue,
      taxRate: editTaxGroupData.taxRate||CommonFields.defaultValue,
      appliedOn: editTaxGroupData.appliedOn||CommonFields.defaultValue,
  });

  }
},[editTaxGroupData])

  const setNewFormdata = (e: any) => {
    const { name, value } = e.target;
    const newValue = value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const submitForm = {
      id: formData.id,
      tenantId: tenantDetails?.id || CommonFields.defaultValue,
      branchId: formData.branchId,
      status: formData.status,
      isVisible: formData.isVisible,
      isDeleted: formData.isDeleted,
      createdBy: formData.createdBy,
      createdOn: formData.createdOn,
      modifiedBy: formData.modifiedBy,
      modifiedOn: formData.modifiedOn,
      taxGroupName: formData.taxGroupName,
      taxGroupDescription: formData.taxGroupDescription,
      taxTypeId: formData.taxTypeId,
      taxCodeId: formData.taxCodeId,
      taxMethodId: formData.taxMethodId,
      taxRate: formData.taxRate,
      appliedOn: formData.appliedOn,
    };
    if (isCreateTaxGroup) {
      dispatch(loadCreateTaxGroup(submitForm));
    } else {
      dispatch(loadEditTaxGroup(formData));
    }
  };

  useEffect(() => {
    if (createTaxGroupSuccess) {
      props.updateIsCreateTaxGroup(false);
      props.TaxGroupListSuccess(createTaxGroupSuccess);
      setTimeout(() => {
        dispatch(resetCreateTaxGroupSuccess());
      }, 1000);
    }
  }, [createTaxGroupSuccess, props, dispatch]);

  useEffect(() => {
    if (editTaxGroupSuccess) {
      props.updateIsEditTaxGroup(false);
      props.TaxGroupListSuccess(editTaxGroupSuccess);
      setTimeout(() => {
        dispatch(resetEditTaxGroupSuccess());
      }, 1000);
    }
  }, [editTaxGroupSuccess, props, dispatch]);

  return (
    <>
      <div
        className={`block bg-white h-[727px] shadow-md rounded-md border-1 px-8 py-2 ${
          isCreateTaxGroup || isEditTaxGroup ? "block" : "hidden"
        }`}
      >
        <div className="grid grid-cols-3 gap-y-1.5 px-2.5 mt-1 mb-2 py-2">
          <div className="col-start-1">
            <label
              htmlFor="taxGroupName"
              className="block text-xs font-medium text-gray-900"
            >
              Title:
              <span className="text-red-600 text-base">*</span>
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="taxGroupName"
                name="taxGroupName"
                value={formData.taxGroupName}
                onChange={setNewFormdata}
                className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-[320px]"
                placeholder="Enter Tax Group Name"
              />
            </div>
          </div>

          <div className="col-start-1">
            <label
              htmlFor="taxGroupDescription"
              className="block text-xs font-medium text-gray-900"
            >
              Description:
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="taxGroupDescription"
                name="taxGroupDescription"
                value={formData.taxGroupDescription}
                onChange={setNewFormdata}
                className="border border-gray-400 text-gray-900 text-sm rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-400 block py-1.5 px-2.5 
                active:ring-slate-200 w-[500px]"
                placeholder="Enter Tax Group Name"
              />
            </div>
          </div>

          <div className="col-start-1">
            <label
              htmlFor="taxTypeId"
              className="block text-xs font-medium leading-6 text-gray-900"
            >
              Type: <span className="text-red-600 text-base">*</span>
            </label>
            <div className="mt-1">
              <select
                id="taxTypeId"
                name="taxTypeId"
                value={formData.taxTypeId}
                onChange={setNewFormdata}
                className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[320px]"
              >
                <option value={CommonFields.defaultValue} disabled>
                  Choose type
                </option>
                {taxTypeList.map((data: CommonFieldsDTO, index: any) => (
                        <option key={index} value={data.id}>
                          {data.fieldValue}
                        </option>
                      ))}
              </select>
            </div>
          </div>

          <div className="col-start-1">
            <label
              htmlFor="taxMethodId"
              className="block text-xs font-medium leading-6 text-gray-900"
            >
              Taxing Method: <span className="text-red-600 text-base">*</span>
            </label>
            <div className="mt-1">
              <select
                id="taxMethodId"
                name="taxMethodId"
                value={formData.taxMethodId}
                onChange={setNewFormdata}
                className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[320px]"
              >
                <option disabled value={CommonFields.defaultValue}>
                  Choose Tax Method
                </option>
                {taxMethodList.map((data: CommonFieldsDTO, index: any) => (
                        <option key={index} value={data.id}>
                          {data.fieldValue}
                        </option>
                      ))}
              </select>
            </div>
          </div>

          <div className="col-start-1">
            <label
              htmlFor="taxRate"
              className="block text-xs font-medium text-gray-900"
            >
              Tax Percentage
              <span className="text-red-600 text-base">*</span>
            </label>
            <div className="flex mt-1">
              <div className="w-10 z-10 text-center pointer-events-none flex items-center justify-center border border-gray-400 rounded-s bg-gray-200">
                %
              </div>
              <input
                type="number"
                className="py-1.5 px-2.5 block rounded-e border-y border-r border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[280px]"
                placeholder="Tax Percent"
                id="taxRate"
                name="taxRate"
                value={formData.taxRate}
                onChange={setNewFormdata}
              />
            </div>
          </div>

          <div className="col-start-1">
            <label
              htmlFor="appliedOn"
              className="block text-xs font-medium text-gray-900"
            >
              Applied On
              <span className="text-red-600 text-base">*</span>
            </label>
            <div className="mt-1">
              <select
                id="appliedOn"
                name="appliedOn"
                value={formData.appliedOn}
                onChange={setNewFormdata}
                className="py-1.5 px-2.5 block rounded-sm border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[320px]"
              >
                <option value={CommonFields.defaultValue} disabled>
                  Choose type
                </option>
                
                {AppliedOnList.map((data: CommonFieldsDTO, index: any) => (
                        <option key={index} value={data.id}>
                          {data.fieldValue}
                        </option>
                      ))}
              </select>
            </div>
          </div>
{/* 
          <div className="col-start-1">
            <label
              htmlFor="applicableTo"
              className="block text-xs font-medium leading-6 text-gray-900"
            >
              Applicable To: <span className="text-red-600 text-base">*</span>
            </label>

            <div className="mt-1 block rounded-md border border-gray-400 text-sm text-gray-900 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-400 w-[320px]">
              <Select
                primaryColor={""}
                placeholder={"Applicable To"}
                value={animal}
                onChange={handleChange}
                options={AppliedOnList}
                isMultiple={true}
                isSearchable={true}
                isClearable={true}
              />
            </div>
          </div> */}
        </div>

        <button
          className="my-3 mx-2 bg-red-500 text-slate-50 px-4 py-1 w-72 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md"
          onClick={handleSubmit}
        >
          Proceed
        </button>
      </div>
    </>
  );
}

export default TaxGroupCU;
