import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable, { TableStyles } from "react-data-table-component";

import { getLoading } from "../../Application/Selectors/loader";
import { loadSalesRepList } from "../../Application/Actions/loader";
import { getSalesRepList } from "../../Application/Selectors/salesRep";
import { SalesRepDTO } from "../../Infrastructure/Core/DTOs/SalesRep/SalesRepDTO";
import ShimmerTable from "../Loader/Table";

function SalesRepManage() {
  const dispatch = useDispatch();
  
  const salesRepList = useSelector(getSalesRepList);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(loadSalesRepList);
  }, [dispatch]);

  const tableColsName = [
    "User Name",
    "Email",
    "Contact Number",
    "lastLoginTime",
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "35px",
      },
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row: SalesRepDTO) => row.userName,
      sortable: true,
      cell: (row: SalesRepDTO) => (
        <div className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
          {row.userName}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row: SalesRepDTO) => row.email,
      sortable: true,
      cell: (row: SalesRepDTO) => (
        <div className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
          {row.email}
        </div>
      ),
    },
    {
      name: "Contact Number",
      selector: (row: SalesRepDTO) => row.phoneNumber || "-",
      sortable: true,
      cell: (row: SalesRepDTO) => (
        <div className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
          {row.phoneNumber || "-"}
        </div>
      ),
    },
    {
      name: "Last Login Time",
      selector: (row: SalesRepDTO) =>
        new Date(row.lastLoginTime).toLocaleString(),
      sortable: true,
      cell: (row: SalesRepDTO) => (
        <div className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
          {new Date(row.lastLoginTime).toLocaleString()}
        </div>
      ),
    },
  ];
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const filteredData: SalesRepDTO[] = salesRepList.filter(
    (salesRep: SalesRepDTO) => {
      const formattedLastLoginTime = salesRep.lastLoginTime
        ? new Date(salesRep.lastLoginTime).toLocaleString()
        : "";
      return (
        salesRep.userName.toLowerCase().includes(searchText.toLowerCase()) ||
        salesRep.email.toLowerCase().includes(searchText.toLowerCase()) ||
        (salesRep.phoneNumber &&
          salesRep.phoneNumber
            .toLowerCase()
            .includes(searchText.toLowerCase())) || // Check for null or undefined phoneNumber
        formattedLastLoginTime.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  );

  return (
    <>
      <div className="flex items-center align-middle justify-between">
        <div>
          <h3 className="text-slate-700 font-semibold text-base md:text-xl py-2">
            Sales Representative Management
          </h3>
        </div>
      </div>
      <div className="flex justify-end my-3">
        <input
          type="text"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search..."
          className="border-[1px] border-slate-300 px-2 py-2 rounded text-sm shadow focus:outline-none focus:ring-1 md:w-2/5 lg:w-2/5 xl:w-1/5 2xl:w-1/5 ease-linear transition-all duration-150"
        />
      </div>
      <div className="overflow-x-auto shadow-lg rounded-lg">
        {loading && value === "getSalesRepList" ? (
          <ShimmerTable
            colNames={tableColsName}
            colsCount={tableColsName.length}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}
export default SalesRepManage;

// const navigate = useNavigate();
// const handleProceed = (e: string | undefined) => {
//   navigate(`/SalesRepDetails/${e}`);
// };