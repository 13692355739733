import * as loaderActions from "../Actions/loader";
const initialState = {
  loading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case loaderActions.SET_LOADING_ON:
    case loaderActions.SET_LOADING_OFF:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
