import React from "react";
import AppRouting from "./Routing/AppRouting.tsx";
import Layout from "./Views/Layout/Layout.tsx";

function App() {
  return (
    <>
        <Layout>
          <AppRouting />
        </Layout>
    </>
  );
}

export default App;
