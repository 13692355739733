// data
//list
export const CUSTOMER_LIST = "customer list load";
export const USER_LIST = "user list load";
export const BRANCH_LIST = "branch list load";
export const TAXGROUP_LIST = "tax group list load";
export const SUBSCRIPTIONPLAN_SETUP_LIST = "subscription plan setup list load";
export const UPLOADED_MENULIST = "menu list load";
//details
export const CUSTOMER_DETAIL_BY_ID = "customer detail load";
export const BRANCH_DETAILS = "branch details load";
export const USER_DETAILS = "user details load";
export const TAXGROUP_DETAILS = "tax group details load";
export const SUBSCRIPTIONPLAN_SETUP_DETAILS = "subscription plan setup details load";
//create
export const CREATE_BRANCH = "create branch load";
export const CREATE_USER = "create user load";
export const CREATE_TAXGROUP = "create tax group load";
export const CREATE_SUBSCRIPTIONPLAN_SETUP = "create subscription plan setup load";
//edit
export const EDIT_BRANCH = "edit branch load";
export const EDIT_USER = "edit user load";
export const EDIT_TAXGROUP = "edit tax group load";
export const EDIT_SUBSCRIPTIONPLAN_SETUP = "edit subscription plan setup load";
//action
export const CHECK_DOMAIN = "check domain load";
export const ACCOUNT_SETUP = "account setup load";
//upload
export const UPLOAD_MENU = "upload menu";

// success
//list
export const CUSTOMER_LIST_SUCCESS = "customer list loaded";
export const USER_LIST_SUCCESS = "user list loaded";
export const BRANCH_LIST_SUCCESS = "branch list loaded";
export const TAXGROUP_LIST_SUCCESS = "tax group list loaded";
export const SUBSCRIPTIONPLAN_SETUP_LIST_SUCCESS = "subscription plan setup list loaded";
export const UPLOADED_MENULIST_SUCCESS = "menu list loaded";
//details
export const CUSTOMER_DETAIL_BY_ID_SUCCESS = "customer detail loaded";
export const BRANCH_DETAILS_SUCCESS = "branch details loaded";
export const USER_DETAILS_SUCCESS = "user details loaded";
export const TAXGROUP_DETAILS_SUCCESS = "tax group details loaded";
export const SUBSCRIPTIONPLAN_SETUP_DETAILS_SUCCESS = "subscription plan setup details loaded";
//create
export const CREATE_BRANCH_SUCCESS = "branch create loaded";
export const CREATE_USER_SUCCESS = "user create loaded";
export const CREATE_TAXGROUP_SUCCESS = "tax group create loaded";
export const CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS = "subscription plan setup create loaded";
//edit
export const EDIT_BRANCH_SUCCESS = "branch edit loaded";
export const EDIT_USER_SUCCESS = "user edit loaded";
export const EDIT_TAXGROUP_SUCCESS = "tax group edit loaded";
export const EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS = "subscription plan setup edit loaded";
//action
export const CHECK_DOMAIN_SUCCESS = "check domain loaded";
export const ACCOUNT_SETUP_SUCCESS = "account setup loaded";
//upload
export const UPLOAD_MENU_SUCCESS = "upload menu loaded";

// failure
//list
export const CUSTOMER_LIST_FAILURE = "customer list failed";
export const USER_LIST_FAILURE = "user list failed";
export const BRANCH_LIST_FAILURE = "branch list failed";
export const TAXGROUP_LIST_FAILURE = "tax group list failed";
export const SUBSCRIPTIONPLAN_SETUP_LIST_FAILURE = "subscription plan setup list failed";
export const UPLOADED_MENULIST_FAILURE = "menu list failed";
//details
export const CUSTOMER_DETAIL_BY_ID_FAILURE = "customer detail failed";
export const BRANCH_DETAILS_FAILURE = "branch details failed";
export const USER_DETAILS_FAILURE = "user details failed";
export const TAXGROUP_DETAILS_FAILURE = "tax group details failed";
export const SUBSCRIPTIONPLAN_SETUP_DETAILS_FAILURE = "subscription plan setup details failed";
//create
export const CREATE_BRANCH_FAILURE = "branch create failed";
export const CREATE_USER_FAILURE = "user create failed";
export const CREATE_TAXGROUP_FAILURE = "tax group create failed";
export const CREATE_SUBSCRIPTIONPLAN_SETUP_FAILURE = "subscription plan setup create failed";
//edit
export const EDIT_BRANCH_FAILURE = "branch edit failed";
export const EDIT_USER_FAILURE = "user edit failed";
export const EDIT_TAXGROUP_FAILURE = "tax group edit failed";
export const EDIT_SUBSCRIPTIONPLAN_SETUP_FAILURE = "subscription plan setup edit failed";
//action
export const CHECK_DOMAIN_FAILURE = "customer branch failed";
export const ACCOUNT_SETUP_FAILURE = "account setup failed";
//upload
export const UPLOAD_MENU_FAILURE = "upload menu failed";

//reset
export const RESET_CREATE_BRANCH_SUCCESS = "RESET_CREATE_BRANCH_SUCCESS";
export const RESET_EDIT_BRANCH_SUCCESS = "RESET_EDIT_BRANCH_SUCCESS";
export const RESET_CREATE_USER_SUCCESS = "RESET_CREATE_USER_SUCCESS";
export const RESET_EDIT_USER_SUCCESS = "RESET_EDIT_USER_SUCCESS";
export const RESET_CREATE_TAXGROUP_SUCCESS = "RESET_CREATE_TAXGROUP_SUCCESS";
export const RESET_EDIT_TAXGROUP_SUCCESS = "RESET_EDIT_TAXGROUP_SUCCESS";
export const RESET_CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS = "RESET_CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS";
export const RESET_EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS = "RESET_EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS";

// get customer list
export const customerList = {
  type: CUSTOMER_LIST,
};
export const customerListSuccess = (data: any) => ({
  type: CUSTOMER_LIST_SUCCESS,
  payload: data,
});
export const customerListFailure = (error: any) => ({
  type: CUSTOMER_LIST_FAILURE,
  payload: error,
});

// get customer details
export const customerDetailsById = (id: any) => ({
  type: CUSTOMER_DETAIL_BY_ID,
  payload: id,
});
export const customeryDetailSuccess = (data: any) => ({
  type: CUSTOMER_DETAIL_BY_ID_SUCCESS,
  payload: data,
});
export const customerDetailFailure = (error: any) => ({
  type: CUSTOMER_DETAIL_BY_ID_FAILURE,
  payload: error,
});

//check-domain
export const checkDomain = (data: any) => ({
  type: CHECK_DOMAIN,
  payload: data,
});
export const checkDomainSuccess = (data: any) => ({
  type: CHECK_DOMAIN_SUCCESS,
  payload: data,
});
export const checkDomainFailure = (error: any) => ({
  type: CHECK_DOMAIN_FAILURE,
  payload: error,
});

//account setup
export const accountSetup = (data: any) => ({
  type: ACCOUNT_SETUP,
  payload: data,
});
export const accountSetupSuccess = (data: any) => ({
  type: ACCOUNT_SETUP_SUCCESS,
  payload: data,
});
export const accountSetupFailure = (error: any) => ({
  type: ACCOUNT_SETUP_FAILURE,
  payload: error,
});

// create branch
export const createBranch = (data: any) => ({
  type: CREATE_BRANCH,
  payload: data,
});
export const createBranchSuccess = (data: any) => ({
  type: CREATE_BRANCH_SUCCESS,
  payload: data,
});
export const createBranchFailure = (error: any) => ({
  type: CREATE_BRANCH_FAILURE,
  payload: error,
});

// edit branch
export const editBranch = (data: any) => ({
  type: EDIT_BRANCH,
  payload: data,
});
export const editBranchSuccess = (data: any) => ({
  type: EDIT_BRANCH_SUCCESS,
  payload: data,
});
export const editBranchFailure = (error: any) => ({
  type: EDIT_BRANCH_FAILURE,
  payload: error,
});

// branch list
export const branchList = (id: any) => ({
  type: BRANCH_LIST,
  payload: id,
});
export const branchListSuccess = (data: any) => ({
  type: BRANCH_LIST_SUCCESS,
  payload: data,
});
export const branchListFailure = (error: any) => ({
  type: BRANCH_LIST_FAILURE,
  payload: error,
});

// branch details
export const branchDetails = (id: any) => ({
  type: BRANCH_DETAILS,
  payload: id,
});
export const branchDetailsSuccess = (data: any) => ({
  type: BRANCH_DETAILS_SUCCESS,
  payload: data,
});
export const branchDetailsFailure = (error: any) => ({
  type: BRANCH_DETAILS_FAILURE,
  payload: error,
});

// user list
export const userList = (data:any)=>({
  type: USER_LIST,
  payload:data
});
export const userListSuccess = (data: any) => ({
  type: USER_LIST_SUCCESS,
  payload: data,
});
export const userListFailure = (error: any) => ({
  type: USER_LIST_FAILURE,
  payload: error,
});

// create user
export const createUser = (data: any) => ({
  type: CREATE_USER,
  payload: data,
});
export const createUserSuccess = (data: any) => ({
  type: CREATE_USER_SUCCESS,
  payload: data,
});
export const createUserFailure = (error: any) => ({
  type: CREATE_USER_FAILURE,
  payload: error,
});

// edit user
export const editUser = (data: any) => ({
  type: EDIT_USER,
  payload: data,
});
export const editUserSuccess = (data: any) => ({
  type: EDIT_USER_SUCCESS,
  payload: data,
});
export const editUserFailure = (error: any) => ({
  type: EDIT_USER_FAILURE,
  payload: error,
});

// user details
export const userDetails = (id: any) => ({
  type: USER_DETAILS,
  payload: id,
});
export const userDetailsSuccess = (data: any) => ({
  type: USER_DETAILS_SUCCESS,
  payload: data,
});
export const userDetailsFailure = (error: any) => ({
  type: USER_DETAILS_FAILURE,
  payload: error,
});

// tax group list
export const taxGroupList =(data:any)=>({
  type: TAXGROUP_LIST,
  payload:data
});
export const taxGroupListSuccess = (data: any) => ({
  type: TAXGROUP_LIST_SUCCESS,
  payload: data,
});
export const taxGroupListFailure = (error: any) => ({
  type: TAXGROUP_LIST_FAILURE,
  payload: error,
});

// create tax group
export const createTaxGroup = (data: any) => ({
  type: CREATE_TAXGROUP,
  payload: data,
});
export const createTaxGroupSuccess = (data: any) => ({
  type: CREATE_TAXGROUP_SUCCESS,
  payload: data,
});
export const createTaxGroupFailure = (error: any) => ({
  type: CREATE_TAXGROUP_FAILURE,
  payload: error,
});

// edit tax group
export const editTaxGroup = (data: any) => ({
  type: EDIT_TAXGROUP,
  payload: data,
});
export const editTaxGroupSuccess = (data: any) => ({
  type: EDIT_TAXGROUP_SUCCESS,
  payload: data,
});
export const editTaxGroupFailure = (error: any) => ({
  type: EDIT_TAXGROUP_FAILURE,
  payload: error,
});

// tax group details
export const taxGroupDetails = (id: any) => ({
  type: TAXGROUP_DETAILS,
  payload: id,
});
export const taxGroupDetailsSuccess = (data: any) => ({
  type: TAXGROUP_DETAILS_SUCCESS,
  payload: data,
});
export const taxGroupDetailsFailure = (error: any) => ({
  type: TAXGROUP_DETAILS_FAILURE,
  payload: error,
});

// subscription plan setup list
export const subscriptionPlanSetupList =(id:any)=> ({
  type: SUBSCRIPTIONPLAN_SETUP_LIST,
  payload:id
});
export const subscriptionPlanSetupListSuccess = (data: any) => ({
  type: SUBSCRIPTIONPLAN_SETUP_LIST_SUCCESS,
  payload: data,
});
export const subscriptionPlanSetupFailure = (error: any) => ({
  type: SUBSCRIPTIONPLAN_SETUP_LIST_FAILURE,
  payload: error,
});
// create subscription plan setup
export const createSubscriptionPlanSetup = (data: any) => ({
  type: CREATE_SUBSCRIPTIONPLAN_SETUP,
  payload: data,
});
export const createSubscriptionPlanSetupSuccess = (data: any) => ({
  type: CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  payload: data,
});
export const createSubscriptionPlanSetupFailure = (error: any) => ({
  type: CREATE_SUBSCRIPTIONPLAN_SETUP_FAILURE,
  payload: error,
});

// edit subscription plan setup
export const editSubscriptionPlanSetup = (data: any) => ({
  type: EDIT_SUBSCRIPTIONPLAN_SETUP,
  payload: data,
});
export const editSubscriptionPlanSetupSuccess = (data: any) => ({
  type: EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  payload: data,
});
export const editSubscriptionPlanSetupFailure = (error: any) => ({
  type: EDIT_SUBSCRIPTIONPLAN_SETUP_FAILURE,
  payload: error,
});

// subscription plan setup details
export const subscriptionPlanSetupDetails = (id: any) => ({
  type: SUBSCRIPTIONPLAN_SETUP_DETAILS,
  payload: id,
});
export const subscriptionPlanSetupDetailsSuccess = (data: any) => ({
  type: SUBSCRIPTIONPLAN_SETUP_DETAILS_SUCCESS,
  payload: data,
});
export const subscriptionPlanSetupDetailsFailure = (error: any) => ({
  type: SUBSCRIPTIONPLAN_SETUP_DETAILS_FAILURE,
  payload: error,
});

//reset state of branch create and update response
export const resetCreateBranchSuccess = () => ({
  type: RESET_CREATE_BRANCH_SUCCESS,
});
export const resetEditBranchSuccess = () => ({
  type: RESET_EDIT_BRANCH_SUCCESS,
});
//reset state of user create and update response
export const resetCreateUserSuccess = () => ({
  type: RESET_CREATE_USER_SUCCESS,
});
export const resetEditUserSuccess = () => ({
  type: RESET_EDIT_USER_SUCCESS,
});
//reset state of tax group create and update response
export const resetCreateTaxGroupSuccess = () => ({
  type: RESET_CREATE_TAXGROUP_SUCCESS,
});
export const resetEditTaxGroupSuccess = () => ({
  type: RESET_EDIT_TAXGROUP_SUCCESS,
});
//reset state of subscription plan setup create and update response
export const resetCreateSubscriptionPlanSetupSuccess = () => ({
  type: RESET_CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
});
export const resetEditSubscriptionPlanSetupSuccess = () => ({
  type: RESET_EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
});

//upload menu
export const uploadMenu = (data: any) => ({
  type: UPLOAD_MENU,
  payload: data,
});
export const uploadMenuSuccess = (data: any) => ({
  type: UPLOAD_MENU_SUCCESS,
  payload: data,
});
export const uploadMenuFailure = (error: any) => ({
  type: UPLOAD_MENU_FAILURE,
  payload: error,
});
//uploaded menu list
export const loadMenuList = (id: any) => ({
  type: UPLOADED_MENULIST,
  payload: id,
});
