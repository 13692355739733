import {
  CREATE_PLAN_SUCCESS,
  EDIT_PLAN_SUCCESS,
  PLAN_DETAILS_SUCCESS,
  PLAN_LIST_SUCCESS,
} from "../Actions/plan";

const initialState = {
  planList: [],
  planDetail: {},
  error: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PLAN_LIST_SUCCESS:
      return { ...state, planList: action.payload.result, error: null };
    case CREATE_PLAN_SUCCESS:
      return { ...state, planList: action.payload.result, error: null };
    case PLAN_DETAILS_SUCCESS:
      return { ...state, planDetail: action.payload.result, error: null };
    case EDIT_PLAN_SUCCESS:
      return { ...state, planList: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;
