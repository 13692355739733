import { useEffect, useState } from "react";
import { loadBranchList } from "../../../../Application/Actions/loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllBranchList } from "../../../../Application/Selectors/customer";
import { BranchDTO } from "../../../../Infrastructure/Core/DTOs/Customer/Branch/BranchDTO";
import { getLoading } from "../../../../Application/Selectors/loader";
import FormField from "../../../Loader/FormField";
import { useParams } from "react-router-dom";
import BranchCU from "./BranchSetup/BranchCU";
import CommonFields from "../../../../Infrastructure/Core/Helper/CommonFields";
function BranchSetup(props: any) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const branchListData = useSelector(getAllBranchList);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  const [isCreateBranch, setIsCreateBranch] = useState(false);
  const [isEditBranch, setIsEditBranch] = useState(false);
  const [branchList, setBranchList] = useState<BranchDTO[] | undefined>(
    undefined
  );
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);

  useEffect(() => {
    if(id){
      dispatch(loadBranchList(id));
    }
  }, [id]);

  useEffect(() => {
    setIsCreateBranch(props.isManage);
    setIsEditBranch(props.isManage);
  }, [props]);

  useEffect(() => {
    setIsCreateBranch(branchListData?.length === 0);
    setIsEditBranch(false);
    setBranchList(branchListData);
  }, [branchListData]);

  const updateIsCreateBranch = (value: boolean) => {
    setIsCreateBranch(value);
  };

  const updateIsEditBranch = (value: boolean) => {
    setIsEditBranch(value);
  };

  const setBranchListSuccess = (data: BranchDTO[]) => {
    setBranchList(data);
  };

  const editBranchForm = (id: any) => {
    setSelectedBranchId(id);
    setIsEditBranch(true);
    setIsCreateBranch(false);
  };

  return (
    <>
      <div className="my-6 md:my-2">
        <div className="flex justify-between items-center align-middle mb-3">
          <div>
            <h1 className="font-medium text-sm md:text-sm lg:text-base xl:text-base 2xl:text-lg">
              Branch / Location Setup
            </h1>
          </div>
          <div>
            <button
              className={`bg-red-500 text-slate-50 py-1 text-xs md:text-sm lg:text-base xl:text-base 2xl:text-base w-32 md:w-36 lg:w-56 rounded-sm hover:bg-red-400 active:bg-red-600 shadow-md ${
                isCreateBranch || isEditBranch ? "hidden" : "block"
              }`}
              onClick={() => setIsCreateBranch(true)}
            >
              Add New Location
            </button>
          </div>
        </div>

        <div
          className={`bg-white h-fit md:h-[680px] lg:h-[695px] xl:h-[655px] 2xl:h-[715px] shadow-md rounded-md p-2 2xl:p-4 ${
            isCreateBranch || isEditBranch ? "hidden" : "block"
          }`}
        >
          <div className="overflow-y-auto h-[630px] md:h-[610px] p-2">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
              {loading && value === "branchList" ? (
                <FormField
                  colCount={4}
                  fieldCount={5}
                  marginY={4}
                  marginX={4}
                />
              ) : (
                <>
                  {branchList && branchList.length !== 0 ? (
                    <>
                      {branchList.map((branch: BranchDTO, index: any) => (
                        <div
                          key={index}
                          className="bg-white w-full md:w-full lg:w-full xl:w-full 2xl:w-full px-3 md:px-3 lg:px-3 py-3 rounded-md shadow-sm shadow-slate-400 border-t grid grid-cols-3 gap-2 md:gap-1"
                        >
                          <div className="col-span-1 me-2">
                            <span
                              className={`${
                                branch.status === CommonFields.status.active
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              } text-slate-50 px-5 py-0.5 uppercase text-xs rounded-full font-medium`}
                            >
                              {branch.status === CommonFields.status.active
                                ? "Active"
                                : "Inactive"}
                            </span>
                          </div>
                          <div className="col-span-1 ms-2">
                            <span
                              className={`text-slate-50 px-5 py-0.5 uppercase text-xs rounded-full font-medium ${
                                branch?.planDetails?.paymentStatus ===
                                CommonFields.payStatus.pending
                                  ? "bg-red-500"
                                  : "bg-green-500"
                              }`}
                            >
                              {branch?.planDetails?.paymentStatus ===
                              CommonFields.payStatus.pending
                                ? "Unpaid"
                                : "Paid"}
                            </span>
                          </div>
                          <div
                            className="col-span-1 flex items-center justify-end p-0.5 cursor-pointer"
                            onClick={() => editBranchForm(branch.id)}
                          >
                            <i className="fa-solid fa-pen-to-square text-lg"></i>
                          </div>

                          <div className="px-2 col-span-3">
                            <div>
                              <h3 className="font-bold text-sm md:text-base">
                                {branch.branchName}
                              </h3>
                              <h6 className="text-xs md:text-xs lg:text-xs font-semibold">
                                BIC: <span>{branch.shortCode}</span>
                              </h6>
                              <h6 className="text-xs md:text-xs lg:text-xs font-semibold">
                                Activation:{" "}
                                <span>
                                  {new Date(branch.createdOn).toLocaleString()}
                                </span>
                              </h6>
                            </div>

                            <div className="flex mt-2">
                              <div className="me-10">
                                <span className="text-xs">Plan Name</span>
                                <h6 className="text-xs md:text-sm font-semibold">
                                  {branch?.planDetails?.planName
                                    ? branch?.planDetails?.planName
                                    : "-"}
                                </h6>
                              </div>
                              <div>
                                <span className="text-xs">Plan Expires On</span>
                                <h6 className="text-xs md:text-sm font-semibold">
                                  {branch?.planDetails?.planExpiry
                                    ? new Date(
                                        branch?.planDetails?.planExpiry
                                      ).toLocaleString()
                                    : "-"}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    "no branch available"
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <BranchCU
        isCreateBranch={isCreateBranch}
        isEditBranch={isEditBranch}
        updateIsCreateBranch={updateIsCreateBranch}
        updateIsEditBranch={updateIsEditBranch}
        branchListSuccess={setBranchListSuccess}
        editBranchData={branchList?.find(
          (branch: any) => branch.id === selectedBranchId
        )}
      />
    </>
  );
}

export default BranchSetup;
