import React from "react";

function TimeLine() {
  const numberOfItems = 3;

  return (
    <>
      <ol className="relative border-s border-gray-400">
        {[...Array(numberOfItems)].map((_, index) => (
          <li key={index} className="m-4 animate-pulse">
            <div className="absolute w-3 h-3 bg-gray-400 rounded-full my-1.5 -start-1.5"></div>
            <div className="h-4 w-80 bg-gray-200 rounded my-1"></div>
          </li>
        ))}
      </ol>
    </>
  );
}

export default TimeLine;
