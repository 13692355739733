import { SALES_REP_LIST_SUCCESS } from "../Actions/salesRep";

const initialState = {
  salesRepList: [],
  error: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SALES_REP_LIST_SUCCESS:
      return { ...state, salesRepList: action.payload, error: null };
    default:
      return state;
  }
};

export default reducer;
