import { useContext } from "react";
import AuthContext from "./AuthContext";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, accessBy }: any) => {
  const { user } = useContext(AuthContext) || {};
  if (accessBy === "non-authenticated") {
    if (!user) {
      return children;
    }
  } else if (accessBy === "authenticated") {
    if (user) {
      return children;
    }
  } else {
    return <Navigate to="/"></Navigate>;
  }
};

export default ProtectedRoute;
