import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AccountSetup from "./Setup/AccountSetup";
import BranchSetup from "./Setup/BranchSetup";
import MenuSetup from "./Setup/MenuSetup";
import ItemSetup from "./Setup/ItemSetup";
import Taxation from "./Setup/TaxGroupSetup";
import { CustomerDetailsDTO } from "../../../Infrastructure/Core/DTOs/Customer/CustomerDetailsDTO";
import { getLoading } from "../../../Application/Selectors/loader";
import FormField from "../../Loader/FormField";
import { loadCustomerDetailsById } from "../../../Application/Actions/loader";
import { getCustomerDetail } from "../../../Application/Selectors/customer";
import UserAccountSetup from "./Setup/UserAccountSetup";
import SubscriptionPlanSetup from "./Setup/SubscriptionPlanSetup";
// import BrandingSetup from "./Setup/BrandingSetup";

function CustomerDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const customerDetailsById: CustomerDetailsDTO =
    useSelector(getCustomerDetail);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;
  useEffect(() => {
    if (id) {
      dispatch(loadCustomerDetailsById(id));
    }
  }, [id, dispatch]);

  const tabData = [
    {
      title: "Account Setup",
      content: <AccountSetup />,
      completionStatus: customerDetailsById?.isAccountSetup,
      clickDisabled: true,
    },
    {
      title: "Branch Setup",
      content: (
        <BranchSetup {...{ isManage: false }} />
      ),
      completionStatus: customerDetailsById?.isBranchSetup,
      clickDisabled: customerDetailsById?.isAccountSetup,
    },
    {
      title: "User Account Creation",
      content: (
        <UserAccountSetup
          {...{ isManage: false }}
        />
      ),
      completionStatus: customerDetailsById?.isUserCreated,
      clickDisabled: customerDetailsById?.isBranchSetup,
    },
    {
      title: "Subscription Plan",
      content: (
        <SubscriptionPlanSetup
          {...{ isManage: false }}
        />
      ),
      completionStatus: customerDetailsById?.isSubscriptionSetup,
      clickDisabled: customerDetailsById?.isUserCreated,
    },
    {
      title: "Taxation",
      content: (
        <Taxation {...{ isManage: false }} />
      ),
      completionStatus: customerDetailsById?.isTaxSetup,
      clickDisabled: customerDetailsById?.isSubscriptionSetup,
    },
    {
      title: "Menu Setup",
      content: <MenuSetup {...{ customerDetailsById, loading }} />,
      completionStatus: customerDetailsById?.isMenuSetup,
      clickDisabled: customerDetailsById?.isTaxSetup,
    },
    {
      title: "Item Catalogue",
      content: <ItemSetup />,
      completionStatus: customerDetailsById?.isCatalogueSetup,
      clickDisabled: customerDetailsById?.isMenuSetup,
    },
    // {
    //   title: "Branding",
    //   content: <BrandingSetup    />,
    //   completionStatus: customerDetailsById?.isBrandSetup,
    //   clickDisabled: customerDetailsById?.isTaxSetup,
    // },
    // {
    //   title: "Bill Setup",
    //   content: "Bill Setup",
    //   completionStatus: customerDetailsById?.isBillingSetup,
    //   clickDisabled: customerDetailsById?.isBrandSetup,
    // },
  ];

  const handleTabClick = (index: number) => {
    setActiveTab(-1);
    setTimeout(() => {
      setActiveTab(index);
    }, 0);
  };

  const totalSteps = tabData.length;
  const completedSteps = tabData.filter(
    (step) => step.completionStatus === true
  ).length;
  let progressWidth = (completedSteps / totalSteps) * 100;
  progressWidth = Math.min(progressWidth, 100 * (8 / totalSteps));
  progressWidth = Math.round(progressWidth);
  const progressBarStyle = {
    width: `${progressWidth}%`,
  };

  return (
    <>
      <h3 className="text-slate-700 font-semibold text-base md:text-lg lg:text-lg xl:text-xl 2xl:text-xl mt-16">
        Customer Details
      </h3>
      <div className="md:flex lg:flex xl:flex 2xl:flex">
        <div className="bg-white h-fit md:[725px] lg:h-[740px] xl:h-[700px] 2xl:h-[760px]  md:basis-6/12 lg:basis-4/12 xl:basis-4/12 2xl:basis-3/12 shadow-md rounded-md my-2">
          <div className="mx-3 md:mx-6 my-1 md:my-2 p-2">
            <h1 className="font-bold text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">
              Customer Onboard Status:
            </h1>
            {loading && value === "getCustomerDetailsById" ? (
              <FormField colCount={1} fieldCount={9} marginY={4} marginX={1} />
            ) : (
              <>
                <div className="p-1 my-2">
                  <ul className="">
                    {tabData.map((tab, index) => (
                      <li
                        key={index}
                        className={`px-3 py-2 my-1 rounded-sm cursor-pointer ${
                          activeTab === index
                            ? "bg-slate-200"
                            : "hover:bg-slate-100"
                        } ${
                          tab.clickDisabled === false
                            ? "pointer-events-none"
                            : ""
                        } ${
                          tab.clickDisabled === false ? "text-slate-400" : ""
                        }`}
                        onClick={() => handleTabClick(index)}
                      >
                        <div className="flex justify-between items-center">
                          <span className="font-medium text-xs md:text-sm lg:text-sm xl:text-sm 2xl:text-base">
                            {tab.title}
                          </span>
                          <div
                            className={`w-4 h-4 md:w-5 md:h-5 rounded-full ${
                              tab.completionStatus === true
                                ? "bg-green-500 flex items-center justify-center"
                                : "bg-slate-100"
                            }`}
                          >
                            <span
                              className={`text-white text-xs font-bold ${
                                tab.completionStatus === true
                                  ? "block"
                                  : "hidden"
                              }`}
                            >
                              ✓
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="my-3 md:my-5">
                  <div className="mx-4 bg-gray-200">
                    <div
                      className="h-2 bg-green-600"
                      style={progressBarStyle}
                    ></div>
                  </div>
                  <div className="flex justify-between items-center mx-4">
                    <h2 className="text-xs md:text-sm font-medium p-1">
                      {progressBarStyle.width} complete
                    </h2>
                    <h2 className="text-xs md:text-sm p-1">Progress</h2>
                  </div>
                </div>
              </>
            )}
            <hr />

            <div className="px-4 py-2">
              <h2 className="font-bold my-2 text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">
                Customer Details
              </h2>
              <div className="grid grid-cols-2 gap-2">
                {loading && value === "getCustomerDetailsById" ? (
                  <FormField
                    fieldCount={4}
                    colCount={1}
                    marginX={5}
                    marginY={3}
                  />
                ) : (
                  <>
                    <div className="flex flex-col">
                      <div>
                        <span className="text-xs">Customer</span>
                      </div>
                      <div>
                        <span className="font-medium text-sm 2xl:text-base">
                          {/* {customerDetailsById?.id} */}
                          {customerDetailsById?.name}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>
                        <span className="text-xs">Date Registered</span>
                      </div>
                      <div>
                        <span className="font-medium text-sm 2xl:text-base">
                          {new Date(
                            customerDetailsById?.createdOn
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>
                        <span className="text-xs">Account Status</span>
                      </div>
                      <div>
                        <span
                          className={`font-medium text-xs md:text-sm text-slate-50 px-2 rounded-full ${
                            progressWidth === 100
                              ? "bg-green-500"
                              : "bg-orange-400"
                          }`}
                        >
                          {progressWidth === 100 ? "Complete" : "Incomplete"}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>
                        <span className="text-xs">Sales Rep Name</span>
                      </div>
                      <div>
                        <span className="font-medium text-sm 2xl:text-base">
                          {customerDetailsById.salesRepName}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-fit md:h-[510px] md:basis-6/12 lg:basis-8/12 xl:basis-8/12 2xl:basis-9/12 md:ms-5 rounded-sm">
          {tabData.map((tab, index) => (
            <div
              className={`tab-content ${
                activeTab === index ? "block" : "hidden"
              }`}
              key={index}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CustomerDetails;
