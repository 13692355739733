import { COMMONFIELD_LIST, commonFieldsListSuccess } from "../Actions/common";
import * as loaderActions from "../Actions/loader";
const getCommonFieldsList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === COMMONFIELD_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "getCommonFieldsList",
          })
        );
        const commonFieldList = await api.Common.getCommonFieldsList();
        dispatch(commonFieldsListSuccess(commonFieldList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getCommonFieldsList",
          })
        );
        dispatch(commonFieldsListSuccess(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getCommonFieldsList",
          })
        );
      }
    }
    next(action);
  };
export default [getCommonFieldsList];
