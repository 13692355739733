import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import DataTable, { TableStyles } from "react-data-table-component";
import { enquiryListDTO } from "../../Infrastructure/Core/DTOs/Enquiry/EnquiryListDTO";
import CommonFields from "../../Infrastructure/Core/Helper/CommonFields";
import { useDispatch, useSelector } from "react-redux";
import { getAllEnquiryList } from "../../Application/Selectors/enquiry";
import { loadEnquiryList } from "../../Application/Actions/loader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
interface CircularProgressWithLabelProps {
  value: number;
  text: string;
  color: string;
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  text,
  color,
}) => {
  return (
    <div style={{ width: "140px", margin: "10px" }}>
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        styles={buildStyles({
          textSize: "16px",
          pathColor: color,
          textColor: color,
          trailColor: "#d6d6d6",
        })}
      />
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <label className="text-sm leading-3">{text}</label>
      </div>
    </div>
  );
};
function Dashboard() {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const enquiryList: enquiryListDTO[] = useSelector(getAllEnquiryList);
  let commonFields: Array<any> | null = JSON.parse(
    localStorage.getItem("commonField") || "null"
  );
  useEffect(() => {
    dispatch(loadEnquiryList);
  }, [dispatch]);
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%", // Use 100% here and control the container div's width
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "35px",
      },
    },
    pagination: {
      style: {
        width: "100%", // Use 100% here and control the container div's width
        justifyContent: "center",
      },
    },
  };
  localStorage.setItem("activeIndex", "0");
  // doughnut
  const doughnutData = {
    labels: ["Starter", "Pro", "Elite"],
    datasets: [
      {
        data: [30, 50, 20],
        backgroundColor: ["#f97316", "#3b82f6", "#22c55e"],
        hoverBackgroundColor: ["#f5873a", "#5e99f9", "#38e177"],
      },
    ],
  };
  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "50%",
  };
  //barchart
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const barChartLabels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];
  const barChartData = {
    labels: barChartLabels,
    datasets: [
      {
        label: "Leads",
        data: [400, 300, 600, 200, 500, 700], // Hardcoded leads data
        backgroundColor: "#f5873a",
      },
      {
        label: "Customers",
        data: [200, 100, 400, 300, 600, 500], // Hardcoded customers data
        backgroundColor: "#3b82f6",
      },
    ],
  };
  //pie
  const pieChartdata = {
    labels: [
      "Total Leads",
      "Unassigned",
      "Ongoing",
      "Sales Closed",
      "Sales Missed",
    ],
    datasets: [
      {
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          "#85b6ff",
          "#506fd9",
          "#1c96e9",
          "#0dcaf0",
          "#1e294f",
        ],
        borderWidth: 1,
      },
    ],
  };
  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: "black",
        },
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row: enquiryListDTO) => row.name,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-5 py-4 font-medium text-gray-900 truncate cursor-pointer">
          {row.name}
        </div>
      ),
    },
    {
      name: "Brand Name",
      selector: (row: enquiryListDTO) => row.brandName,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-5 py-4 truncate">{row.brandName}</div>
      ),
    },
    {
      name: "Email",
      selector: (row: enquiryListDTO) => row.email,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-5 py-4 truncate">{row.email}</div>
      ),
    },
    {
      name: "Contact Number",
      selector: (row: enquiryListDTO) => row.phone,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-6 py-4 truncate">{row.phone}</div>
      ),
    },
    {
      name: "Country",
      selector: (row: enquiryListDTO) => row.country,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <div className="px-6 py-4 truncate">{row.country}</div>
      ),
    },
    {
      name: "Status",
      selector: (row: enquiryListDTO) => row.saleStatus,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <span
          className={`text-center block w-[150px] truncate px-2 py-1 md:px-6 md:py-1 rounded-full font-medium ${
            row.saleStatus === CommonFields.saleStatus.salesDone
              ? "px-12 text-green-600 bg-green-100"
              : row.saleStatus === CommonFields.saleStatus.noSales
              ? "px-12 text-red-600 bg-red-100"
              : row.saleStatus === CommonFields.saleStatus.ongoingSales
              ? "px-6 text-blue-600 bg-blue-100"
              : "px-12 text-orange-600 bg-orange-100"
          }`}
        >
          {commonFields?.find((cmf) => cmf.id === row.saleStatus)?.fieldValue}
        </span>
      ),
    },
    {
      name: "Sales Rep",
      selector: (row: enquiryListDTO) => row.salesRepName,
      sortable: true,
      cell: (row: enquiryListDTO) => (
        <span
          className={`text-center block w-[150px] truncate px-6 py-1 rounded-full font-medium ${
            row.salesRepresentative === ""
              ? "text-orange-600 bg-orange-100"
              : "text-slate-600 bg-gray-200"
          }`}
        >
          {row.salesRepresentative === "" ? "Unassigned" : row.salesRepName}
        </span>
      ),
    },
  ];
  const filteredData = enquiryList.filter((item) => {
    const statusValue = commonFields?.find(
      (cmf) => cmf.id === item.saleStatus
    )?.fieldValue;
    const salesRepValue = item.salesRepName || "Unassigned";
    return (
      item.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item.brandName.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase()) ||
      item.phone.toLowerCase().includes(searchText.toLowerCase()) ||
      item.country.toLowerCase().includes(searchText.toLowerCase()) ||
      (statusValue &&
        statusValue.toLowerCase().includes(searchText.toLowerCase())) ||
      (salesRepValue &&
        salesRepValue.toLowerCase().includes(searchText.toLowerCase()))
    );
  });
  return (
    <>
      <div className="text-slate-700 font-medium text-lg mx-2 overflow-auto">
        <div className="2xl:grid 2xl:grid-cols-2 gap-4 my-2">
          <div className="bg-white p-2 shadow-md rounded-sm 2xl:my-0 my-5">
            <h3 className="font-medium px-2">Leads Summary</h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3 p-2">
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">10 / 100</h2>
                  <label className="text-sm leading-3">
                    Today's Total Leads
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500 / 700</h2>
                  <label className="text-sm leading-3">
                    Weekly Total Leads
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500 / 900</h2>
                  <label className="text-sm leading-3">
                    Monthly Total Leads
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">8 / 100</h2>
                  <label className="text-sm leading-3">
                    Yearly Total Leads
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-2 shadow-md rounded-sm 2xl:my-0 my-5">
            <h3 className="font-medium px-2">New Activated Users</h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3 p-2">
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">10</h2>
                  <label className="text-xs leading-3">
                    Today's Activated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500</h2>
                  <label className="text-xs leading-3">
                    Weekly Activated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500</h2>
                  <label className="text-xs leading-3">
                    Monthly Activated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">8</h2>
                  <label className="text-xs leading-3">
                    Yearly Activated Users
                  </label>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="bg-white p-2 shadow-md rounded-sm 2xl:my-0 my-5">
            <h3 className="font-medium px-2">Renew</h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3 p-2">
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">10</h2>
                  <label className="text-sm leading-3">Today's Renewals</label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500</h2>
                  <label className="text-sm leading-3">Weekly Renewals</label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500</h2>
                  <label className="text-sm leading-3">Monthly Renewals</label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">8</h2>
                  <label className="text-sm leading-3">Yearly Renewals</label>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="bg-white p-2 shadow-md rounded-sm 2xl:my-0 my-5">
            <h3 className="font-medium px-2">Deactivated / Expired</h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 gap-3 p-2">
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">10</h2>
                  <label className="text-xs leading-3">
                    Today's Deactivated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">700</h2>
                  <label className="text-xs leading-3">
                    Weekly Deactivated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">500</h2>
                  <label className="text-xs leading-3">
                    Monthly Deactivated Users
                  </label>
                </div>
              </div>
              <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
                <div className="p-2">
                  <h2 className="text-2xl py-1">100</h2>
                  <label className="text-xs leading-3">
                    Yearly Deactivated Users
                  </label>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="bg-white px-5 py-2 shadow-md rounded-sm overflow-x-auto my-4">
          <h3 className="font-medium">Monthly Leads VS Customers</h3>
          <div
            className={`bar-chart-container`}
            style={{
              minWidth: `${barChartLabels.length * 50}px`,
              height: "400px",
            }}
          >
            <Bar options={barChartOptions} data={barChartData} />
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-8 gap-3 my-4 bg-white p-4 shadow-md rounded-sm">
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">10 / 100</h2>
              <label className="text-sm leading-3">Leads Closed</label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">500 / 700</h2>
              <label className="text-sm leading-3">On Trial</label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">500 / 900</h2>
              <label className="text-sm leading-3">
                Activated Subscriptions
              </label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">100</h2>
              <label className="text-sm leading-3">Active</label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">8</h2>
              <label className="text-sm leading-3">
                Trial Expires In 7 Days
              </label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">9</h2>
              <label className="text-sm leading-3">Trial Expired</label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">8</h2>
              <label className="text-sm leading-3">
                Subs. Renewal In 7 Days
              </label>
            </div>
          </div>
          <div className="bg-red-50 shadow-sm me-2 py-4 px-3 rounded-sm">
            <div className="p-2">
              <h2 className="text-2xl py-1">6</h2>
              <label className="text-sm leading-3">Plan Expired</label>
            </div>
          </div>
        </div>
        <div className="2xl:grid 2xl:grid-cols-2 2xl:gap-4 my-4">
          <div className="mt-5">
            <h3 className="my-1">Callback Setup</h3>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              customStyles={customStyles}
            />
          </div>
          <div className="mt-5">
            <h3 className="my-1">FollowUp</h3>
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              customStyles={customStyles}
            />
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 xl:grid xl:grid-cols-2 2xl:grid-cols-5 my-4 gap-4">
          <div
            className="px-5 py-2 bg-white shadow-md rounded-sm mb-4 lg:mb-0 chart-container 2xl:col-span-2"
            style={{ zIndex: 1 }}
          >
            <h3 className="font-medium">Subscriptions</h3>
            <Doughnut data={doughnutData} options={doughnutOptions} />
          </div>
          <div
            className="px-5 py-2 bg-white shadow-md rounded-sm chart-container 2xl:col-span-2"
            style={{ zIndex: 1 }}
          >
            <h3 className="font-medium text-black">Leads</h3>
            <Pie data={pieChartdata} options={pieChartOptions} />
          </div>
          <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-1">
            <div className="md:grid md:grid-cols-2 lg:grid lg:grid-rows-2 xl:grid xl:grid-cols-2 2xl:grid 2xl:grid-rows-2 2xl:grid-cols-none 2xl:gap-4">
              <div className="bg-white shadow-md me-2 py-4 px-3 rounded-sm flex justify-center my-2">
                <CircularProgressWithLabel
                  value={58}
                  text="Call Back"
                  color="#85b6ff"
                />
              </div>
              <div className="bg-white shadow-md me-2 py-4 px-3 rounded-sm flex justify-center my-2">
                <CircularProgressWithLabel
                  value={82}
                  text="Follow Up"
                  color="#85b6ff"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
