import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable, { TableStyles } from "react-data-table-component";
import { loadCustomerList } from "../../Application/Actions/loader";
import { getAllCustomerList } from "../../Application/Selectors/customer";
import { getLoading } from "../../Application/Selectors/loader";
import { CustomerListDTO } from "../../Infrastructure/Core/DTOs/Customer/CustomerListDTO";
import ShimmerTable from "../Loader/Table";

function CustomerManage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadCustomerList);
  }, []);

  const customerList: CustomerListDTO[] = useSelector(getAllCustomerList);
  const loading: boolean = useSelector(getLoading).loading;
  const value: string = useSelector(getLoading).value;

  const [searchText, setSearchText] = useState("");

  let totalSetups: number;
  const tableColsName = [
    "Id",
    "Business Name",
    "Domain",
    "Setup",
    "Sales Rep",
    "Plan Expires On",
    "Active Plan",
  ];
  const customStyles: TableStyles = {
    table: {
      style: {
        width: "100%",
        textAlign: "left",
        fontSize: "0.875rem",
        color: "#4B5563",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#d7d7d7",
        fontWeight: "bold",
        paddingLeft: "35px",
      },
    },
  };
  const columns = [
    {
      name: "Business Name",
      selector: (row: CustomerListDTO) => row.name,
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div
          className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
          onClick={() => handleProceed(row.id)}
        >
          {row.name}
        </div>
      ),
    },
    {
      name: "Domain",
      selector: (row: CustomerListDTO) => row.domain,
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div className="px-4 py-2">{row.domain || "-"}</div>
      ),
    },
    {
      name: "Setup",
      selector: (row: CustomerListDTO) =>
        `${countSetups(row)} / ${totalSetups}`,
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div className="px-5 py-2 md:px-4 lg:px-4">
          <span className="bg-blue-400 text-white rounded-full text-xs px-2 py-1 md:px-3 lg:px-4 lg:text-sm">
            {countSetups(row)} / {totalSetups}
          </span>
        </div>
      ),
    },
    {
      name: "Sales Rep",
      selector: (row: CustomerListDTO) => row.salesRepName,
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div
          className={`px-4 py-2 ${
            row.salesRepresentative
              ? "text-slate-600 bg-gray-200"
              : "text-orange-600 bg-orange-100"
          } rounded-full font-medium`}
        >
          {row.salesRepresentative ? row.salesRepName : "Unassigned"}
        </div>
      ),
    },
    {
      name: "Plan Expires On",
      selector: (row: CustomerListDTO) =>
        new Date(row.planExpiredOn).toLocaleString(),
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div className="px-46 py-2">
          {new Date(row.planExpiredOn).toLocaleString()}
        </div>
      ),
    },
    {
      name: "Active Plan",
      selector: (row: CustomerListDTO) => row.planName,
      sortable: true,
      cell: (row: CustomerListDTO) => (
        <div className="px-4 py-4">{row.planName}</div>
      ),
    },
  ];

  const handleProceed = (id: string | undefined) => {
    navigate(`/CustomerDetail/${id}`);
  };
  const countSetups = (customer: CustomerListDTO): number => {
    const setups = [
      customer.isAccountSetup,
      // customer.isBillingSetup,
      customer.isBranchSetup,
      // customer.isBrandSetup,
      // customer.isCatalogueSetup,
      // customer.isMenuSetup,
      customer.isUserCreated,
      customer.isTaxSetup,
      customer.isSubscriptionSetup,
    ];
    totalSetups = setups.length;
    return setups.filter(Boolean).length;
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const filteredData = customerList.filter((customer) => {
    const salesRepValue = customer.salesRepName || "Unassigned";
    return (
      customer.name.toLowerCase().includes(searchText.toLowerCase()) ||
      customer.domain.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(customer.planExpiredOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      customer.planName.toLowerCase().includes(searchText.toLowerCase()) ||
      (`${countSetups(customer)}/${totalSetups}`).toLowerCase().includes(searchText.toLowerCase())||
      (salesRepValue &&
        salesRepValue.toLowerCase().includes(searchText.toLowerCase()))
    );
  });

  return (
    <>
      <h3 className="text-slate-700 font-semibold text-lg md:text-xl">
        Customer Management
      </h3>
      <div className="flex justify-end my-3">
        <input
          type="text"
          value={searchText}
          onChange={handleSearch}
          placeholder="Search"
          className="border-[1px] border-slate-300 px-2 py-2 rounded text-sm shadow focus:outline-none focus:ring-1 md:w-2/5 lg:w-2/5 xl:w-1/5 2xl:w-1/5 ease-linear transition-all duration-150"
        />
      </div>
      <div className="overflow-x-auto shadow-lg rounded-lg">
        {loading && value === "getCustomerList" ? (
          <ShimmerTable
            colsCount={tableColsName.length}
            colNames={tableColsName}
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            pagination
            highlightOnHover
            pointerOnHover
            onRowClicked={(row) => handleProceed(row.id)}
          />
        )}
      </div>
    </>
  );
}

export default CustomerManage;
