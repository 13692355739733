// action
export const PLAN_LIST = "plan list";
export const CREATE_PLAN = "create plan";
export const PLAN_DETAILS = "plan details";
export const EDIT_PLAN = "edit plan";

//success
export const PLAN_LIST_SUCCESS = "plan list success";
export const CREATE_PLAN_SUCCESS = "create plan success";
export const PLAN_DETAILS_SUCCESS = "plan details success";
export const EDIT_PLAN_SUCCESS = "edit plan success";

//failure
export const PLAN_LIST_FAILURE = "plan list failed";
export const CREATE_PLAN_FAILURE = "create plan failed";
export const PLAN_DETAILS_FAILURE = "plan details failure";
export const EDIT_PLAN_FAILURE = "edit plan failure";

export const planList = {
  type: PLAN_LIST,
};
export const createPlan = (data: any) => ({
  type: CREATE_PLAN,
  payload: data,
});
export const planDetails = (id: any) => ({
  type: PLAN_DETAILS,
  payload: id,
});
export const editPlan = (data: any) => ({
  type: EDIT_PLAN,
  payload: data,
});

export const planListSuccess = (data: any) => ({
  type: PLAN_LIST_SUCCESS,
  payload: data,
});
export const createPlanSuccess = (data: any) => ({
  type: CREATE_PLAN_SUCCESS,
  payload: data,
});
export const planDetailsSuccess = (data: any) => ({
  type: PLAN_DETAILS_SUCCESS,
  payload: data,
});
export const editPlanSuccess = (data: any) => ({
  type: EDIT_PLAN_SUCCESS,
  payload: data,
});

export const planListFailure = (error: any) => ({
  type: PLAN_LIST_FAILURE,
  payload: error,
});
export const createPlanFailure = (error: any) => ({
  type: CREATE_PLAN_FAILURE,
  payload: error,
});
export const planDetailsFailure = (error: any) => ({
  type: PLAN_DETAILS_FAILURE,
  payload: error,
});
export const editPlanFailure = (error: any) => ({
  type: EDIT_PLAN_FAILURE,
  payload: error,
});