import {
  LOAD_ACCOUNT_SETUP,
  LOAD_BRANCH_DETAILS,
  LOAD_BRANCH_LIST,
  LOAD_CHECK_DOMAIN,
  LOAD_CREATE_BRANCH,
  LOAD_CREATE_PLAN,
  LOAD_CREATE_SUBSCRIPTIONPLAN_SETUP,
  LOAD_CREATE_TAXGROUP,
  LOAD_CREATE_USER,
  LOAD_CUSTOMER_DETAIL_BY_ID,
  LOAD_CUSTOMER_LIST,
  LOAD_EDIT_BRANCH,
  LOAD_EDIT_PLAN,
  LOAD_EDIT_SUBSCRIPTIONPLAN_SETUP,
  LOAD_EDIT_TAXGROUP,
  LOAD_EDIT_USER,
  LOAD_ENQUIRY_DETAIL_BY_ID,
  LOAD_ENQUIRY_LIST,
  LOAD_LEAD_TO_CUSTOMER,
  LOAD_PLAN_DETAILS,
  LOAD_PLAN_LIST,
  LOAD_SALES_REP_LIST,
  LOAD_SUBSCRIPTIONPLAN_SETUP_DETAILS,
  LOAD_SUBSCRIPTIONPLAN_SETUP_LIST,
  LOAD_TAXGROUP_DETAILS,
  LOAD_TAXGROUP_LIST,
  LOAD_UPLOAD_MENU,
  LOAD_USER_DETAILS,
  LOAD_USER_LIST
} from "../Actions/loader";
import * as enquiryActions from "../Actions/enquiry";
import * as customerActions from "../Actions/customer";
import * as salesRepActions from "../Actions/salesRep";
import * as planActions from "../Actions/plan";

//lead
const loadEnquiryList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_ENQUIRY_LIST) {
      log("page loaded");
      dispatch(enquiryActions.enquiryList);
    }
    next(action);
  };
const loadEnquiryDetailsById =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_ENQUIRY_DETAIL_BY_ID) {
      log("page loaded");
      dispatch(enquiryActions.enquiryDetailsById(action.payload));
    }
    next(action);
  };
const loadLeadToCustomer =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_LEAD_TO_CUSTOMER) {
      log("page loaded");
      dispatch(enquiryActions.leadToCustomer(action.payload));
    }
    next(action);
  };

//tenant
const loadCustomerList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CUSTOMER_LIST) {
      log("page loaded");
      dispatch(customerActions.customerList);
    }
    next(action);
  };
const loadCustomerDetailsById =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CUSTOMER_DETAIL_BY_ID) {
      log("page loaded");
      dispatch(customerActions.customerDetailsById(action.payload));
    }
    next(action);
  };
const loadCheckDomain =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CHECK_DOMAIN) {
      log("page loaded");
      dispatch(customerActions.checkDomain(action.payload));
    }
    next(action);
  };
//setups
const loadAccountSetup =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_ACCOUNT_SETUP) {
      log("page loaded");
      dispatch(customerActions.accountSetup(action.payload));
    }
    next(action);
  };
//branch
const loadCreateBranch =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CREATE_BRANCH) {
      log("page loaded");
      dispatch(customerActions.createBranch(action.payload));
    }
    next(action);
  };
const loadEditBranch =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_EDIT_BRANCH) {
      log("page loaded");
      dispatch(customerActions.editBranch(action.payload));
    }
    next(action);
  };
const loadBranchList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_BRANCH_LIST) {
      log("page loaded");
      dispatch(customerActions.branchList(action.payload));
    }
    next(action);
  };
const loadBranchDetails =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_BRANCH_DETAILS) {
      log("page loaded");
      dispatch(customerActions.branchDetails(action.payload));
    }
    next(action);
  };
//user
const loadCreateUser =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CREATE_USER) {
      log("page loaded");
      dispatch(customerActions.createUser(action.payload));
    }
    next(action);
  };
const loadEditUser =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_EDIT_USER) {
      log("page loaded");
      dispatch(customerActions.editUser(action.payload));
    }
    next(action);
  };
const loadUserList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_USER_LIST) {
      log("page loaded");
      dispatch(customerActions.userList(action.payload));
    }
    next(action);
  };
const loadUserDetails =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_USER_DETAILS) {
      log("page loaded");
      dispatch(customerActions.userDetails(action.payload));
    }
    next(action);
  };
//tax group
const loadCreateTaxGroup =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CREATE_TAXGROUP) {
      log("page loaded");
      dispatch(customerActions.createTaxGroup(action.payload));
    }
    next(action);
  };
const loadEditTaxGroup =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_EDIT_TAXGROUP) {
      log("page loaded");
      dispatch(customerActions.editTaxGroup(action.payload));
    }
    next(action);
  };
const loadTaxGroupList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_TAXGROUP_LIST) {
      log("page loaded");
      dispatch(customerActions.taxGroupList(action.payload));
    }
    next(action);
  };
const loadTaxGroupDetails =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_TAXGROUP_DETAILS) {
      log("page loaded");
      dispatch(customerActions.taxGroupDetails(action.payload));
    }
    next(action);
  };
//subscription plan
const loadCreateSubscriptionPlanSetup =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CREATE_SUBSCRIPTIONPLAN_SETUP) {
      log("page loaded");
      dispatch(customerActions.createSubscriptionPlanSetup(action.payload));
    }
    next(action);
  };
const loadEditSubscriptionPlanSetup =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_EDIT_SUBSCRIPTIONPLAN_SETUP) {
      log("page loaded");
      dispatch(customerActions.editSubscriptionPlanSetup(action.payload));
    }
    next(action);
  };
const loadSubscriptionPlanSetupList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_SUBSCRIPTIONPLAN_SETUP_LIST) {
      log("page loaded");
      dispatch(customerActions.subscriptionPlanSetupList(action.payload));
    }
    next(action);
  };
const loadSubscriptionPlanSetupDetails =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_SUBSCRIPTIONPLAN_SETUP_DETAILS) {
      log("page loaded");
      dispatch(customerActions.subscriptionPlanSetupDetails(action.payload));
    }
    next(action);
  };
//menu
const loadUploadMenu =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_UPLOAD_MENU) {
      log("page loaded");
      dispatch(customerActions.uploadMenu(action.payload));
    }
    next(action);
  };
//plan
const loadPlanList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_PLAN_LIST) {
      log("page loaded");
      dispatch(planActions.planList);
    }
    next(action);
  };
const loadCreatePlan =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_CREATE_PLAN) {
      log("page loaded");
      dispatch(planActions.createPlan(action.payload));
    }
    next(action);
  };
const loadEditPlan =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_EDIT_PLAN) {
      log("page loaded");
      dispatch(planActions.editPlan(action.payload));
    }
    next(action);
  };
  const loadPlanDetails =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_PLAN_DETAILS) {
      log("page loaded");
      dispatch(planActions.planDetails(action.payload));
    }
    next(action);
  };
//sales rep
const loadSalesRepList =
  ({ log }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type === LOAD_SALES_REP_LIST) {
      log("page loaded");
      dispatch(salesRepActions.salesRepList);
    }
    next(action);
  };
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  loadEnquiryList,
  loadEnquiryDetailsById,
  loadCustomerList,
  loadCustomerDetailsById,
  loadCheckDomain,
  loadAccountSetup,
  loadLeadToCustomer,
  loadCreateBranch,
  loadBranchList,
  loadBranchDetails,
  loadSalesRepList,
  loadEditBranch,
  loadCreateUser,
  loadEditUser,
  loadUserList,
  loadUserDetails,
  loadCreateTaxGroup,
  loadEditTaxGroup,
  loadTaxGroupList,
  loadTaxGroupDetails,
  loadUploadMenu,
  loadCreateSubscriptionPlanSetup,
  loadEditSubscriptionPlanSetup,
  loadSubscriptionPlanSetupList,
  loadSubscriptionPlanSetupDetails,
  loadPlanList,
  loadCreatePlan,
  loadPlanDetails,
  loadEditPlan
];
