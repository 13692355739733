// data
//list
export const ENQUIRY_LIST = "enquiry list load";
export const SALE_STATUS_LIST = "sale status list";
export const ENQUIRY_CALL_LOG = "enquiry call log";
//details
export const UPDATE_ENQUIRY_DETAIL = "enquiry details update";
export const ENQUIRY_DETAIL_BY_ID = "get details by enquiry id";
//create
export const LEAD_TO_CUSTOMER = "convert lead to customer";
//action
export const RESET_TENANT_CREATED = "reset tenant created";

// success
//list
export const ENQUIRY_LIST_SUCCESS = "enquiry list loaded";
export const ENQUIRY_CALL_LOG_SUCCESS = "enquiry call log updated";
export const SALE_STATUS_LIST_SUCCESS = "sale status list success";
//details
export const ENQUIRY_DETAIL_BY_ID_SUCCESS = "enquiry detail loaded";
//update
export const UPDATE_ENQUIRY_DETAIL_SUCCESS = "enquiry details updated success";
//create
export const LEAD_TO_CUSTOMER_SUCCESS = "lead to customer success";

// failure
//list
export const ENQUIRY_LIST_FAILURE = "enquiry list failed";
export const ENQUIRY_CALL_LOG_FAILURE = "enquiry call log updation failed";
export const SALE_STATUS_LIST_FAILURE = "sale status list failed";
//details
export const ENQUIRY_DETAIL_BY_ID_FAILURE = "enquiry detail failed";
//update
export const UPDATE_ENQUIRY_DETAIL_FAILURE = "enquiry details updated failed";
//create
export const LEAD_TO_CUSTOMER_FAILURE = "lead to customer failed";

// get enquiry list
export const enquiryList = {
  type: ENQUIRY_LIST,
};
export const enquiryListSuccess = (enquiryList: any) => ({
  type: ENQUIRY_LIST_SUCCESS,
  payload: enquiryList,
});
export const enquiryListFailure = (error: any) => ({
  type: ENQUIRY_LIST_FAILURE,
  payload: error,
});

// get enquiry details
export const enquiryDetailsById = (id: any) => ({
  type: ENQUIRY_DETAIL_BY_ID,
  payload: id,
});
export const enquiryDetailSuccess = (enquiryData: any) => ({
  type: ENQUIRY_DETAIL_BY_ID_SUCCESS,
  payload: enquiryData,
});
export const enquiryDetailFailure = (error: any) => ({
  type: ENQUIRY_DETAIL_BY_ID_FAILURE,
  payload: error,
});

// status log
export const enquiryCallLog = (data: any) => ({
  type: ENQUIRY_CALL_LOG,
  payload: data,
});
export const enquiryStatusSuccess = (data: any) => ({
  type: ENQUIRY_CALL_LOG_SUCCESS,
  payload: data,
});
export const enquiryStatusFailure = (error: any) => ({
  type: ENQUIRY_CALL_LOG_FAILURE,
  payload: error,
});

// get sale status list
export const saleStatusList = {
  type: SALE_STATUS_LIST,
};
export const saleStatusListSuccess = (data: any) => ({
  type: SALE_STATUS_LIST_SUCCESS,
  payload: data,
});
export const saleStatusListFailure = (error: any) => ({
  type: SALE_STATUS_LIST_FAILURE,
  payload: error,
});

// update enquiry details
export const updateEnquiryDetail = (data: any) => ({
  type: UPDATE_ENQUIRY_DETAIL,
  payload: data,
});
export const updateEnquiryDetailSuccess = (data: any) => ({
  type: UPDATE_ENQUIRY_DETAIL_SUCCESS,
  payload: data,
});
export const updateEnquiryDetailFailure = (error: any) => ({
  type: UPDATE_ENQUIRY_DETAIL_FAILURE,
  payload: error,
});

//lead to customer
export const leadToCustomer = (data: any) => ({
  type: LEAD_TO_CUSTOMER,
  payload: data,
});
export const leadToCustomerSuccess = (data: any) => ({
  type: LEAD_TO_CUSTOMER_SUCCESS,
  payload: data,
});
export const leadToCustomerFailure = (error: any) => ({
  type: LEAD_TO_CUSTOMER_FAILURE,
  payload: error,
});

//reset
export const resetTenantCreated = {
  type: RESET_TENANT_CREATED,
};
