import {
  ACCOUNT_SETUP_SUCCESS,
  BRANCH_DETAILS_SUCCESS,
  BRANCH_LIST_SUCCESS,
  CHECK_DOMAIN_SUCCESS,
  CREATE_BRANCH_SUCCESS,
  CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  CREATE_TAXGROUP_SUCCESS,
  CREATE_USER_SUCCESS,
  CUSTOMER_DETAIL_BY_ID_SUCCESS,
  CUSTOMER_LIST_SUCCESS,
  EDIT_BRANCH_SUCCESS,
  EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  EDIT_TAXGROUP_SUCCESS,
  EDIT_USER_SUCCESS,
  RESET_CREATE_BRANCH_SUCCESS,
  RESET_CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  RESET_CREATE_TAXGROUP_SUCCESS,
  RESET_CREATE_USER_SUCCESS,
  RESET_EDIT_BRANCH_SUCCESS,
  RESET_EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS,
  RESET_EDIT_TAXGROUP_SUCCESS,
  RESET_EDIT_USER_SUCCESS,
  SUBSCRIPTIONPLAN_SETUP_DETAILS_SUCCESS,
  SUBSCRIPTIONPLAN_SETUP_LIST_SUCCESS,
  TAXGROUP_DETAILS_SUCCESS,
  TAXGROUP_LIST_SUCCESS,
  UPLOAD_MENU_SUCCESS,
  USER_DETAILS_SUCCESS,
  USER_LIST_SUCCESS,
} from "../Actions/customer";

const initialState = {
  customerList: [],
  branchList: [],
  userList: [],
  taxGroupList: [],
  subscriptionPlanSetupList: [],
  menuListSuccess: undefined,
  cutomerDetail: {},
  taxGroupDetails: {},
  subscriptionPlanSetupDetails: {},
  domainSuccess: undefined,
  branchDetails: {},
  userDetails: {},
  createBranchSuccess: undefined,
  createUserSuccess: undefined,
  createSubscriptionPlanSetupSuccess: undefined,
  createTaxGroupSuccess: undefined,
  editBranchSuccess: undefined,
  editUserSuccess: undefined,
  editTaxGroupSuccess: undefined,
  editSubscriptionPlanSetupSuccess: undefined,
  error: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CUSTOMER_LIST_SUCCESS:
      return { ...state, customerList: action.payload, error: null };
    case TAXGROUP_LIST_SUCCESS:
      return { ...state, taxGroupList: action.payload.result, error: null };
    case BRANCH_LIST_SUCCESS:
      return { ...state, branchList: action.payload.result, error: null };
    case USER_LIST_SUCCESS:
      return { ...state, userList: action.payload.result, error: null };
    case SUBSCRIPTIONPLAN_SETUP_LIST_SUCCESS:
      return {
        ...state,
        subscriptionPlanSetupList: action.payload.result,
        error: null,
      };
    case CUSTOMER_DETAIL_BY_ID_SUCCESS:
      return { ...state, cutomerDetail: action.payload, error: null };
    case BRANCH_DETAILS_SUCCESS:
      return { ...state, branchDetails: action.payload.result, error: null };
    case USER_DETAILS_SUCCESS:
      return { ...state, userDetails: action.payload.result, error: null };
    case TAXGROUP_DETAILS_SUCCESS:
      return { ...state, taxGroupDetails: action.payload.result, error: null };
    case SUBSCRIPTIONPLAN_SETUP_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionPlanSetupDetails: action.payload.result,
        error: null,
      };
    case CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        createBranchSuccess: action.payload.result,
        error: null,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserSuccess: action.payload.result,
        error: null,
      };
    case CREATE_TAXGROUP_SUCCESS:
      return {
        ...state,
        createTaxGroupSuccess: action.payload.result,
        error: null,
      };
    case CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS:
      return {
        ...state,
        subscriptionPlanSetupList: action.payload.result,
        error: null,
      };

    case EDIT_BRANCH_SUCCESS:
      return {
        ...state,
        editBranchSuccess: action.payload.result,
        error: null,
      };
    case EDIT_USER_SUCCESS:
      return { ...state, editUserSuccess: action.payload.result, error: null };
    case EDIT_TAXGROUP_SUCCESS:
      return {
        ...state,
        editTaxGroupSuccess: action.payload.result,
        error: null,
      };
    case EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS:
      return {
        ...state,
        editSubscriptionPlanSetupSuccess: action.payload.result,
        error: null,
      };
    case CHECK_DOMAIN_SUCCESS:
      return { ...state, domainSuccess: action.payload, error: null };
    case ACCOUNT_SETUP_SUCCESS:
      return { ...state, cutomerDetail: action.payload, error: null };
    case RESET_CREATE_BRANCH_SUCCESS:
      return { ...state, createBranchSuccess: undefined, error: null };
    case RESET_EDIT_BRANCH_SUCCESS:
      return { ...state, editBranchSuccess: undefined, error: null };
    case RESET_CREATE_TAXGROUP_SUCCESS:
      return { ...state, createTaxGroupSuccess: undefined, error: null };
    case RESET_EDIT_TAXGROUP_SUCCESS:
      return { ...state, editTaxGroupSuccess: undefined, error: null };
    case RESET_CREATE_USER_SUCCESS:
      return { ...state, createUserSuccess: undefined, error: null };
    case RESET_EDIT_USER_SUCCESS:
      return { ...state, editUserSuccess: undefined, error: null };
    case RESET_CREATE_SUBSCRIPTIONPLAN_SETUP_SUCCESS:
      return {
        ...state,
        createSubscriptionPlanSetuSuccess: undefined,
        error: null,
      };
    case RESET_EDIT_SUBSCRIPTIONPLAN_SETUP_SUCCESS:
      return {
        ...state,
        editSubscriptionPlanSetupSuccess: undefined,
        error: null,
      };
    case UPLOAD_MENU_SUCCESS:
      return {
        ...state,
        menuListSuccess: action.payload.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
