import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AuthContext from "../../Infrastructure/Core/Helper/AuthContext";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { useSelector } from "react-redux";
import { getLoading } from "../../Application/Selectors/loader";

import { validateRequired } from "../../Infrastructure/Core/validationUtils";
function Login() {
  const userNameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState({
    userNameRef: "",
    passwordRef: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setFieldErrors({
      userNameRef: "",
      passwordRef: "",
    });
  }, []);

  if (!authContext) {
    return null;
  }

  const { login } = authContext;

  const setErrorNullOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError(null);
    if (value?.trim() !== "") {
      setFieldErrors({ ...fieldErrors, [name]: "" });
    } else {
      setFieldErrors({
        ...fieldErrors,
        [name]: `${
          name === "userName"
            ? "UserName"
            : name === "password"
            ? "Password"
            : "This Field"
        } is required`,
      });
    }
  };
  const validateInput = () => {
    const newErrors = {
      userNameRef: "",
      passwordRef: "",
    };
    newErrors.userNameRef = validateRequired(userNameRef.current?.value.trim())
      ? ""
      : "Full Name is required";
    newErrors.passwordRef = validateRequired(passwordRef.current?.value.trim())
      ? ""
      : "Country Code is required";
    setFieldErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };

  const LoginSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    if (!userNameRef.current?.value.trim()) {
      return;
    }
    if (!passwordRef.current?.value.trim()) {
      return;
    }
    const payLoad = {
      username: userNameRef.current.value,
      password: passwordRef.current.value,
    };
    try {
      await login(payLoad);
    } catch (error) {
      setError("Invalid username or password");
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <div
          className="bg-cover bg-center hidden lg:block lg:w-6/12 xl:w-6/12 xl:mx-0 2xl:w-8/12"
          style={{
            backgroundImage: "url('assets/Images/login-img.png')",
          }}
        />
        <div className="absolute top-1/3 left-0 px-10 text-white w-7/12 hidden lg:block lg:w-6/12 lg:mx-0 xl:w-6/12 xl:mx-0 2xl:mx-20">
          <div className="header-logo">
            <img
              className="w-60"
              src="assets/Images/main-logo.png"
              alt="logo"
            />
          </div>

          <div className="mx-3 my-2 text-justify">
            <h1 className="font-bold text-3xl">
              Where Innovation Meets Flavor !
            </h1>
            <p className="font-normal text-sm text-gray-200 my-3">
              Unlock the Power of Fudeasy MSP Portal! Your All-in-One Solution
              for Effortless Restaurant Management. Whether you're a sales
              dynamo, a visionary owner, or a tech wizard, dive into a world of
              seamless operations, actionable insights, and boundless potential.
              Join the Fudeasy revolution and elevate your business game today!
            </p>
          </div>
        </div>
        <div className="flex-1 w-4/12">
          <div className="bg-white p-16 md:p-24 lg:p-24 xl:p-14 2xl:p-24 h-full flex flex-col justify-center">
            <h2 className="text-2xl font-bold mb-3">Sign In</h2>
            <p className="text-gray-600 mb-6">
              Welcome back! Please sign in to continue.
            </p>
            <form>
              <div className="mb-4">
                <label
                  htmlFor="userName"
                  className="block text-sm font-medium text-gray-700 mr-1"
                >
                  Email address
                </label>
                <div className="flex items-center align-middle content-center">
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    ref={userNameRef}
                    className={`flex-11 shadow appearance-none border ${
                      fieldErrors.userNameRef !== "" &&
                      !userNameRef.current?.value.trim()
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-0 focus:shadow-outline focus:ring-1 focus:ring-amber-500 focus:border-0`}
                    placeholder="Enter your user name"
                    onChange={setErrorNullOnChange}
                    autoComplete="off"
                  />
                  {fieldErrors.userNameRef !== "" &&
                    !userNameRef.current?.value.trim() && (
                      <div className="flex-1 pl-1" title="Username is required">
                        <i className="fas fa-info-circle text-red-500 cursor-pointer" />
                      </div>
                    )}
                </div>
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 mr-1"
                >
                  Password
                </label>
                <div className="flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    ref={passwordRef}
                    autoComplete="off"
                    className={`flex-11 shadow appearance-none border ${
                      fieldErrors.passwordRef !== "" &&
                      !passwordRef.current?.value.trim()
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-0 focus:shadow-outline focus:ring-1 focus:ring-amber-500 focus:border-0 pr-10`}
                    placeholder="Enter your password"
                    onChange={setErrorNullOnChange}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 pt-2 cursor-pointer"
                    title="Password is required"
                  >
                    <i
                      className={`fas ${
                        showPassword ? "fa-eye-slash" : "fa-eye"
                      } text-gray-400 cursor-pointer ${
                        fieldErrors.passwordRef !== "" &&
                        !passwordRef.current?.value.trim()
                          ? "mr-5"
                          : ""
                      }`}
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  <div className="flex-1 pl-1">
                    {fieldErrors.passwordRef !== "" &&
                    !passwordRef.current?.value.trim() ? (
                      <i
                        className="fas fa-info-circle text-red-500 cursor-pointer"
                        title="Password is required"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              {error && <h6 className="text-red-500 text-sm">{error}</h6>}

              <button
                type="button"
                onClick={LoginSubmit}
                className="bg-[#ba2618] py-1.5 px-5 mt-4 text-lg text-white rounded-md hover:bg-[#d13b2d]"
                style={{ width: "100%", textAlign: "center" }}
                disabled={loading && value === "login"}
              >
                {loading && value === "login" ? (
                  <LoadingSpinner color="#ffffff" size={25} type={"TailSpin"} />
                ) : (
                  "Sign In"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
