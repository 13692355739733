import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface Token {
  token: string;
  refreshToken: string;
  domain: string;
}

const jwtInterceptor = axios.create();

jwtInterceptor.interceptors.request.use(
  (config) => {
    const storedTokenString: string | null = localStorage.getItem("Token");
    if (storedTokenString !== null) {
      const storedToken: Token = JSON.parse(storedTokenString);
      config.headers.Authorization = `Bearer ${storedToken.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

jwtInterceptor.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    if (error.response?.status === 401) {
      try {
        const storedTokenString: string | null = localStorage.getItem("Token");
        if (storedTokenString === null) {
          throw new Error("No token available for refresh");
        }
        const storedToken: Token = JSON.parse(storedTokenString);
        const datatobepassed = {
          accessToken: storedToken.token,
          refreshToken: storedToken.refreshToken,
          domain: "admin@dastar.com",
        };
        const refreshResponse = await axios.post(
          "https://gateway.fudeasy.com/api/Authenticate/refresh-token",
          datatobepassed
        );
        const responseToken: Token = {
          token: refreshResponse.data.accessToken,
          refreshToken: refreshResponse.data.refreshToken,
          domain: "admin@dastar.com",
        };
        const originalRequest = error.config as AxiosRequestConfig;
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${responseToken.token}`,
        };
        return axios(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem("Token");
        throw refreshError;
      }
    }
    return Promise.reject(error);
  }
);

export default jwtInterceptor;
