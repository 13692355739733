import ToastifyService from "../../Infrastructure/Core/Helper/ToastifyService";
import {
  ACCOUNT_SETUP,
  BRANCH_DETAILS,
  BRANCH_LIST,
  CHECK_DOMAIN,
  CREATE_BRANCH,
  CREATE_SUBSCRIPTIONPLAN_SETUP,
  CREATE_TAXGROUP,
  CREATE_USER,
  CUSTOMER_DETAIL_BY_ID,
  CUSTOMER_LIST,
  EDIT_BRANCH,
  EDIT_SUBSCRIPTIONPLAN_SETUP,
  EDIT_TAXGROUP,
  EDIT_USER,
  SUBSCRIPTIONPLAN_SETUP_LIST,
  TAXGROUP_DETAILS,
  TAXGROUP_LIST,
  UPLOADED_MENULIST,
  UPLOAD_MENU,
  USER_DETAILS,
  USER_LIST,
  accountSetupFailure,
  accountSetupSuccess,
  branchDetailsFailure,
  branchDetailsSuccess,
  branchListFailure,
  branchListSuccess,
  checkDomainFailure,
  checkDomainSuccess,
  createBranchFailure,
  createBranchSuccess,
  createSubscriptionPlanSetupFailure,
  createSubscriptionPlanSetupSuccess,
  createTaxGroupFailure,
  createTaxGroupSuccess,
  createUserFailure,
  createUserSuccess,
  customerDetailFailure,
  customerListFailure,
  customerListSuccess,
  customeryDetailSuccess,
  editBranchFailure,
  editBranchSuccess,
  editSubscriptionPlanSetupFailure,
  editSubscriptionPlanSetupSuccess,
  editTaxGroupFailure,
  editTaxGroupSuccess,
  editUserFailure,
  editUserSuccess,
  subscriptionPlanSetupDetailsFailure,
  subscriptionPlanSetupDetailsSuccess,
  subscriptionPlanSetupFailure,
  subscriptionPlanSetupListSuccess,
  taxGroupDetailsFailure,
  taxGroupDetailsSuccess,
  taxGroupListFailure,
  taxGroupListSuccess,
  uploadMenuFailure,
  uploadMenuSuccess,
  userDetailsFailure,
  userDetailsSuccess,
  userListFailure,
  userListSuccess,
} from "../Actions/customer";
import * as loaderActions from "../Actions/loader";

const getCustomerList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CUSTOMER_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "getCustomerList" })
        );
        const customerList = await api.Customer.getCustomerList();
        dispatch(customerListSuccess(customerList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getCustomerList" })
        );
        dispatch(customerListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getCustomerList" })
        );
      }
    }
    next(action);
  };
const getCustomerDetailsById =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CUSTOMER_DETAIL_BY_ID) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "getCustomerDetailsById",
          })
        );
        const customerDetail = await api.Customer.getCustomerDetailById(
          action.payload
        );
        dispatch(customeryDetailSuccess(customerDetail));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getCustomerDetailsById",
          })
        );
        dispatch(customerDetailFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getCustomerDetailsById",
          })
        );
      }
    }
    next(action);
  };
const checkDomain =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CHECK_DOMAIN) {
      try {
        const domainStatus = await api.Customer.checkDomain(action.payload);
        dispatch(checkDomainSuccess(domainStatus.result));
      } catch (error) {
        dispatch(checkDomainFailure(error));
      }
    }
    next(action);
  };
const accountSetup =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === ACCOUNT_SETUP) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "accountSetup" })
        );
        const accountSetup = await api.Customer.accountSetup(action.payload);
        dispatch(accountSetupSuccess(accountSetup));
        ToastifyService.success("Account setup Successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "accountSetup" })
        );
        dispatch(accountSetupFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "accountSetup" })
        );
        dispatch(loaderActions.setLoading({ loading: false, value: "" }));
      }
    }
    next(action);
  };
const createBranch =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CREATE_BRANCH) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "createBranch" })
        );
        const branchCreateRes = await api.Customer.createBranch(action.payload);
        dispatch(createBranchSuccess(branchCreateRes));
        dispatch(loaderActions.loadUserList(action.payload.tenantId));
        dispatch(
          loaderActions.loadCustomerDetailsById(action.payload.tenantId)
        );
        ToastifyService.success("Branch created successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createBranch" })
        );
        dispatch(createBranchFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createBranch" })
        );
      }
    }
    next(action);
  };
const editBranch =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === EDIT_BRANCH) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "editBranch" })
        );
        const branchEditRes = await api.Customer.editBranch(action.payload);
        dispatch(editBranchSuccess(branchEditRes));
        ToastifyService.success("Branch details updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editBranch" })
        );
        dispatch(editBranchFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editBranch" })
        );
      }
    }
    next(action);
  };
const branchList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === BRANCH_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "branchList" })
        );
        const branchList = await api.Customer.branchList(action.payload);
        dispatch(branchListSuccess(branchList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "branchList" })
        );
        dispatch(branchListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "branchList" })
        );
      }
    }
    next(action);
  };
const branchDetails =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === BRANCH_DETAILS) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "branchDetails" })
        );
        const branchDetails = await api.Customer.branchDetails(action.payload);
        dispatch(branchDetailsSuccess(branchDetails));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "branchDetails" })
        );
        dispatch(branchDetailsFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "branchDetails" })
        );
      }
    }
    next(action);
  };
const createUser =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CREATE_USER) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "createUser" })
        );
        const userCreateRes = await api.Customer.createUser(action.payload);
        dispatch(
          loaderActions.loadCustomerDetailsById(action.payload.tenantId)
        );
        dispatch(createUserSuccess(userCreateRes));
        ToastifyService.success("User created successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createUser" })
        );
        dispatch(createUserFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createUser" })
        );
      }
    }
    next(action);
  };
const editUser =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === EDIT_USER) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "editUser" })
        );
        const userEditRes = await api.Customer.editUser(action.payload);
        dispatch(editUserSuccess(userEditRes));
        ToastifyService.success("User details updated Successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editUser" })
        );
        dispatch(editUserFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editUser" })
        );
      }
    }
    next(action);
  };
const userList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === USER_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "userList" })
        );
        const userList = await api.Customer.userList(action.payload);
        dispatch(userListSuccess(userList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "userList" })
        );
        dispatch(userListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "userList" })
        );
      }
    }
    next(action);
  };
const userDetails =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === USER_DETAILS) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "userDetails" })
        );
        const userDetails = await api.Customer.userDetails(action.payload);
        dispatch(userDetailsSuccess(userDetails));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "userDetails" })
        );
        dispatch(userDetailsFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "userDetails" })
        );
      }
    }
    next(action);
  };
const createTaxGroup =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CREATE_TAXGROUP) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "createTaxGroup" })
        );
        const taxGroupCreateRes = await api.Customer.createTaxGroup(
          action.payload
        );
        dispatch(createTaxGroupSuccess(taxGroupCreateRes));
        dispatch(
          loaderActions.loadCustomerDetailsById(action.payload.tenantId)
        );
        ToastifyService.success("Tax created successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createTaxGroup" })
        );
        dispatch(createTaxGroupFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "createTaxGroup" })
        );
      }
    }
    next(action);
  };
const editTaxGroup =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === EDIT_TAXGROUP) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "editTaxGroup" })
        );
        const taxGroupEditRes = await api.Customer.editTaxGroup(action.payload);
        dispatch(editTaxGroupSuccess(taxGroupEditRes));
        ToastifyService.success("Tax updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editTaxGroup" })
        );
        dispatch(editTaxGroupFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "editTaxGroup" })
        );
      }
    }
    next(action);
  };
const taxGroupList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === TAXGROUP_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "taxGroupList" })
        );
        const taxGroupList = await api.Customer.taxGroupList(action.payload);
        dispatch(taxGroupListSuccess(taxGroupList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "taxGroupList" })
        );
        dispatch(taxGroupListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "taxGroupList" })
        );
      }
    }
    next(action);
  };
const taxGroupDetails =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === TAXGROUP_DETAILS) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "taxGroupDetails" })
        );
        const taxGroupDetails = await api.Customer.taxGroupDetails(
          action.payload
        );
        dispatch(taxGroupDetailsSuccess(taxGroupDetails));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "taxGroupDetails" })
        );
        dispatch(taxGroupDetailsFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "taxGroupDetails" })
        );
      }
    }
    next(action);
  };
const createSubscriptionPlanSetup =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === CREATE_SUBSCRIPTIONPLAN_SETUP) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "createSubscriptionPlanSetup",
          })
        );
        const subscriptionPlanSetupCreateRes =
          await api.Customer.createSubscriptionPlanSetup(action.payload);
        dispatch(
          createSubscriptionPlanSetupSuccess(subscriptionPlanSetupCreateRes)
        );
        dispatch(
          loaderActions.loadCustomerDetailsById(action.payload.tenantId)
        );
        ToastifyService.success("Subscription added successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "createSubscriptionPlanSetup",
          })
        );
        dispatch(createSubscriptionPlanSetupFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "createSubscriptionPlanSetup",
          })
        );
      }
    }
    next(action);
  };
const editSubscriptionPlanSetup =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === EDIT_SUBSCRIPTIONPLAN_SETUP) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "editSubscriptionPlanSetup",
          })
        );
        const subscriptionPlanSetupEditRes =
          await api.Customer.editSubscriptionPlanSetup(action.payload);
        dispatch(
          editSubscriptionPlanSetupSuccess(subscriptionPlanSetupEditRes)
        );
        ToastifyService.success("Subscription updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "editSubscriptionPlanSetup",
          })
        );
        dispatch(editSubscriptionPlanSetupFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "editSubscriptionPlanSetup",
          })
        );
      }
    }
    next(action);
  };
const subscriptionPlanSetupList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === SUBSCRIPTIONPLAN_SETUP_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "subscriptionPlanSetupList",
          })
        );
        const subscriptionPlanSetupList =
          await api.Customer.subscriptionPlanSetupList(action.payload);
        dispatch(subscriptionPlanSetupListSuccess(subscriptionPlanSetupList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "subscriptionPlanSetupList",
          })
        );
        dispatch(subscriptionPlanSetupFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "subscriptionPlanSetupList",
          })
        );
      }
    }
    next(action);
  };
const subscriptionPlanSetupDetails =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === TAXGROUP_DETAILS) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "subscriptionPlanSetupDetails",
          })
        );
        const subscriptionPlanSetupDetails =
          await api.Customer.subscriptionPlanSetupDetails(action.payload);
        dispatch(
          subscriptionPlanSetupDetailsSuccess(subscriptionPlanSetupDetails)
        );
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "subscriptionPlanSetupDetails",
          })
        );
        dispatch(subscriptionPlanSetupDetailsFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "subscriptionPlanSetupDetails",
          })
        );
      }
    }
    next(action);
  };
const uploadMenu =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === UPLOAD_MENU) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "uploadMenu" })
        );
        const uploadMenuRes = await api.Customer.uploadMenu(action.payload);
        dispatch(uploadMenuSuccess(uploadMenuRes));
        dispatch(
          loaderActions.loadCustomerDetailsById(action.payload.get("TenantId"))
        );
        ToastifyService.success("Menu uploaded successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "uploadMenu" })
        );
        dispatch(uploadMenuFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "uploadMenu" })
        );
      }
    }
    next(action);
  };
const loadMenuList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === UPLOADED_MENULIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "loadMenuList" })
        );
        const loadMenuListRes = await api.Customer.loadMenuList(action.payload);
        dispatch(uploadMenuSuccess(loadMenuListRes));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "loadMenuList" })
        );
        dispatch(uploadMenuFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "loadMenuList" })
        );
      }
    }
    next(action);
  };
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  getCustomerList,
  getCustomerDetailsById,
  checkDomain,
  accountSetup,
  createBranch,
  branchList,
  branchDetails,
  editBranch,
  createUser,
  editUser,
  userList,
  userDetails,
  createTaxGroup,
  editTaxGroup,
  taxGroupList,
  taxGroupDetails,
  uploadMenu,
  createSubscriptionPlanSetup,
  editSubscriptionPlanSetup,
  subscriptionPlanSetupList,
  subscriptionPlanSetupDetails,
  loadMenuList,
];
