import { COMMONFIELD_LIST_SUCCESS } from "../Actions/common";

const initialState = {
  commonFieldList: [],
  error: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COMMONFIELD_LIST_SUCCESS:
      return { ...state, commonFieldList: action.payload, error: null };
    default:
      return state;
  }
};

export default reducer;
