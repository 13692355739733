//list
export const LOAD_ENQUIRY_LIST = "loading enquiry list";
export const LOAD_CUSTOMER_LIST = "loading customer list";
export const LOAD_BRANCH_LIST = "loading branch list";
export const LOAD_USER_LIST = "loading user list";
export const LOAD_TAXGROUP_LIST = "loading tax group list";
export const LOAD_SALES_REP_LIST = "loading sales rep list";
export const LOAD_SUBSCRIPTIONPLAN_SETUP_LIST =
  "loading subscription plan setup list";
export const LOAD_PLAN_LIST = "loading plan list";
//details
export const LOAD_ENQUIRY_DETAIL_BY_ID = "loading enquiry detail";
export const LOAD_CUSTOMER_DETAIL_BY_ID = "loading customer detail";
export const LOAD_TAXGROUP_DETAILS = "loading tax group detail";
export const LOAD_PLAN_DETAILS = "loading plan detail";
export const LOAD_BRANCH_DETAILS = "loading branch details";
export const LOAD_USER_DETAILS = "loading user details";
export const LOAD_SUBSCRIPTIONPLAN_SETUP_DETAILS =
  "loading subscription plam details";
//actions
export const LOAD_CHECK_DOMAIN = "loading check customer domain";
export const LOAD_ACCOUNT_SETUP = "loading account setup";
export const LOAD_LEAD_TO_CUSTOMER = "loading lead to customer";
//create
export const LOAD_CREATE_BRANCH = "loading create branch";
export const LOAD_CREATE_USER = "loading create user";
export const LOAD_CREATE_TAXGROUP = "loading create tax group";
export const LOAD_CREATE_PLAN = "loading create plan";
export const LOAD_CREATE_SUBSCRIPTIONPLAN_SETUP =
  "loading create subscription plan";
//edit
export const LOAD_EDIT_BRANCH = "loading edit branch";
export const LOAD_EDIT_USER = "loading edit user";
export const LOAD_EDIT_TAXGROUP = "loading edit tax group";
export const LOAD_EDIT_PLAN = "loading edit plan";
export const LOAD_EDIT_SUBSCRIPTIONPLAN_SETUP =
  "loading edit subscription plan";
//load
export const LOAD_UPLOAD_MENU = "loading menu upload";

// loader config
export const SET_LOADING_ON = "loader on";
export const SET_LOADING_OFF = "loader off";

interface Data {
  loading: boolean;
  [key: string]: any;
}

// loader settings
export const setLoading = (data: Data) => ({
  type: data.loading ? SET_LOADING_ON : SET_LOADING_OFF,
  payload: data,
});

//ENQUIRY
export const loadEnquiryList = {
  type: LOAD_ENQUIRY_LIST,
};
export const loadEnquiryDetailsById = (id: any) => ({
  type: LOAD_ENQUIRY_DETAIL_BY_ID,
  payload: id,
});

//LEAD TO CUSTOMER
export const loadLeadToCustomer = (data: any) => ({
  type: LOAD_LEAD_TO_CUSTOMER,
  payload: data,
});

//CUSTOMER
export const loadCustomerList = {
  type: LOAD_CUSTOMER_LIST,
};
export const loadCustomerDetailsById = (id: any) => ({
  type: LOAD_CUSTOMER_DETAIL_BY_ID,
  payload: id,
});
// check domain whether it exists in db
export const loadCheckDomain = (data: any) => ({
  type: LOAD_CHECK_DOMAIN,
  payload: data,
});
export const loadAccountSetup = (data: any) => ({
  type: LOAD_ACCOUNT_SETUP,
  payload: data,
});

//BRANCH
export const loadCreateBranch = (data: any) => ({
  type: LOAD_CREATE_BRANCH,
  payload: data,
});
export const loadBranchList = (id: any) => ({
  type: LOAD_BRANCH_LIST,
  payload: id,
});
export const loadBranchDetails = (id: any) => ({
  type: LOAD_BRANCH_DETAILS,
  payload: id,
});
export const loadEditBranch = (data: any) => ({
  type: LOAD_EDIT_BRANCH,
  payload: data,
});

//USER
export const loadCreateUser = (data: any) => ({
  type: LOAD_CREATE_USER,
  payload: data,
});
export const loadUserList = (id: any) => ({
  type: LOAD_USER_LIST,
  payload: id,
});
export const loadUserDetails = (id: any) => ({
  type: LOAD_USER_DETAILS,
  payload: id,
});
export const loadEditUser = (data: any) => ({
  type: LOAD_EDIT_USER,
  payload: data,
});

//TAX GROUP
export const loadCreateTaxGroup = (data: any) => ({
  type: LOAD_CREATE_TAXGROUP,
  payload: data,
});
export const loadTaxGroupList = (id: any) => ({
  type: LOAD_TAXGROUP_LIST,
  payload: id,
});
export const loadTaxGroupDetails = (id: any) => ({
  type: LOAD_TAXGROUP_DETAILS,
  payload: id,
});
export const loadEditTaxGroup = (data: any) => ({
  type: LOAD_EDIT_TAXGROUP,
  payload: data,
});
//SUBSCRIPTION PLAN SETUP
export const loadCreateSubscriptionPlanSetup = (data: any) => ({
  type: LOAD_CREATE_SUBSCRIPTIONPLAN_SETUP,
  payload: data,
});
export const loadSubscriptionPlanSetupList = (id: any) => ({
  type: LOAD_SUBSCRIPTIONPLAN_SETUP_LIST,
  payload: id,
});
export const loadSubscriptionPlanSetupDetails = (id: any) => ({
  type: LOAD_SUBSCRIPTIONPLAN_SETUP_DETAILS,
  payload: id,
});
export const loadEditSubscriptionPlanSetup = (data: any) => ({
  type: LOAD_EDIT_SUBSCRIPTIONPLAN_SETUP,
  payload: data,
});

//SALES REP
export const loadSalesRepList = {
  type: LOAD_SALES_REP_LIST,
};

//UPLOAD MENU SETUP
export const loadUploadMenu = (data: any) => ({
  type: LOAD_UPLOAD_MENU,
  payload: data,
});

//PLAN
export const loadPlanList = {
  type: LOAD_PLAN_LIST,
};
export const loadCreatePlan = (data: any) => ({
  type: LOAD_CREATE_PLAN,
  payload: data,
});
export const loadPlanDetails = (id: any) => ({
  type: LOAD_PLAN_DETAILS,
  payload: id,
});
export const loadEditPlan = (data: any) => ({
  type: LOAD_EDIT_PLAN,
  payload: data,
});
