import {
  ENQUIRY_CALL_LOG_SUCCESS,
  ENQUIRY_DETAIL_BY_ID_SUCCESS,
  ENQUIRY_LIST_SUCCESS,
  LEAD_TO_CUSTOMER_SUCCESS,
  RESET_TENANT_CREATED,
  SALE_STATUS_LIST_SUCCESS,
} from "../Actions/enquiry";

const initialState = {
  enquiryList: [],
  enquiryDetail: [],
  saleStatusList: [],
  callLog: [],
  tenantCreated: undefined,
  error: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ENQUIRY_LIST_SUCCESS:
      return { ...state, enquiryList: action.payload, error: null };
    case ENQUIRY_DETAIL_BY_ID_SUCCESS:
      return { ...state, enquiryDetail: action.payload, error: null };
    case ENQUIRY_CALL_LOG_SUCCESS:
      return { ...state, callLog: action.payload, error: null };
    case SALE_STATUS_LIST_SUCCESS:
      return { ...state, saleStatusList: action.payload, error: null };
    case LEAD_TO_CUSTOMER_SUCCESS:
      return { ...state, tenantCreated: action.payload, error: null };
    case RESET_TENANT_CREATED:
      return { ...state, tenantCreated: undefined, error: null };
    default:
      return state;
  }
};

export default reducer;
