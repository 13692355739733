import {
  compose,
  applyMiddleware,
  createStore,
  Store,
  Middleware,
} from "redux";
import reducers from "./Reducers";
import middleware from "./Middleware";

// dev tool
const composeEnhancers =
  (process.env.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const configureStore = (services: any): Store => {
  const middlewares: Middleware[] = middleware.map((f) => f(services));
  return createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};
