import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { createContext, useEffect, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Environment from "./Environment";
import { CommonFieldsDTO } from "../DTOs/CommonFields/CommonFieldsDTO";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../Application/Actions/loader";

interface User {
  id: string;
  username: string;
  email: string;
}

interface AuthContextProps {
  user: User | null;
  commonFields: CommonFieldsDTO[] | [];
  login: (payload: any) => Promise<void>;
  logout: () => void;
}
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(() => {
    const userProfile = localStorage.getItem("userProfile");
    if (userProfile) {
      return JSON.parse(userProfile);
    } else {
      return null;
    }
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [commonFields, setCommonFields] = useState([] as CommonFieldsDTO[]);

  const login = async (payload: any) => {
    try {
      dispatch(setLoading({ loading: true, value: "login" }));
      const config = {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${Environment.authenticateUrl}/login`,
        payload,
        config
      );
      const commonFieldResponse = await axios.get(
        `${Environment.setUpUrl}/all-common-fields`,
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            authorization: `Bearer ${response.data.token}`,
          },
        }
      );
      const commonFields: CommonFieldsDTO[] = commonFieldResponse.data.result;
      setCommonFields(commonFields);
      localStorage.setItem("commonField", JSON.stringify(commonFields));
      localStorage.setItem("Token", JSON.stringify(response.data));
      setUser(response.data);
      localStorage.setItem("activeIndex", "0");
      dispatch(setLoading({ loading: false, value: "login" }));
      navigate("/");
    } catch (error) {
      dispatch(setLoading({ loading: false, value: "login" }));
      throw new Error("Invalid username or password");
    } finally {
      dispatch(setLoading({ loading: false, value: "login" }));
    }
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    navigate("/Login");
  };

  function isTokenExpired(token: string | null | undefined) {
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken && decodedToken.exp) {
        const expirationTime = decodedToken.exp;
        const currentTime = Math.floor(Date.now() / 1000);
        return currentTime >= expirationTime;
      }
    }
    return true;
  }

  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("Token") || "null");
    const commonFields = JSON.parse(
      localStorage.getItem("commonField") || "null"
    );
    if (storedToken) {
      setUser(storedToken);
      setCommonFields(commonFields);
      const isExpired = isTokenExpired(storedToken?.token);
      if (isExpired) {
        console.log("Token has expired");
      } else {
        console.log("Token is still valid");
      }
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ user, commonFields, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
