export const getAllCustomerList = (state: any) => state.customer.customerList;
export const getAllUserList = (state: any) => state.customer.userList;
export const getAllBranchList = (state: any) => state.customer.branchList;
export const getAllTaxGroupList = (state: any) => state.customer.taxGroupList;
export const getAllSubscriptionPlanSetupList = (state: any) =>
  state.customer.subscriptionPlanSetupList;

export const getCustomerDetail = (state: any) => state.customer.cutomerDetail;
export const getBranchDetails = (state: any) => state.customer.branchDetails;
export const getUserDetails = (state: any) => state.customer.userDetails;
export const getTaxGroupDetails = (state: any) =>
  state.customer.taxGroupDetails;
export const getSubscriptionPlanSetupDetails = (state: any) =>
  state.customer.subscriptionPlanSetupDetails;

export const getDomainSuccess = (state: any) => state.customer.domainSuccess;

export const getCreateBranchSuccess = (state: any) =>
  state.customer.createBranchSuccess;
export const getCreateTaxGroupSuccess = (state: any) =>
  state.customer.createTaxGroupSuccess;
export const getCreateUserSuccess = (state: any) =>
  state.customer.createUserSuccess;
// export const getCreateSubscriptionPlanSetupSuccess=(state:any)=>state.customer.createSubscriptionPlanSetupSuccess;

export const getEditUserSuccess = (state: any) =>
  state.customer.editUserSuccess;
export const getEditBranchSuccess = (state: any) =>
  state.customer.editBranchSuccess;
export const getEditTaxGroupSuccess = (state: any) =>
  state.customer.editTaxGroupSuccess;
export const getEditSubscriptionPlanSetupSuccess = (state: any) =>
  state.customer.editSubscriptionPlanSetupSuccess;
export const getMenuListSuccess = (state: any) =>
  state.customer.menuListSuccess;
