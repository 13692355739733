import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function SideNav(props: any) {
  const sideNavToggleVal = props.isOpen;
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredTitle, setHoveredTitle] = useState("");

  useEffect(() => {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex) {
      setActiveIndex(Number(storedActiveIndex));
    }
  }, []);

  const handleMenuItemClick = (index: any) => {
    setActiveIndex(index);
    localStorage.setItem("activeIndex", index.toString());
  };

  const handleMainDivHover = (title: string) => {
    setHoveredTitle(title);
  };

  const Menus = [
    { title: "Dashboard", src: "fa-solid fa-chart-line", navUrl: "Dashboard" },
    {
      title: "Enquiry Manage",
      src: "fa-solid fa-headset",
      navUrl: "EnquiryManage",
    },
    {
      title: "Customer Manage",
      src: "fa-regular fa-user",
      navUrl: "CustomerManage",
      gap: true,
    },
    {
      title: "Sales Representative",
      src: "fa-solid fa-user-tie",
      navUrl: "SalesRepManage",
    },
    { title: "Plans", src: "fa-solid fa-wallet", navUrl: "PlanManage" },
  ];

  return (
    <>
      <div
        className={`bg-white fixed left-0 top-0 bottom-0 ${
          sideNavToggleVal || hoveredTitle ? "w-56" : "w-13 md:block md:w-20"
        } py-2 shadow-lg z-50 border-e border-slate-300 transition-width duration-700 ease-in-out hover:w-56`}
        onMouseEnter={() => {
          handleMainDivHover("block");
          props?.toggleSideNav(true);
        }}
        onMouseLeave={() => {
          handleMainDivHover("");
          props?.toggleSideNav(false);
        }}
      >
        <ul className="pt-16">
          {Menus.map((Menu, index) => (
            <Link
              key={index}
              className={`origin-left duration-200`}
              to={`/${Menu.navUrl}`}
            >
              <li
                className={`flex px-5 py-1.5 cursor-pointer text-sm items-center gap-x-2 my-3 font-medium ${
                  activeIndex === index
                    ? "text-red-600 hover:text-red-400"
                    : "text-slate-700 hover:text-slate-400"
                }`}
                onClick={() => handleMenuItemClick(index)}
              >
                <i
                  className={`${Menu.src} py-1 text-xl ${
                    sideNavToggleVal || hoveredTitle
                      ? ``
                      : `mx-auto ${
                          activeIndex === index
                            ? "border-b-2 border-red-600"
                            : ""
                        }`
                  }`}
                ></i>
                <div
                  className={`transition-opacity delay-1000 duration-1000 ease-in-out whitespace-nowrap overflow-hidden `}
                  style={{ maxWidth: "100%" }}
                >
                  {sideNavToggleVal || hoveredTitle ? Menu.title : ""}
                </div>
              </li>
              <hr className={` ${Menu.gap ? "block my-4" : "hidden mt-2"}`} />
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export default SideNav;