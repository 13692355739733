import customer from './customer';
import enquiry from './enquiry';
import loader from './loader';
import plan from './plan';
import salesRep from './salesRep';
import common from './common';

export default [
    ...enquiry,
    ...loader,
    ...customer,
    ...salesRep,
    ...plan,
    ...common
]