import {
  ENQUIRY_CALL_LOG,
  ENQUIRY_DETAIL_BY_ID,
  ENQUIRY_LIST,
  LEAD_TO_CUSTOMER,
  SALE_STATUS_LIST,
  UPDATE_ENQUIRY_DETAIL,
  enquiryDetailFailure,
  enquiryDetailSuccess,
  enquiryListFailure,
  enquiryListSuccess,
  enquiryStatusFailure,
  enquiryStatusSuccess,
  leadToCustomerFailure,
  leadToCustomerSuccess,
  saleStatusList,
  saleStatusListFailure,
  saleStatusListSuccess,
  updateEnquiryDetailSuccess,
} from "../Actions/enquiry";
import * as loaderActions from "../Actions/loader";
import * as enquiryActions from "../Actions/enquiry";
import { salesRepList } from "../Actions/salesRep";
import ToastifyService from "../../Infrastructure/Core/Helper/ToastifyService";

const getEnquiryList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === ENQUIRY_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "getEnquiryList" })
        );
        const enquiryList = await api.Enquiry.getEnquiryList();
        dispatch(enquiryListSuccess(enquiryList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getEnquiryList" })
        );
        dispatch(enquiryListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "getEnquiryList" })
        );
      }
    }
    next(action);
  };
const getEnquiryDetailsById =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === ENQUIRY_DETAIL_BY_ID) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "getEnquiryDetailsById",
          })
        );
        const enquiryDetail = await api.Enquiry.getEnquiryDetailById(
          action.payload
        );
        dispatch(saleStatusList);
        dispatch(salesRepList);
        dispatch(enquiryDetailSuccess(enquiryDetail.lead));
        dispatch(enquiryStatusSuccess(enquiryDetail.leadCallLog));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getEnquiryDetailsById",
          })
        );
        dispatch(enquiryDetailFailure(error));
        dispatch(enquiryStatusFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getEnquiryDetailsById",
          })
        );
      }
    }
    next(action);
  };
const enquiryCallLog =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === ENQUIRY_CALL_LOG) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "enquiryCallLog" })
        );
        const leadCallLog = await api.Enquiry.enquiryCallLog(action.payload);
        dispatch(enquiryStatusSuccess(leadCallLog.result));
        ToastifyService.success("Sales details updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "enquiryCallLog" })
        );
        dispatch(enquiryStatusFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "enquiryCallLog" })
        );
      }
    }
    next(action);
  };
const getSaleStatusList =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === SALE_STATUS_LIST) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "getSaleStatusList",
          })
        );
        const saleStatusList = await api.Enquiry.saleStatusList();
        dispatch(saleStatusListSuccess(saleStatusList));
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getSaleStatusList",
          })
        );
        dispatch(saleStatusListFailure(error));
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "getSaleStatusList",
          })
        );
      }
    }
    next(action);
  };
const updateEnquiryDetail =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === UPDATE_ENQUIRY_DETAIL) {
      try {
        dispatch(
          loaderActions.setLoading({
            loading: true,
            value: "updateEnquiryDetail",
          })
        );
        const enquiryDetailRes = await api.Enquiry.updateEnquiryDetail(
          action.payload
        );
        dispatch(updateEnquiryDetailSuccess(enquiryDetailRes));
        ToastifyService.success("Lead details updated successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "updateEnquiryDetail",
          })
        );
        dispatch(updateEnquiryDetailSuccess(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({
            loading: false,
            value: "updateEnquiryDetail",
          })
        );
      }
    }
    next(action);
  };
const leadToCustomer =
  ({ api }: any) =>
  ({ dispatch }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type === LEAD_TO_CUSTOMER) {
      try {
        dispatch(
          loaderActions.setLoading({ loading: true, value: "leadToCustomer" })
        );
        const leadToCustomerRes = await api.Enquiry.leadToCustomer(
          action.payload
        );
        dispatch(enquiryActions.updateEnquiryDetail(action.payload));
        dispatch(leadToCustomerSuccess(leadToCustomerRes));
        ToastifyService.success("Customer created successfully!");
      } catch (error) {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "leadToCustomer" })
        );
        dispatch(leadToCustomerFailure(error));
        ToastifyService.error("Something Went wrong! ");
      } finally {
        dispatch(
          loaderActions.setLoading({ loading: false, value: "leadToCustomer" })
        );
      }
    }
    next(action);
  };

export default [
  getEnquiryList,
  getEnquiryDetailsById,
  enquiryCallLog,
  getSaleStatusList,
  updateEnquiryDetail,
  leadToCustomer,
];