import Customer from './Customer';
import Enquiry from './Enquiry';
import Plan from './Plan';
import SalesRep from './SalesRep';
import Common from './Common';
export default{
    Enquiry,
    Customer,
    SalesRep,
    Plan,
    Common
}