import Environment from "../../../Core/Helper/Environment";
import jwtInterceptor from "../../../Core/Helper/JwtInterceptor";

let storedTokenString = localStorage.getItem("Token");
let parsedToken = storedTokenString ? JSON.parse(storedTokenString) : null;
let token = parsedToken?.token ?? null;
let headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  authorization: `Bearer ${token}`,
};

const planList = async () => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.subscriptionUrl}/plans`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const createPlan = async (data: any) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.subscriptionUrl}/add-plan`,
      data,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const editPlan = async (data: any) => {
  try {
    const response = await jwtInterceptor.post(
      `${Environment.subscriptionUrl}/update-plan`,
      data,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
const planDetails = async (id: any) => {
  try {
    const response = await jwtInterceptor.get(
      `${Environment.subscriptionUrl}/plan-details/${id}`,
      {
        headers: headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  planList,
  createPlan,
  planDetails,
  editPlan
};
